import { useCurrentUserContext } from './useCurrentUser';
import type {
  PermissionTypeDto,
  PermissionsDto,
} from '~/lib/dtos/PermissionsDto';

export const useHasPermissions = (
  permissionNames: PermissionTypeDto | PermissionsDto
) => {
  const { currentUser } = useCurrentUserContext();
  if (currentUser !== null) {
    const { permissions } = currentUser;

    if (!permissions) {
      return false;
    }
    if (Array.isArray(permissionNames)) {
      return permissions.some((permissionName) =>
        Boolean(permissionNames.includes?.(permissionName))
      );
    } else {
      return permissions.includes?.(permissionNames);
    }
  } else {
    return false;
  }
};
