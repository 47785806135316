import z from 'zod';
import { useTranslation } from 'react-i18next';
import {
  hasAtLeastOneOperator,
  hasNumberOrTicketIdOnBasic,
  notLongerThanThirtyDays,
  numberHasThreeConsecutiveDigits,
  hasAtLeastOneZoneOrSpecialType,
  notLongerThanOneYear,
} from './rules';

const TicketTypesSchema = z.array(
  z.union([
    z.literal('ADULT'),
    z.literal('CHILD'),
    z.literal('SUPPLEMENTARY'), // <--- this one is a combination of SUPPLEMENTARY_CHILD and SUPPLEMENTARY_ADULT
    z.literal('BIKE'),
    z.literal('DOG'),
    z.literal('DSB1'),
  ])
);

export type TicketTypes = z.infer<typeof TicketTypesSchema>;

export const useSearchSchema = () => {
  const { t } = useTranslation();

  return z
    .object({
      phoneNumber: z.string(),
      ticketId: z.string(),
      fourTPaymentId: z.string(),
      correlationId: z.string(),
      startDate: z.string(),
      endDate: z.string(),
      operators: z.array(
        z.union([
          z.literal('dk.telia'),
          z.literal('dk.sonofon'),
          z.literal('dk.tdc'),
          z.literal('dk.hi3g'),
        ])
      ),
      ticketTypes: TicketTypesSchema,
      bikePossession: z.array(
        z.union([z.literal('HAS_BIKE'), z.literal('WITHOUT_BIKE')])
      ),
      searchType: z.union([z.literal('advanced'), z.literal('basic')]),
      zones: z.array(z.string()),
      paymentStatus: z.union([
        z.literal('CAPTURED'),
        z.literal('CAPTURE_FAILED'),
        z.literal('NONE'),
        z.literal('REFUNDED'),
        z.literal('REFUND_FAILED'),
        z.literal('AUTHORIZED'),
        z.literal('AUTHORIZATION_FAILED'),
      ]),
      ticketStatus: z.union([
        z.literal('DELIVERY_FAILED'),
        z.literal('EXPIRED'),
        z.literal('NONE'),
        z.literal('VALID'),
      ]),
      ticketsPage: z.string(),
      ticketsResultsPerPage: z.string(),
      ordersPage: z.string(),
      ordersResultsPerPage: z.string(),
      preordersPage: z.string(),
      preordersResultsPerPage: z.string(),
      otherMessagesPage: z.string(),
      otherMessagesResultsPerPage: z.string(),
      blockadeMessagesPage: z.string(),
      blockadeMessagesResultsPerPage: z.string(),
    })
    .refine(numberHasThreeConsecutiveDigits, {
      message: t('search.three-consecutive-digits'),
      path: ['phoneNumber'],
    })
    .refine(notLongerThanThirtyDays, {
      message: t('search.longest-allowed-search'),
      path: ['phoneNumber'],
    })
    .refine(notLongerThanOneYear, {
      message: t('search.longest-allowed-search-with-full-phone-number'),
      path: ['phoneNumber'],
    })
    .refine(hasNumberOrTicketIdOnBasic, {
      message: t('search.enter-phone-number-error'),
      path: ['phoneNumber'],
    })
    .refine(hasAtLeastOneOperator, {
      message: t('search.at-least-one-operator'),
      path: ['phoneNumber'],
    })
    .refine(hasAtLeastOneZoneOrSpecialType, {
      message: t('search.at-least-one-ticket-type'),
      path: ['phoneNumber'],
    });
};

export type SearchSchemaType = z.infer<ReturnType<typeof useSearchSchema>>;
