type Props = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: 'normal' | 'phoneNumber';
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  maxLength?: number;
};

export const TextInput = ({
  value,
  onChange,
  variant = 'normal',
  label,
  placeholder = '',
  disabled = false,
  maxLength,
}: Props) => {
  return (
    <div>
      {label && (
        <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
          {label}
        </label>
      )}
      <div className="flex items-center gap-1">
        {variant === 'phoneNumber' && (
          <div className=" text-sm text-gray-500">+45</div>
        )}
        <input
          className="bg-gray-50 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
};
