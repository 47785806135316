import clsx from 'clsx';
import React from 'react';

const Description = ({
  size,
  children,
}: {
  size?: string;
  children: string | JSX.Element;
}) => {
  const baseClass = 'pt-2 pb-4';

  const dynamicClass = () => {
    if (size === undefined) {
      return 'text-md';
    } else {
      return `text-${size}`;
    }
  };

  return <div className={clsx(baseClass, dynamicClass())}>{children}</div>;
};
export default Description;
