import z from 'zod';
import { DeliveryNotificationDtoSchema } from './DeliveryNotificationDto';
import { OutGoingMessageTypeDtoSchema } from './OutgoingMessageTypeDto';

export const OutgoingMessageDtoSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  text: z.object({
    value: z.string(),
  }),
  type: OutGoingMessageTypeDtoSchema,
  userId: z.object({
    value: z.number(),
  }),
  processId: z.object({
    value: z.string(),
  }),
  deliveryNotification: DeliveryNotificationDtoSchema.optional(),
  createdAt: z.string(),
});

export type OutgoingMessageDto = z.infer<typeof OutgoingMessageDtoSchema>;
