import type { UseQueryOptions } from 'react-query';
import type { BulletinDto } from '../dtos/BulletinDto';
import { BulletinDtoSchema } from '../dtos/BulletinDto';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';

export const useBulletinQuery = (
  bulletinId: string,
  options?: UseQueryOptions<BulletinDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: BulletinDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/bulletins/${bulletinId}`,
    },
    { ...options, queryKey: ['bulletin', bulletinId] }
  );
};
