import React, { useEffect, useState } from 'react';
import type { ProjectInfo } from '../types';

export const useProjectInfo = () => {
  const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null);

  useEffect(() => {
    if (projectInfo === null) {
      const version = document.head.querySelector('[name=version]');
      setProjectInfo({
        env: String(import.meta.env.VITE_PROJECT_ENV).toUpperCase(),
        ver: String(version?.getAttribute('content')),
      });
    }
  }, []);

  return { projectInfo };
};
