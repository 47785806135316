import { z } from 'zod';

interface GetJsonOptions<T extends z.ZodTypeAny> {
  headers?: HeadersInit;
  schema: T;
  url: string;
}

export const getJson = async <T extends z.ZodTypeAny>({
  headers = {},
  schema,
  url,
}: GetJsonOptions<T>): Promise<z.TypeOf<T>> => {
  const response = await fetch(url, {
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error(
      `Response status was not ok (${response.status} ${response.statusText})!`
    );
  }

  const json = await response.json();
  try {
    return schema.parse(json);
  } catch (error) {
    if (error instanceof z.ZodError) {
      error.errors.forEach((err) => {
        console.error(`Path: ${err.path.join('.')}, Issue: ${err.message}`);
      });
    }
    throw error;
  }
};
