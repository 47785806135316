import { I18n } from 'aws-amplify';
import { dnDict } from './dn';
import { enDict } from './en';

const setupI18nAmplify = () => {
  I18n.putVocabulariesForLanguage('dn', dnDict);
  I18n.putVocabulariesForLanguage('en', enDict);

  if (localStorage.getItem('i18nextLng') === 'en') {
    I18n.setLanguage('en');
  } else {
    I18n.setLanguage('dn');
  }
  return;
};

export default setupI18nAmplify;
