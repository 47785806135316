import z from 'zod';

export const UserDtoSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  phoneNumber: z.object({
    value: z.string(),
  }),
  createdAt: z.string(),
  modifiedAt: z.string(),
});

export type UserDto = z.infer<typeof UserDtoSchema>;
