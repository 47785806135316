const ErrorMessage = ({ errorMessage }: { errorMessage?: string }) => {
  if (!errorMessage) return null;
  return (
    <div className="bg-red-400/75 w-full p-2 rounded-md shadow-md">
      <div className="text-center text-black">{errorMessage}</div>
    </div>
  );
};

export default ErrorMessage;
