import z from 'zod';

export const BasicUserSchema = z.object({
  createdAt: z.string().datetime(),
  id: z.object({
    value: z.number(),
  }),
  modifiedAt: z.string(),
  phoneNumber: z.object({
    value: z.string(),
  }),
  welcomed: z.boolean(),
});

export const BasicUserDtoSchema = z.object({
  user: BasicUserSchema,
});
export type BasicUserDto = z.infer<typeof BasicUserDtoSchema>;
