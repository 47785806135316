import { Auth } from 'aws-amplify';
import { Table } from 'flowbite-react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import NotyfContext from '~/context/NotyfContext';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import { useModal } from '~/hooks/useModal';
import { NoteModal } from '../Modal/NoteModal';

const REFUND_NOTE_MAX_LENGTH = 100;

type RefundRequestData = {
  ticketId: string;
  note: string;
};

type RefundResponseData = {
  creationStatus: string;
};

export const TicketRefundCell = ({
  className,
  refundStatus,
  ticketId,
}: {
  className?: string;
  refundStatus?: string;
  ticketId: string;
}) => {
  const notyf = useContext(NotyfContext);

  const { t } = useTranslation();

  const hasPermissions = useHasPermissions('REFUND_TICKET');

  const [newRefundStatus, setNewRefundStatus] = useState<null | string>(null);

  const refundTicketMutation = useMutation<
    RefundResponseData,
    Error,
    RefundRequestData
  >(
    async ({ note, ticketId }) => {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const response = await fetch(
        `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/refund/${ticketId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken.getJwtToken()}`,
          },
          body: JSON.stringify({
            note: note,
            author:
              idToken.payload['cognito:username'] ||
              idToken.payload['username'],
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
    {
      onSuccess: (data) => {
        notyf.success(t('refund.refund-requested'));

        setNewRefundStatus(data.creationStatus);
        if (modalIsVisible) {
          toggleModalVisibility();
        }
      },
    }
  );

  const [modalIsVisible, toggleModalVisibility] = useModal();

  const handlePaymentStatus = (paymentStatus: string | undefined) => {
    switch (paymentStatus) {
      case 'PAYMENT_COMPLETED':
        return (
          <>
            <RefundButton
              canRefund={hasPermissions}
              toggleModalVisibility={toggleModalVisibility}
            />
            <NoteModal
              isLoading={refundTicketMutation.isLoading}
              isVisible={modalIsVisible}
              onSubmit={(note) => {
                refundTicketMutation.mutate({ ticketId, note });
              }}
              toggleVisibility={toggleModalVisibility}
              submitText={t('notes.ticket.refund.button-text')}
              title={t('notes.ticket.refund.title')}
              maxLength={REFUND_NOTE_MAX_LENGTH}
            />
          </>
        );
      case 'PAYMENT_REFUNDED':
        return <div className="text-center">{t('refund.refunded')}</div>;
      case 'CREATED':
        return (
          <div className="text-center">{t('refund.refund-requested')}</div>
        );
      default:
        return <div className="text-center">-</div>;
    }
  };

  return (
    <Table.Cell className={className}>
      {handlePaymentStatus(newRefundStatus || refundStatus)}
    </Table.Cell>
  );
};

const RefundButton = ({
  canRefund,
  toggleModalVisibility,
}: {
  canRefund: boolean;
  toggleModalVisibility: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={() => {
        canRefund && toggleModalVisibility();
      }}
      className={
        canRefund
          ? 'px-3 flex mx-auto py-1.5 bg-blue-500 hover:bg-blue-600 text-white rounded-md cursor-pointer'
          : 'px-3 flex mx-auto py-1.5 text-black bg-gray-200 rounded-md cursor-not-allowed'
      }
    >
      {t('refund.refund')}
    </button>
  );
};
