import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import Header from '~/components/Header';
import type { TicketResponse } from '../types';
import { useTranslateTicketNoteType } from '~/hooks/useTranslateTicketNotes';
import { Modal } from '~/components/Modal/Modal';
import { useModal } from '~/hooks/useModal';
import { formatDate } from '~/lib/utils/formatDates/formatDates';

type Props = {
  notes: TicketResponse['detail']['notes'];
};

export const NotesTable = ({ notes }: Props) => {
  const { t } = useTranslation();

  if (notes.length === 0) {
    return (
      <div className="flex flex-col justify-center mt-4">
        <Header className="mt-auto mb-2">{t('search.notes')}</Header>

        <Table className="border-spacing-0 bg-gray-50 w-full py-1 border border-separate border-gray-300 rounded-md shadow-lg">
          <Table.Head className="justify-center w-full text-center">
            <Table.HeadCell className="w-full">
              {t('tables.no-notes')}
            </Table.HeadCell>
          </Table.Head>
        </Table>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center mt-4">
      <Header className="mt-auto mb-2">{t('search.notes')}</Header>
      <Table className="border-spacing-0 w-full border border-separate border-gray-300 rounded-md shadow-lg">
        <TableBody notes={notes} />
      </Table>
    </div>
  );
};

const TableBody = ({ notes }: Props) => {
  const { translateTicketNoteType } = useTranslateTicketNoteType();

  const { t } = useTranslation();

  return (
    <>
      <Table.Head className="bg-gray-100">
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('tables.note-type')}
          </div>
        </Table.HeadCell>
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('tables.created')}
          </div>
        </Table.HeadCell>
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('customer.note')}
          </div>
        </Table.HeadCell>
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('tables.user')}
          </div>
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">
        {notes.map(({ content, createdAt, createdBy, id, type }) => {
          return (
            <Table.Row
              key={id.value}
              className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
            >
              <Table.Cell className="px-3 py-1.5 text-center border border-gray-100">
                {translateTicketNoteType(type)}
              </Table.Cell>
              <Table.Cell className="px-3 py-1.5 text-center border border-gray-100">
                {formatDate(createdAt)}
              </Table.Cell>
              <ModalCell content={content} />
              <Table.Cell className="px-3 py-1.5 text-center border border-gray-100">
                {createdBy}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </>
  );
};

const ModalCell = ({ content }: { content: string }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useModal();

  return (
    <>
      <Table.Cell
        className="max-w-xs px-6 py-4 text-center border border-gray-100"
        onClick={() => {
          setIsModalOpen();
        }}
      >
        <div className="text-blue-500 truncate cursor-pointer">{content}</div>
      </Table.Cell>
      <Modal isVisible={isModalOpen} toggleVisibility={setIsModalOpen}>
        <div className="w-96 flex flex-col p-8">
          <Header className="pb-2 text-left">{t('customer.note')}</Header>
          <span className="text-left break-words">{content}</span>
        </div>
      </Modal>
    </>
  );
};
