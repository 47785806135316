import { useTranslation } from 'react-i18next';

import { Link, Outlet } from 'react-router-dom';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import Header from '../../components/Header';
import { Container } from '../../components/Layout';
import type { Nullable } from '../search/types';

type MaintenanceListElement = {
  name: string;
  svg: Nullable<JSX.Element>;
  to: string;
  isDisplayable: boolean;
};

const useMaintenanceList = () => {
  const { t } = useTranslation();

  const hasPermissionsToViewAuditLog = useHasPermissions('VIEW_AUDIT_LOG');
  const hasPermissionsToViewUsers = useHasPermissions('VIEW_USERS');
  const hasPermissionsToViewDashboard = useHasPermissions('VIEW_DASHBOARD');
  const hasPermissionsToMoveDsbQueue = useHasPermissions('MOVE_DSB_QUEUE');

  const maintenanceList: MaintenanceListElement[] = [
    {
      name: t('users.users'),
      svg: (
        <svg
          className="min-w-min w-6 h-6"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="matrix(0.9,0,0,0.9,0.7000000000000002,0.75)">
            <g>
              <circle
                cx="5"
                cy="3.75"
                fill="none"
                r="2.25"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.5,13.5H.5v-1a4.5,4.5,0,0,1,9,0Z"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9,1.5A2.25,2.25,0,0,1,9,6"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.6,8.19a4.5,4.5,0,0,1,2.9,4.2V13.5H12"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </svg>
      ),
      to: 'users',
      isDisplayable: hasPermissionsToViewUsers,
    },
    {
      name: t('maintenance.audit-log'),
      svg: (
        <svg
          className="min-w-min w-6 h-6"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="matrix(0.9,0,0,0.9,0.7000000000000002,0.7000000000000002)">
            <g>
              <polyline
                fill="none"
                points="5.62 7.38 11.5 1.5 13.5 3.5"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <line
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                x1="9.25"
                x2="11"
                y1="3.75"
                y2="5.5"
              />
              <circle
                cx="3.5"
                cy="9.5"
                fill="none"
                r="3"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </svg>
      ),
      to: 'audit_log',
      isDisplayable: hasPermissionsToViewAuditLog,
    },
    {
      name: t('maintenance.status-search'),
      svg: (
        <svg
          className="min-w-min w-6 h-6"
          width="24"
          height="24"
          viewBox="2 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 10.9794C11 10.4271 11.4477 9.97937 12 9.97937C12.5523 9.97937 13 10.4271 13 10.9794V16.9794C13 17.5317 12.5523 17.9794 12 17.9794C11.4477 17.9794 11 17.5317 11 16.9794V10.9794Z"
            fill="currentColor"
          />
          <path
            d="M12 6.05115C11.4477 6.05115 11 6.49886 11 7.05115C11 7.60343 11.4477 8.05115 12 8.05115C12.5523 8.05115 13 7.60343 13 7.05115C13 6.49886 12.5523 6.05115 12 6.05115Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
            fill="currentColor"
          />
        </svg>
      ),
      to: 'status_search',
      isDisplayable: hasPermissionsToViewDashboard,
    },
    {
      name: t('maintenance.queues'),
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          className="min-w-min w-6 h-6"
          viewBox="-0.855 -0.855 24 24"
          height="24"
          width="24"
        >
          <g id="database-refresh--raid-storage-code-disk-programming-database-array-hard-disc-refresh">
            <path
              id="Vector"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.552857142857142 7.5467571428571425c4.836245378571428 0 8.756785714285714 -1.5111983142857142 8.756785714285714 -3.375342857142857S14.389102521428573 0.7960714285714285 9.552857142857142 0.7960714285714285 0.7960714285714285 2.3072697428571427 0.7960714285714285 4.1714142857142855 4.716611764285714 7.5467571428571425 9.552857142857142 7.5467571428571425Z"
              strokeWidth="1.71"
            ></path>
            <path
              id="Vector_2"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.309642857142855 9.5530482v-5.381442857142857"
              strokeWidth="1.71"
            ></path>
            <path
              id="Vector_3"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M0.7960714285714285 4.171605342857143v10.762885714285714C0.7960714285714285 16.59442735714286 3.886293342857143 17.923229785714287 7.960714285714285 18.240225428571428"
              strokeWidth="1.71"
            ></path>
            <path
              id="Vector_4"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.552857142857142 12.864514285714286C4.776428571428571 12.928199999999999 0.7960714285714285 11.415664285714286 0.7960714285714285 9.552857142857142"
              strokeWidth="1.71"
            ></path>
            <path
              id="Vector_5"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21.33455507142857 18.10027607142857 -1.3389921428571427 1.0119660000000001 -1.0121252142857142 -1.3389921428571427"
              strokeWidth="1.71"
            ></path>
            <path
              id="Ellipse 50"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.921528285714285 19.011777857142857c0.2209894285714286 -0.42462449999999996 0.3709692857142857 -0.8955803571428571 0.432426 -1.3988567142857142 0.27448542857142855 -2.251194471428571 -1.328006357142857 -4.298769792857143 -3.579296357142857 -4.573318907142857 -0.7124839285714285 -0.08689915714285713 -1.4045884285714285 0.014217835714285714 -2.026543114285714 0.2644390071428571"
              strokeWidth="1.71"
            ></path>
            <path
              id="Vector_6"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m11.216519057142857 16.193048142857144 1.3389921428571427 -1.0120933714285714 1.0120615285714285 1.3389602999999999"
              strokeWidth="1.71"
            ></path>
            <path
              id="Ellipse 52"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12.629227414285713 15.282215057142858c-0.2210690357142857 0.4247041071428571 -0.3710488928571429 0.8955485142857142 -0.432426 1.3988248714285714 -0.27454911428571427 2.25129 1.3278949071428572 4.298785714285715 3.5791689857142854 4.573430357142857 0.7125794571428571 0.086931 1.404683957142857 -0.014329285714285713 2.026574957142857 -0.26445492857142855"
              strokeWidth="1.71"
            ></path>
          </g>
        </svg>
      ),
      to: 'move_dsb_queue',
      isDisplayable: hasPermissionsToMoveDsbQueue,
    },
  ];

  return { maintenanceList };
};

const Maintenance: React.FC = () => {
  const { t } = useTranslation();

  const { maintenanceList } = useMaintenanceList();

  return (
    <>
      <Header className="pb-2">{t('navbar.maintenance')}</Header>
      <Container>
        <div className="md:flex-row md:justify-start flex flex-col justify-center w-full">
          <aside
            className="md:min-w-max md:mb-0 md:mr-4 md:border-r md:border-b-0 mb-4 border-b"
            aria-label="Sidebar"
          >
            <div className="dark:bg-gray-800 px-3 py-4 overflow-y-auto rounded">
              <ul className="md:block grid justify-center grid-cols-2 my-auto space-y-2 align-middle">
                {maintenanceList.map((link) => {
                  if (link.isDisplayable)
                    return (
                      <li key={link.to} className=" md:pr-6 my-auto">
                        <Link
                          to={link.to}
                          className="dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center p-2 text-base font-normal text-gray-900 rounded-lg"
                        >
                          {link.svg}
                          <span className="ml-3">{link.name}</span>
                        </Link>
                      </li>
                    );
                })}
              </ul>
            </div>
          </aside>
          <Outlet />
        </div>
      </Container>
    </>
  );
};
export default Maintenance;
