type QueryValue = string | string[] | null | undefined;

const encodePair = (key: string, val: string) =>
  `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;

export const buildParams = (query: Record<string, QueryValue>): string => {
  const parts: string[] = [];

  for (const key in query) {
    const value = query[key];

    if (Array.isArray(value)) {
      for (const val of value) {
        parts.push(encodePair(key, val));
      }
      continue;
    }

    if (!value) {
      continue;
    }

    parts.push(encodePair(key, value));
  }

  return parts.join('&');
};
