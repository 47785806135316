import type { UseQueryOptions } from 'react-query';
import type { DashboardDto } from '../dtos/DashboardDto';
import { DashboardDtoSchema } from '../dtos/DashboardDto';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';

export const useDashboardQuery = (
  refreshTime: null | string,
  options?: UseQueryOptions<DashboardDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: DashboardDtoSchema,
      url: `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/dashboard/basic`,
    },
    { ...options, queryKey: ['dashboard', refreshTime] }
  );
};
