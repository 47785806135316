import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useModal } from '~/hooks/useModal';
import { Auth } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import NotyfContext from '~/context/NotyfContext';
import { NoteModal } from '~/components/Modal/NoteModal';

const addNoteMutation = async (note: string, userId: number) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/user/${userId}/note`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ note }),
      method: 'POST',
    }
  ).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

type Props = {
  userId: number;
};

export const AddCustomerNoteCell = ({ userId }: Props) => {
  const notyf = useContext(NotyfContext);

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useModal();

  const { mutate, isLoading: isAddingCustomerNote } = useMutation({
    mutationFn: ({ note, userId }: { note: string; userId: number }) => {
      return addNoteMutation(note, userId);
    },
    onSuccess: () => {
      setIsModalOpen();
      notyf.success('success');
      queryClient.invalidateQueries({ queryKey: ['customerNotes'] });
    },
    onError: () => {
      notyf.error(t('status.error'));
    },
  });

  const sendEmail = (note: string) => {
    mutate({ note, userId });
  };

  return (
    <Table.Cell className="border-2 border-gray-100">
      <button
        onClick={() => {
          setIsModalOpen();
        }}
        className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        {t('customer.add')}
      </button>
      <NoteModal
        isVisible={isModalOpen}
        toggleVisibility={setIsModalOpen}
        isLoading={isAddingCustomerNote}
        onSubmit={sendEmail}
        submitText={t('notes.customer.standalone.button-text')}
        title={t('notes.customer.standalone.title')}
      />
    </Table.Cell>
  );
};
