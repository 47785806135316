import { Table } from 'flowbite-react';
import { ClipLoader } from 'react-spinners';
import { mapOperator } from '~/features/search/utils';
import type { useDashboard } from './useDashBoard';
import { useMemo } from 'react';
import { formatDate } from '~/lib/utils/formatDates/formatDates';

type TableSmsProps = Pick<
  ReturnType<typeof useDashboard>,
  'operatorsData' | 'error' | 'operatorsLoading'
>;

const TableSms = ({
  operatorsData,
  operatorsLoading,
  error,
}: TableSmsProps) => {
  const totalRow = useMemo(() => {
    let totalMessagesSent = 0;
    let totalMessagesReceived = 0;
    let totalDeliveryNotification = 0;
    let totalCaptured = 0;
    let totalDeliveredMessagesPercentage = 0;
    let totalSuccessfullyCapturedPercentage = 0;
    let operatorCount = 0;

    operatorsData.forEach((operatorData) => {
      totalMessagesSent += operatorData?.numberOfMessagesSentInAnHour ?? 0;
      totalMessagesReceived +=
        operatorData?.numberOfMessagesReceivedInAnHour ?? 0;
      totalDeliveryNotification +=
        operatorData?.numberOfDeliveryNotificationInAnHour ?? 0;
      totalCaptured += operatorData?.numberOfCapturedInAnHour ?? 0;
      totalDeliveredMessagesPercentage +=
        operatorData?.percentageOfDeliveredMessagesToSentMessages ?? 0;
      totalSuccessfullyCapturedPercentage +=
        operatorData?.percentageOfSuccessfullyCapturedToSentTicketMessages ?? 0;
      operatorCount++;
    });

    const averageDeliveredMessagesPercentage =
      operatorCount > 0 ? totalDeliveredMessagesPercentage / operatorCount : 0;
    const averageSuccessfullyCapturedPercentage =
      operatorCount > 0
        ? totalSuccessfullyCapturedPercentage / operatorCount
        : 0;

    return {
      totalMessagesSent,
      totalMessagesReceived,
      totalDeliveryNotification,
      totalCaptured,
      averageDeliveredMessagesPercentage,
      averageSuccessfullyCapturedPercentage,
    };
  }, [operatorsData]);

  if (error) {
    return (
      <Table className="shadow-lg">
        <Table.Head className="text-center">
          <Table.HeadCell>{JSON.stringify(error)}</Table.HeadCell>
        </Table.Head>
      </Table>
    );
  }
  if (operatorsLoading) {
    return (
      <Table className="shadow-lg">
        <Table.Head className="text-center">
          <Table.HeadCell>
            <ClipLoader />
          </Table.HeadCell>
        </Table.Head>
      </Table>
    );
  }
  if (!operatorsData) {
    return (
      <Table className="shadow-lg">
        <Table.Head className="text-center">
          <Table.HeadCell>No data</Table.HeadCell>
        </Table.Head>
      </Table>
    );
  }

  const {
    totalMessagesSent,
    totalMessagesReceived,
    totalDeliveryNotification,
    totalCaptured,
    averageDeliveredMessagesPercentage,
    averageSuccessfullyCapturedPercentage,
  } = totalRow;

  return (
    <Table className="border-spacing-0 mb-4 border border-separate border-black rounded-md shadow-lg">
      <Table.Head className="bg-gray-100">
        <Table.HeadCell className=" border-b border-r border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            SMS
          </div>
        </Table.HeadCell>
        <Table.HeadCell colSpan={3} className=" border-b border-r border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Latest
          </div>
        </Table.HeadCell>
        <Table.HeadCell colSpan={6} className=" border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Within last hour
          </div>
        </Table.HeadCell>
      </Table.Head>
      <Table.Head className="bg-gray-100 border-black">
        <Table.HeadCell className="border-b border-r border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Operators
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Latest MO SMS received
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Latest MT SMS delivered
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-r border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Latest ticket captured
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            MO SMS received
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            MT SMS sent
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            MT SMS delivered
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Ticket billing captured
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Success % in MT delivery
          </div>
        </Table.HeadCell>
        <Table.HeadCell className="border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Capture % in billing
          </div>
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="text-gray-800 divide-y">
        {operatorsData.map((data, id) => {
          if (!data) {
            return null;
          }
          const {
            operator,
            latestIncomingMessage,
            latestOutgoingMessage,
            latestSuccessfulCapture,
            numberOfMessagesSentInAnHour,
            numberOfMessagesReceivedInAnHour,
            numberOfDeliveryNotificationInAnHour,
            numberOfCapturedInAnHour,
            percentageOfDeliveredMessagesToSentMessages,
            percentageOfSuccessfullyCapturedToSentTicketMessages,
          } = data;
          return (
            <Table.Row key={id} className="">
              <Table.Cell className="text-center border-r border-black">
                {mapOperator(operator)}
              </Table.Cell>
              <Table.Cell className="text-center">
                {formatDate(latestIncomingMessage)}
              </Table.Cell>
              <Table.Cell className="text-center">
                {formatDate(latestOutgoingMessage)}
              </Table.Cell>
              <Table.Cell className="text-center border-r border-black">
                {formatDate(latestSuccessfulCapture)}
              </Table.Cell>

              <Table.Cell className="text-center">
                {numberOfMessagesReceivedInAnHour}
              </Table.Cell>
              <Table.Cell className=" text-center">
                {numberOfMessagesSentInAnHour}
              </Table.Cell>
              <Table.Cell className=" text-center">
                {numberOfDeliveryNotificationInAnHour}
              </Table.Cell>
              <Table.Cell className=" text-center">
                {numberOfCapturedInAnHour}
              </Table.Cell>
              <Table.Cell className=" text-center">
                {percentageOfDeliveredMessagesToSentMessages.toFixed(2)}
              </Table.Cell>
              <Table.Cell className=" text-center">
                {percentageOfSuccessfullyCapturedToSentTicketMessages.toFixed(
                  2
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
        <Table.Row className="bg-gray-100 border-t border-black">
          <Table.Cell className=" text-center border-t border-black">
            Total
          </Table.Cell>
          <Table.Cell
            className=" text-center border-t border-r border-black"
            colSpan={3}
          ></Table.Cell>
          <Table.Cell className=" text-center border-t border-black">
            {totalMessagesReceived}
          </Table.Cell>
          <Table.Cell className="text-center border-t border-black">
            {totalMessagesSent}
          </Table.Cell>
          <Table.Cell className=" text-center border-t border-black">
            {totalDeliveryNotification}
          </Table.Cell>
          <Table.Cell className=" text-center border-t border-black">
            {totalCaptured}
          </Table.Cell>
          <Table.Cell className=" text-center border-t border-black">
            {averageDeliveredMessagesPercentage.toFixed(2)}
          </Table.Cell>
          <Table.Cell className=" text-center border-t border-black">
            {averageSuccessfullyCapturedPercentage.toFixed(2)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
export default TableSms;
