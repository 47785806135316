import { Table } from 'flowbite-react';
import { OperatorCell, PhoneNumberCell } from '../components/TicketCells';
import { useTranslation } from 'react-i18next';
import { TicketTypeCell } from '~/components/Cells/TicketTypeCell';
import type { OrderDetailsDto } from '~/lib/dtos/OrderDetailsDto';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';
const OrderTable = ({ orderResponse }: { orderResponse: OrderDetailsDto }) => {
  const order = orderResponse.detail.order;
  const { t } = useTranslation();

  if (!order) {
    return (
      <Table>
        <Table.Head className="text-center">
          <Table.Cell className="px-4 text-center border border-gray-100">
            {t('common.loading')}
          </Table.Cell>
        </Table.Head>
      </Table>
    );
  }

  return (
    <div className="overflow-x-none w-full">
      <Table className="border-spacing-0 w-full border border-separate border-gray-300 rounded-md shadow-md">
        <Table.Head className="text-center">
          <Table.HeadCell className="">{t('tables.property')}</Table.HeadCell>
          <Table.HeadCell className="">{t('tables.value')}</Table.HeadCell>
        </Table.Head>
        <Table.Body className=" whitespace-nowrap">
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.created')}
            </Table.Cell>
            <Table.Cell className="px-4 text-center border border-gray-100">
              <DateBreakHandler date={order.createdAt} />
            </Table.Cell>
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.phone-number-customer-profile')}
            </Table.Cell>
            <PhoneNumberCell
              className="px-4 text-center border border-gray-100"
              phoneNumber={orderResponse.detail.user.phoneNumber.value}
              customerId={orderResponse.detail.user.id.value}
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.operator')}
            </Table.Cell>
            <OperatorCell
              operator={orderResponse.detail.incomingMessages[0].provider.value}
              className="px-4 text-center border border-gray-100"
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.ticket-type')}
            </Table.Cell>

            <TicketTypeCell
              className="px-4 text-center border border-gray-100"
              type={order.ticketType}
              distance={order.distance.value}
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.ticket-price')}
            </Table.Cell>
            <Table.Cell className="px-4 text-center border border-gray-100">
              {order.price.amount}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
export default OrderTable;
