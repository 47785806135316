import { useTranslation } from 'react-i18next';
import type { TUserStatus } from '~/features/maintenance/users/utils';

export const useTranslateUserStatus = () => {
  const { t } = useTranslation();

  const userStatusmap: Record<TUserStatus, string> = {
    ACTIVE: t('users.active'),
    RESEND: t('tables.resend'),
    INACTIVE: t('users.inactive'),
    DEACTIVATED: t('users.deactivated'),
    RESET_REQUIRED: t('users.reset-required-status'),
    PASSWORD_RESENT: t('users.password-resent'),
    UNKNOWN_STATUS: t('users.unknown-status'),
  };

  const translateUserStatus = (userGroup: TUserStatus) => {
    return userStatusmap[userGroup] ?? userGroup;
  };

  return { translateUserStatus };
};
