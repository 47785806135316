import { Spinner, Table } from 'flowbite-react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavigationType,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router';
import Header from '~/components/Header';
import Pagination from '../../search/components/Pagination';
import RecordsPerPageSelect from '../../search/components/RecordsPerPageSelect';
import { useFullUserResultsTable } from './useFullUserResultsTable';
import DatePicker from 'react-datepicker';
import { PaymentStatusCell } from '~/components/Cells/PaymentStatusCell';
import { HeadCell } from '~/components/Cells/HeadCell';
import { Cell } from '../../../components/Cells/Cell';
import { EbkDsbStatusCell } from '~/components/Cells/EbkDsbStatusCell';
import type { Nullable } from '~/features/search/types';
import { GoToCell } from '~/features/search/components/TicketCells';
import { useSearchParams } from 'react-router-dom';
import Button from '~/components/Button';
import { DeliveryCell } from '~/components/Cells/DeliveryCell';
import { TicketTypeCell } from '~/components/Cells/TicketTypeCell';
import { TicketRefundCell } from '~/components/Cells/TicketRefundCell';
import { StatusCell } from './StatusCell';
import {
  differenceInSeconds,
  parse,
  parseISO,
  addDays,
  startOfDay,
} from 'date-fns';
import { formatDate } from '~/lib/utils/formatDates/formatDates';
import { useDecodeCustomerResultsData } from './useDecodeCustomerResultsData';
import { buildParams } from '~/lib/utils/buildParams/buildParams';
const snakeToCamel = (str: string) => {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
};

export const DateBreakHandler = ({ date }: { date: Nullable<string> }) => {
  if (!date) {
    return <div className="flex flex-col items-center justify-center">-</div>;
  }

  const [dateDay, time] = formatDate(date).match(/.{1,10}/g) || [];

  return (
    <div className="flex flex-col items-center justify-center">
      <div>{dateDay}</div>
      <div>{time}</div>
    </div>
  );
};

const useTranslatedHeaders = () => {
  const { t } = useTranslation();

  return [
    t('tables.details'),
    t('tables.created'),
    t('tables.delivery-status'),
    t('tables.ebk-response'),
    t('tables.dsb-response'),
    t('tables.valid-from'),
    t('tables.valid-to'),
    t('tables.type'),
    t('tables.refund-status'),
    t('tables.payment-status'),
    t('tables.status'),
  ];
};

export const FullUserResultsTable = () => {
  const { t } = useTranslation();

  const [
    {
      startDate: initialStartDate,
      endDate: initialEndDate,
      page: initialPage,
      limit: initialLimit,
    },
  ] = useDecodeCustomerResultsData();

  const params = useParams();

  const [page, setPage] = useState(Number(initialPage));
  const [limit, setLimit] = useState(Number(initialLimit));

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const { data, error, isLoading, isFetching, availablePages, count } =
    useFullUserResultsTable({
      userId: params.customerId ?? '',
      limit,
      page,
      startDate,
      endDate,
    });

  useEffect(() => {
    if (!availablePages) {
      return;
    }

    if (availablePages <= page - 1) {
      setPage(availablePages);
      return;
    }
  }, [availablePages]);

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  const updateSearchEndDate = () => {
    setEndDate(new Date());
  };

  const navigate = useNavigate();

  useEffect(() => {
    const queryString = buildParams({
      page: page.toString(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      limit: limit.toString(),
    });
    navigate(`/customer/${params.customerId}?${queryString}`, {
      replace: true,
    });
  }, [page, startDate, endDate, limit]);

  return (
    <div className="flex flex-col gap-2 overflow-auto">
      <div className="flex justify-between">
        <Header className="mt-auto mb-2">{t('tables.customer-history')}</Header>
        <div className="flex gap-8">
          <div className="flex">
            {isLoading || isFetching ? (
              <div className="pb-2 mt-auto">
                <Spinner />
              </div>
            ) : (
              <Button onClick={updateSearchEndDate} className="mt-auto">
                {t('search.update-search')}
              </Button>
            )}
          </div>

          <div className="flex gap-2">
            <div>
              <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
                {t('common.start-date')}
              </label>
              <DatePicker
                showTimeSelect
                dateFormat="d/M/yyyy, HH:mm"
                timeFormat="HH:mm"
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                }}
                maxDate={endDate}
                calendarStartDay={1}
              />
            </div>
            <div>
              <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
                {t('common.end-date')}
              </label>
              <DatePicker
                showTimeSelect
                dateFormat="d/M/yyyy, HH:mm"
                timeFormat="HH:mm"
                selected={endDate}
                onChange={(date: Date) => {
                  setEndDate(date);
                }}
                calendarStartDay={1}
              />
            </div>
          </div>
          <RecordsPerPageSelect
            type="lg"
            setItemsPerPage={setLimit}
            itemsPerPage={limit}
          />
        </div>
      </div>
      <Table className=" bg-gray-100 shadow-lg">
        <TableBody data={data} isLoading={isLoading} error={error} />
      </Table>
      <Pagination
        resultsNumber={count}
        onPageChange={handlePageClick}
        pageCount={availablePages}
        currentPage={page - 1}
      />
    </div>
  );
};

type TableBodyProps = Pick<
  ReturnType<typeof useFullUserResultsTable>,
  'data' | 'error' | 'isLoading'
>;

const TableBody = ({ data, error, isLoading }: TableBodyProps) => {
  const { t } = useTranslation();

  const headerNames = useTranslatedHeaders();

  if (error) {
    return (
      <Table.Head className="text-center">
        <Table.HeadCell>{JSON.stringify(error)}</Table.HeadCell>
      </Table.Head>
    );
  }
  if (isLoading) {
    return (
      <Table.Head className="text-center">
        <Table.HeadCell>{t('common.loading')}</Table.HeadCell>
      </Table.Head>
    );
  }
  if (data.length === 0) {
    return (
      <Table.Head className="w-full text-center">
        <Table.HeadCell>
          {t('common.no-orders-for-this-search-criteria')}
        </Table.HeadCell>
      </Table.Head>
    );
  }
  return (
    <>
      <Table.Head className="bg-gray-100">
        {headerNames.map((name) => {
          return <HeadCell key={name} name={name} />;
        })}
      </Table.Head>
      <Table.Body className="whitespace-nowrap">
        {data.map(
          ({
            dsbStatusCreatedAt,
            ebkStatusCreatedAt,
            ebkStatus,
            dsbStatus,
            orderStatus,
            ticketPaymentStatus,
            ticketType,
            validFrom,
            validTo,
            distance,
            created,
            delivered,
            preOrderStatus,
            type,
            id,
            deliveryNotificationStatus,
            isResent,
          }) => {
            return (
              <Table.Row
                key={id}
                className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
              >
                <GoToCell id={id} type={snakeToCamel(String(type))} />
                <Cell content={<DateBreakHandler date={created} />} />
                <DeliveryCell
                  fromDate={created}
                  toDate={delivered}
                  status={deliveryNotificationStatus}
                  isResent={isResent}
                />
                <EbkDsbStatusCell
                  status={ebkStatus}
                  delay={differenceInSeconds(
                    parseISO(ebkStatusCreatedAt ?? created),
                    parseISO(created)
                  )}
                />
                <EbkDsbStatusCell
                  status={dsbStatus}
                  delay={differenceInSeconds(
                    parseISO(dsbStatusCreatedAt ?? created),
                    parseISO(created)
                  )}
                />
                <Cell
                  content={
                    validFrom ? <DateBreakHandler date={validFrom} /> : null
                  }
                />
                <Cell
                  content={validTo ? <DateBreakHandler date={validTo} /> : null}
                />
                {ticketType && distance ? (
                  <TicketTypeCell
                    type={ticketType}
                    distance={distance}
                    className="text-center border-2 border-gray-100"
                  />
                ) : (
                  <Cell content={null} />
                )}
                <TicketRefundCell
                  className="border-2 border-gray-100"
                  refundStatus={String(ticketPaymentStatus)}
                  ticketId={id}
                />
                <PaymentStatusCell paymentStatus={ticketPaymentStatus} />
                <StatusCell
                  type={type}
                  preOrderStatus={preOrderStatus}
                  orderStatus={orderStatus}
                  ticketPaymentStatus={ticketPaymentStatus}
                />
              </Table.Row>
            );
          }
        )}
      </Table.Body>
    </>
  );
};
