import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import { buildParams } from '../utils/buildParams/buildParams';
import type { SearchSchemaType } from '../schemas/searchSchema';
import type { IncomingMessagesDto } from '../dtos/IncomingMessagesDto';
import { IncomingMessagesDtoSchema } from '../dtos/IncomingMessagesDto';

type Args = {
  searchParams: {
    endDate: string;
    limit: string;
    operator: SearchSchemaType['operators'];
    page: string;
    startDate: string;
    phone?: string;
  };
  options?: UseQueryOptions<IncomingMessagesDto>;
};

export const useIncomingMessagesQuery = ({ searchParams, options }: Args) => {
  const search = buildParams(searchParams);

  return useAuthenticatedQuery(
    {
      schema: IncomingMessagesDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/incoming-messages/search?${search}`,
    },
    { ...options, queryKey: ['incoming-messages', searchParams], retry: false }
  );
};
