import { z } from 'zod';

const BOUserAttributeSchema = z.object({
  Name: z.string(),
  Value: z.string(),
});

const BOUserGroupSchema = z.union([
  z.literal('Superuser'),
  z.literal('Administrator'),
  z.literal('TeamLeader'),
  z.literal('FraudManager'),
  z.literal('BackofficeEmployee'),
  z.literal('CustomerServiceRW'),
  z.literal('CustomerServiceR'),
  z.literal('ProductOwner'),
]);

export type BOUserGroupSchemaDto = z.infer<typeof BOUserGroupSchema>;

export const BOUserSchema = z.object({
  Username: z.string(),
  Attributes: z.array(BOUserAttributeSchema),
  UserCreateDate: z.string(),
  UserLastModifiedDate: z.string(),
  Enabled: z.boolean(),
  UserStatus: z.string(),
  Groups: z.array(
    z.object({
      GroupName: BOUserGroupSchema,
      Precedence: z.number(),
    })
  ),
});

export type BOUserSchemaDto = z.infer<typeof BOUserSchema>;

export const CreateBOUserFormSchema = z.object({
  name: z.string(),
  userEmail: z.string(),
  userLevel: BOUserGroupSchema.optional().or(z.literal('')),
});

export type CreateBOUserFormSchemaDto = z.infer<typeof CreateBOUserFormSchema>;
