import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

type TRequireAuth = {
  children: JSX.Element;
};

export const RequireAuth = ({ children }: TRequireAuth) => {
  const { currentUser, loading } = useAuth();

  if (!loading && currentUser === null) {
    return <Navigate to="/login" replace />;
  } else {
    return children;
  }
};
