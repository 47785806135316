import clsx from 'clsx';
import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import type { TicketTypeDto } from '~/lib/dtos/common/TicketTypeDto';

type TicketTypeCellProps = {
  className?: string;
  type: TicketTypeDto;
  distance: number;
};

export const TicketTypeCell = ({
  className = '',
  type,
  distance,
}: TicketTypeCellProps) => {
  const { t } = useTranslation();

  const fixedTranslationTypes: Partial<Record<TicketTypeDto, string>> = {
    DSB1: t('ticket-types.dsb-regional-train-add-on'),
    SUPPLEMENTARY_CHILD: t('ticket-types.supplementary-child'),
    SUPPLEMENTARY_ADULT: t('ticket-types.supplementary-adult'),
    BIKE: t('ticket-types.bicycle-metro'),
  };

  const distanceRequiredTypes: Partial<Record<TicketTypeDto, string>> = {
    ADULT: t('ticket-types.x-zone-adult'),
    ADULT_WITH_BIKE: t('ticket-types.x-zone-adult-with-bike'),
    CHILD: t('ticket-types.x-zone-child'),
    CHILD_WITH_BIKE: t('ticket-types.x-zone-child-with-bike'),
    DOG: t('ticket-types.x-zone-dog'),
  };

  let ticketInfo = '';

  if (fixedTranslationTypes[type]) {
    ticketInfo = fixedTranslationTypes[type]!;
  } else if (distanceRequiredTypes[type]) {
    ticketInfo = `${distance} ${distanceRequiredTypes[type]}`;
  } else {
    ticketInfo = `${distance} zoner ${type.toLowerCase()}`;
  }

  return (
    <Table.Cell className={className}>
      <div className="whitespace-nowrap w-full text-center">
        <span>{ticketInfo}</span>
      </div>
    </Table.Cell>
  );
};
