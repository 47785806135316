import z from 'zod';

export const PreOrderStatusDtoSchema = z.union([
  z.literal('WAITING_FOR_HINTS_REPLY'),
  z.literal('WAITING_FOR_SECOND_HINTS_REPLY'),
  z.literal('DENIED'),
  z.literal('CANCELLED'),
  z.literal('COMPLETED'),
  z.literal('ABANDONED'),
  z.literal('CLOSED_WITH_OPERATOR_TIMEOUT'),
]);

export type PreOrderStatusDto = z.infer<typeof PreOrderStatusDtoSchema>;
