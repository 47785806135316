import { useAuthenticator } from '@aws-amplify/ui-react';
import { API, Auth } from 'aws-amplify';
import type { SetStateAction } from 'react';
import { useMemo, useState, createContext, useEffect } from 'react';
import type { RequestInitializer } from '../types';
import type { PermissionsDto } from '~/lib/dtos/PermissionsDto';

import type { UserGroupDto } from '~/lib/dtos/UserGroupDto';

export const CurrentUserContext = createContext<null | CurrentUserContextType>(
  null
);

type Props = {
  children: React.ReactNode;
};

export const CurrentUserContextProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState<null | CurrentUserData>(null);

  const { user } = useAuthenticator((context) => [context.user]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      setCurrentUser(null);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  useEffect(() => {
    if (currentUser !== null && currentUser.permissions !== null) {
      return;
    } else if (user !== undefined) {
      Auth.currentSession().then((session) => {
        const idToken = session.getIdToken();

        const requestInfo: RequestInitializer = {
          headers: { Authorization: 'Bearer ' + idToken.getJwtToken() },
        };
        API.get('DOT', '/users/permissions', requestInfo).then(
          (permissions) => {
            setCurrentUser({
              username: String(idToken.payload['cognito:username']),
              email: String(idToken.payload['email']),
              company: idToken.payload['custom:company'],
              groups: idToken.payload['cognito:groups'],
              permissions: permissions,
              lastResetPassword: idToken.payload['custom:lastResetPassword'],
            });
          }
        );
      });
    }
  }, [currentUser, user]);

  const memoizedContextValue = useMemo(
    () => ({ currentUser, setCurrentUser, signOut }),
    [currentUser, setCurrentUser, signOut]
  );

  return (
    <CurrentUserContext.Provider value={memoizedContextValue}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export type TCompany =
  | 'DSB'
  | 'METRO_SERVICE'
  | 'MOVIA'
  | 'DOT'
  | 'SIILI'
  | 'PLUSDIAL';

export type CurrentUserData = {
  permissions: PermissionsDto | null;
  username: string;
  email: string;
  company: TCompany;
  groups: UserGroupDto[];
  lastResetPassword?: string;
};

export type CurrentUserContextType = {
  currentUser: null | CurrentUserData;
  setCurrentUser: React.Dispatch<SetStateAction<null | CurrentUserData>>;
  signOut: () => void;
};
