import { Authenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import type { ProjectInfo } from '../../types';

const createFormFields = ({
  code,
  email,
}: {
  code: string | null;
  email: string | null;
}) => {
  if (code === null || email === null) {
    return {};
  }

  const formFields = {
    signIn: {
      username: {
        defaultValue: email,
        isReadOnly: false,
      },
      password: {
        defaultValue: code,
        isReadOnly: false,
      },
    },
  };

  return formFields;
};

const LoginPage = ({ projectInfo }: { projectInfo: ProjectInfo | null }) => {
  const [formFields, setFormFields] = useState<null | object>(null);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const email = searchParams.get('email');

  useEffect(() => {
    setFormFields(createFormFields({ code, email }));
  }, [code]);

  return (
    <div className="bg-gray-50 w-full h-screen">
      <div className="flex flex-col justify-center pt-4 align-middle">
        <img
          src="/assets/dot_logo.png"
          alt="dot logo"
          className="w-96 mx-auto"
        />
        {formFields !== null && (
          <Authenticator
            formFields={formFields}
            hideSignUp={true}
            signUpAttributes={['email']}
            className="mt-2"
          >
            <Navigate to="/" />
          </Authenticator>
        )}

        <span className="py-2 font-mono text-sm text-center text-gray-800">
          {projectInfo !== null
            ? projectInfo.env + ' system ' + projectInfo.ver
            : null}
        </span>
      </div>
    </div>
  );
};
export default LoginPage;
