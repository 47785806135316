import z from 'zod';
import { BlockReasonDTOSchema } from './common/BlockReasonDTO';

export const UserDtoSchema = z.object({
  user: z.object({
    canBlockPermanently: z.boolean(),
    canBlockTemporarily: z.boolean(),
    canUnblock: z.boolean(),
    isBlocked: z.boolean(),
    isWhitelisted: z.boolean(),
    moneyNotSpent: z.number(),
    moneySpent: z.number(),
    permanentBlockHistories: z.array(
      z.object({
        blockCreatedAt: z.string().datetime(),
        blockedBy: z.object({
          blockedBySystem: z.boolean(),
          value: z.string(),
        }),
        createdAt: z.string().datetime(),
        blockadeExpirationDate: z.string().datetime(),
        id: z.object({
          value: z.number(),
        }),
        note: z.object({
          value: z.string(),
        }),
        unblockNote: z.object({
          value: z.string(),
        }),
        unblockedBy: z.object({
          value: z.string(),
        }),
        userId: z.object({
          value: z.number(),
        }),
        blockReason: BlockReasonDTOSchema,
      })
    ),
    permanentlyBlockedUser: z
      .object({
        blockedBy: z.object({
          blockedBySystem: z.boolean(),
          value: z.string(),
        }),
        createdAt: z.string().datetime(),
        blockadeExpirationDate: z.string().datetime(),
        id: z.object({
          value: z.number(),
        }),
        note: z.object({
          value: z.string(),
        }),
        userId: z.object({
          value: z.number(),
        }),
        blockReason: BlockReasonDTOSchema,
      })
      .nullable(),
    phoneNumber: z.object({
      value: z.string(),
    }),
    provider: z.string(),
    purchasedTickets: z.number(),
    stoppedOrders: z.number(),
    temporarilyBlockedUser: z
      .object({
        blockadeExpirationDate: z.string().datetime(),
        blockedBy: z.object({
          blockedBySystem: z.boolean(),
          value: z.string(),
        }),
        createdAt: z.string().datetime(),
        id: z.object({
          value: z.number(),
        }),
        note: z.object({
          value: z.string(),
        }),
        userId: z.object({
          value: z.number(),
        }),
        blockReason: BlockReasonDTOSchema,
      })
      .nullable(),
    temporaryBlockHistories: z.array(
      z.object({
        blockCreatedAt: z.string().datetime(),
        blockadeExpirationDate: z.string().datetime(),
        blockedBy: z.object({
          blockedBySystem: z.boolean(),
          value: z.string(),
        }),
        createdAt: z.string().datetime(),
        id: z.object({
          value: z.number(),
        }),
        note: z.object({
          value: z.string(),
        }),
        unblockNote: z.object({
          value: z.string(),
        }),
        unblockedBy: z.object({
          value: z.string(),
        }),
        userId: z.object({
          value: z.number(),
        }),
        blockReason: BlockReasonDTOSchema,
      })
    ),
    uncompletedOrders: z.number(),
    userId: z.object({
      value: z.number(),
    }),
  }),
});

export type UserDto = z.infer<typeof UserDtoSchema>;
