import { useTranslation } from 'react-i18next';
import type { TicketTypeDto } from '~/lib/dtos/common/TicketTypeDto';

export const useTranslateTicketTypes = () => {
  const { t } = useTranslation();

  const ticketTypesMap: Record<
    TicketTypeDto | 'SUPPLEMENTARY' | 'HAS_BIKE' | 'WITHOUT_BIKE',
    string
  > = {
    ADULT: t('ticket-types.adult'),
    ADULT_WITH_BIKE: t('ticket-types.adult-with-bike'),
    BIKE: t('ticket-types.bicycle-metro'),
    CHILD: t('ticket-types.child'),
    CHILD_WITH_BIKE: t('ticket-types.child-with-bike'),
    DOG: t('ticket-types.dog'),
    DSB1: t('ticket-types.dsb-regional-train-add-on'),
    SUPPLEMENTARY_ADULT: t('ticket-types.supplementary-adult'),
    SUPPLEMENTARY_CHILD: t('ticket-types.supplementary-child'),
    SUPPLEMENTARY: t('ticket-types.supplementary'),
    HAS_BIKE: t('search.with-bike'),
    WITHOUT_BIKE: t('search.without-bike'),
  };

  const translateTicketTypes = (
    ticketType: TicketTypeDto | 'SUPPLEMENTARY' | 'HAS_BIKE' | 'WITHOUT_BIKE'
  ) => {
    return ticketTypesMap[ticketType] ?? ticketType;
  };

  return { translateTicketTypes };
};
