import z from 'zod';
import { PreOrderDtoSchema } from './common/PreOrderDto';
import { IncomingMessageDtoSchema } from './common/IncomingMessageDto';
import { OutgoingMessageDtoSchema } from './common/OutgoingMessageDto';
import { DeliveryNotificationDtoSchema } from './common/DeliveryNotificationDto';
import { BasicUserSchema } from './BasicUserDto';

export const BlockadeMessageDetailsDtoSchema = z.object({
  blockadeMessageDetails: z.object({
    outgoingMessageId: z.object({
      value: z.number(),
    }),
    user: z.object({
      createdAt: z.string(),
      id: z.object({
        value: z.number(),
      }),
      modifiedAt: z.string(),
      phoneNumber: z.object({
        value: z.string(),
      }),
      welcomed: z.boolean(),
    }),
    outgoingMessages: z.array(OutgoingMessageDtoSchema),
    deliveryNotifications: z.array(
      z.object({
        id: z.object({
          value: z.number(),
        }),
        outgoingMessageId: z.object({
          value: z.number(),
        }),
        externalId: z.object({
          value: z.number(),
        }),
        status: z.string(),
        timestamp: z.string(),
        createdAt: z.string(),
      })
    ),
  }),
});

export type BlockadeMessageDetailsDto = z.infer<
  typeof BlockadeMessageDetailsDtoSchema
>;
