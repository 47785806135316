import { Table } from 'flowbite-react';
import { OperatorCell, PhoneNumberCell } from '../components/TicketCells';
import { useTranslation } from 'react-i18next';
import type { BlockadeMessageDetailsDto } from '~/lib/dtos/BlockadeMessageDetailsDto';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';

type Props = {
  blockadeMessageDetails: BlockadeMessageDetailsDto['blockadeMessageDetails'];
};

const BlockadeMessageTable = ({ blockadeMessageDetails }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-none">
      <Table className="border-spacing-0 border border-separate border-gray-300 rounded-md shadow-md">
        <Table.Head className="bg-gray-100">
          <Table.HeadCell className="px-4 text-center">
            {t('tables.property')}
          </Table.HeadCell>
          <Table.HeadCell className="px-4 text-center">
            {t('tables.value')}
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className=" whitespace-nowrap">
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.created')}
            </Table.Cell>
            <Table.Cell className="px-4 text-center border border-gray-100">
              <DateBreakHandler
                date={blockadeMessageDetails.outgoingMessages[0].createdAt}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.phone-number-customer-profile')}
            </Table.Cell>
            <PhoneNumberCell
              className="px-4 text-center border border-gray-100"
              customerId={blockadeMessageDetails.user.id.value}
              phoneNumber={blockadeMessageDetails.user.phoneNumber.value}
            />
          </Table.Row>
          {/* <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.operator')}
            </Table.Cell>

            <OperatorCell
              operator={blockadeMessageDetails.provider.value ?? ''}
              className="px-4 text-center border border-gray-100"
            />
          </Table.Row> */}
        </Table.Body>
      </Table>
    </div>
  );
};
export default BlockadeMessageTable;
