import { useTranslation } from 'react-i18next';
import Header from '~/components/Header';
import ButtonWithLoading from '~/components/ButtonWithLoading';
import { Auth } from 'aws-amplify';
import NotyfContext from '~/context/NotyfContext';
import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Tooltip } from 'flowbite-react';

const moveDsbErrorQueueMutation = async () => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/dsb/recover`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

const fetchDsbQueueTicketCount = async () => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/dsb/tickets/count`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );
  return res.json();
};

const fetchDsbRefundQueueTicketCount = async () => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/dsb/refunds/count`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );

  return res.json();
};

const moveDsbRefundErrorQueueMutation = async () => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/dsb/refunds/recover`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

const Queues = () => {
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const queryClient = useQueryClient();
  const { mutate: moveDsbTickets, isLoading: isMoveDsbTicketsMutationLoading } =
    useMutation(moveDsbErrorQueueMutation, {
      onSuccess: () => {
        notyf.success(t('queues.tickets.move-dsb-error-queue-success'));
        queryClient.invalidateQueries({
          queryKey: 'dsbTicketsCounter',
        });
      },
      onError: () => {
        notyf.error(t('queues.tickets.move-dsb-error-queue-failed'));
      },
    });
  const { mutate: moveDsbRefunds, isLoading: isMoveDsbRefundsMutationLoading } =
    useMutation(moveDsbRefundErrorQueueMutation, {
      onSuccess: () => {
        notyf.success(t('queues.tickets.move-dsb-error-queue-success'));
        queryClient.invalidateQueries({
          queryKey: 'dsbRefundsCounter',
        });
      },
      onError: () => {
        notyf.error(t('queues.tickets.move-dsb-error-queue-failed'));
      },
    });

  const { data: dsbTicketCount, isLoading: isTicketCountLoading } =
    useQuery<number>(['dsbTicketsCounter'], () => fetchDsbQueueTicketCount(), {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    });

  const { data: dsbRefundCount, isLoading: isRefundCountLoading } =
    useQuery<number>(
      ['dsbRefundsCounter'],
      () => fetchDsbRefundQueueTicketCount(),
      {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    );

  return (
    <div className="flex flex-col flex-1 overflow-x-auto">
      <div className="flex items-center justify-between">
        <Header size="2xl">{t('queues.queues')}</Header>
      </div>

      <div className="whitespace-nowrap mt-2">
        <Header>{t('queues.tickets.dsb-error-queue')}</Header>
        <div className=" italic">
          <div className="pt-1 whitespace-normal">
            {t('queues.tickets.dsb-error-queue-description-1')}
          </div>
          <div className="whitespace-normal">
            {t('queues.tickets.dsb-error-queue-description-2')}
          </div>
          <div>{t('queues.tickets.dsb-error-queue-description-3')}</div>
        </div>

        <div className="py-1">
          {t('queues.tickets.number-of-tickets-in-dsb-error-queue')}:{' '}
          <strong>
            {isTicketCountLoading ? t('common.loading') : dsbTicketCount}
          </strong>
        </div>
        <div className="py-1">
          <Tooltip
            content={t('helper-texts.queues.move-ticket-error-queue')}
            placement="right"
          >
            <ButtonWithLoading
              text={t('queues.tickets.move-error-queue')}
              loading={isMoveDsbTicketsMutationLoading}
              onClick={() => moveDsbTickets()}
              type="button"
            />
          </Tooltip>
        </div>
      </div>

      <div className="whitespace-nowrap mt-2 border-t-2">
        <Header>{t('queues.refunds.dsb-error-queue')}</Header>
        <div className=" italic">
          <div className="pt-1 whitespace-normal">
            {t('queues.refunds.dsb-error-queue-description-1')}
          </div>
          <div className="whitespace-normal">
            {t('queues.refunds.dsb-error-queue-description-2')}
          </div>
          <div>{t('queues.refunds.dsb-error-queue-description-3')}</div>
        </div>

        <div className="py-1">
          {t('queues.refunds.number-of-tickets-in-dsb-error-queue')}:{' '}
          <strong>
            {isRefundCountLoading ? t('common.loading') : dsbRefundCount}
          </strong>
        </div>
        <div className="py-1">
          <Tooltip
            content={t('helper-texts.queues.move-refund-error-queue')}
            placement="right"
          >
            <ButtonWithLoading
              text={t('queues.refunds.move-error-queue')}
              loading={isMoveDsbRefundsMutationLoading}
              onClick={() => moveDsbRefunds()}
              type="button"
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Queues;
