import { useTranslation } from 'react-i18next';
import type { MessageTypeDto } from '~/lib/dtos/common/MessageTypeDto';
import type { MessageTypeCell } from './MessageTypeCell';

export const useMessageTypeMap = ({
  type,
}: Parameters<typeof MessageTypeCell>[number]) => {
  const { t } = useTranslation();

  const messageStatusMap: Partial<Record<MessageTypeDto, string>> = {
    other_message: t('other-messages.other-message'),
    incoming_message: t('other-messages.incoming-message'),
    invalid_order: t('other-messages.invalid-order'),
  };

  return type ? messageStatusMap[type] : type;
};

export const useMessageHeadingMap = ({
  type,
}: Parameters<typeof MessageTypeCell>[number]) => {
  const { t } = useTranslation();

  const messageHeadinMap: Partial<Record<MessageTypeDto, string>> = {
    other_message: t('other-messages.other-message-datails'),
    incoming_message: t('other-messages.incoming-message-datails'),
    invalid_order: t('other-messages.invalid-order-details'),
  };

  return type ? messageHeadinMap[type] + ' ' : type;
};
