import { useTranslation } from 'react-i18next';
import type { BlockReasonDTO } from '~/lib/dtos/common/BlockReasonDTO';

export const useTranslateBlockReasons = () => {
  const { t } = useTranslation();

  const blockReasonsMap: Record<BlockReasonDTO, string> = {
    UNCOMPLETED_ORDERS: t('customer.block-reasons.uncompleted-orders'),
    PURCHASE_AFTER_BOARDING: t(
      'customer.block-reasons.purchase_after_boarding'
    ),
    TICKET_FORGERY_OR_TRANSFER: t(
      'customer.block-reasons.ticket_forgery_or_transfer'
    ),
    INCORRECT_CUSTOMER_TYPE: t(
      'customer.block-reasons.incorrect_customer_type'
    ),
    INCORRECT_VALIDITY_OR_TICKET_TYPE: t(
      'customer.block-reasons.incorrect_validity_or_ticket_type'
    ),
    DEFAULT: '-',
  };

  const translateBlockReasons = (blockingReason: BlockReasonDTO) => {
    return blockReasonsMap[blockingReason] ?? blockingReason;
  };

  return { translateBlockReasons };
};
