import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import { useModal } from '~/hooks/useModal';
import z from 'zod';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Auth } from 'aws-amplify';
import { useMutation } from 'react-query';
import { useContext } from 'react';
import NotyfContext from '~/context/NotyfContext';
import { Modal } from '~/components/Modal/Modal';
import Header from '~/components/Header';
import ButtonWithLoading from '~/components/ButtonWithLoading';

const sendEmailMutation = async (data: TSendReceiptForm, ticketId: string) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/ticket/${ticketId}/receipt`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      method: 'POST',
    }
  ).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

type Props = {
  ticketId: string;
};

export type TSendReceiptForm = {
  email: string;
  note: string;
};

const NOTE_MAX_LENGTH = 500;
const NOTE_MIN_LENGTH = 10;

export const SendReceiptCell = ({ ticketId }: Props) => {
  const notyf = useContext(NotyfContext);

  const { t } = useTranslation();

  const schema = z.object({
    email: z
      .string()
      .email({ message: t('notes.ticket.send-receipt.invalid-email') }),
    note: z
      .string()
      .min(NOTE_MIN_LENGTH, { message: t('common.min-10-chars') })
      .or(z.literal('')),
  });
  const { handleSubmit, control, clearErrors, watch } =
    useForm<TSendReceiptForm>({
      resolver: zodResolver(schema),
      defaultValues: {
        email: '',
        note: '',
      },
    });

  const [modalVisibility, toggleModalVisibility] = useModal();

  const { mutate, isLoading: isSendingEmail } = useMutation({
    mutationFn: ({
      data,
      ticketId,
    }: {
      data: TSendReceiptForm;
      ticketId: string;
    }) => {
      return sendEmailMutation(data, ticketId);
    },
    onSuccess: () => {
      toggleModalVisibility();
      notyf.success(t('users.successfully-sent-receipt'));
    },
    onError: () => {
      notyf.error(t('status.error'));
    },
  });

  const hasPermissions = useHasPermissions('SEND_RECIPT');

  const sendEmail: SubmitHandler<TSendReceiptForm> = (data) => {
    mutate({ data, ticketId });
  };

  return (
    <Table.Cell className="border border-gray-100">
      <button
        disabled={!hasPermissions}
        onClick={() => {
          clearErrors();
          toggleModalVisibility();
        }}
        className="px-3 flex  mx-auto py-1.5 bg-blue-500 hover:bg-blue-600 text-white rounded-md cursor-pointer disabled:bg-gray-200 disabled:text-black disabled:cursor-not-allowed"
      >
        {t('common.send')}
      </button>
      <Modal
        isVisible={modalVisibility}
        toggleVisibility={() => {
          toggleModalVisibility();
        }}
      >
        <form onSubmit={handleSubmit(sendEmail)}>
          <div className="flex flex-col p-8 min-w-[500px]">
            <Header className="pb-2 text-left">
              {t('notes.ticket.send-receipt.email')}
            </Header>
            <div className="flex flex-col gap-2">
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="flex flex-col gap-2">
                      <input
                        {...field}
                        placeholder={t('tables.email')}
                        className="form-input p-2"
                      />
                      {error?.message && (
                        <span className="mr-auto text-red-500">
                          {error.message}
                        </span>
                      )}
                    </div>
                  );
                }}
              />
              <Header className="text-left">
                {t('notes.ticket.send-receipt.note')}
              </Header>
              <Controller
                name="note"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className="flex flex-col gap-2">
                      <textarea
                        {...field}
                        placeholder={t('notes.placeholder')}
                        className="form-input w-full p-2"
                        rows={6}
                        maxLength={NOTE_MAX_LENGTH}
                      />
                      {error?.message && (
                        <span className="mr-auto text-red-500">
                          {error.message}
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            </div>
            <div className="mt-2 ml-auto">
              <span
                className={`${
                  watch('note').length > NOTE_MAX_LENGTH && 'text-red-600'
                }`}
              >{`${watch('note').length}/${NOTE_MAX_LENGTH}`}</span>
            </div>

            <div className="mt-2 ml-auto">
              <ButtonWithLoading
                text={t('notes.ticket.send-receipt.button-text')}
                type="submit"
                loading={isSendingEmail}
              />
            </div>
          </div>
        </form>
      </Modal>
    </Table.Cell>
  );
};
