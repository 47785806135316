import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Button from '../../../components/Button';

import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import MessagesTable from '../messages/MessagesTable';
import { API, Auth } from 'aws-amplify';
import OrderTable from './OrderTable';
import { useOrderDetailsQuery } from '~/lib/hooks/useOrderDetailsQuery';
import { Table } from 'flowbite-react';
import { BarLoader } from 'react-spinners';
import { useDownloadSearchResults } from '~/hooks/useDownloadSearchResults';
import type { OrderDetailsDto } from '~/lib/dtos/OrderDetailsDto';
import { useHasPermissions } from '~/hooks/useHasPermissions';

const blockDateEnd = new Date();
blockDateEnd.setMonth(blockDateEnd.getMonth() + 1);

const dateStart = new Date();
dateStart.setMinutes(dateStart.getMinutes() - 30);

const dateEnd = new Date();
dateEnd.setMinutes(dateEnd.getMinutes() + 30);

const dateStart4T = new Date();
dateStart4T.setMinutes(dateStart4T.getMinutes() - 31);

const OrderDetailsContent = ({
  order,
  isFetching,
  isError,
}: {
  order: OrderDetailsDto | undefined;
  isFetching: boolean;
  isError: boolean;
}) => {
  const { t } = useTranslation();
  const { download } = useDownloadSearchResults({
    id: order?.detail.order.id.value,
    createdAt: order?.detail.order.createdAt,
    fileType: 'order',
  });

  const downloadVisible = useHasPermissions('DOWNLOAD_DATA');

  if (isFetching) {
    return (
      <div className="flex justify-center">
        <BarLoader color="#7f7f7f" />
      </div>
    );
  }

  if (isError || !order) {
    return <Header>{t('status.error')}</Header>;
  }
  return (
    <div className="flex w-full">
      <div className="flex flex-col">
        <OrderTable orderResponse={order} />
      </div>
      <div className=" flex flex-col w-full ml-8">
        <div className="flex justify-between pt-4 pb-2">
          <div className="mt-auto text-xl font-semibold text-left">
            {t('tables.messages')}
          </div>
          <div className=" mt-auto">
            <Button onClick={download} isVisible={downloadVisible}>
              {t('common.download-messages')}
            </Button>
          </div>
        </div>

        <MessagesTable
          deliveryNotifications={order.detail.deliveryNotifications}
          incomingMessages={order.detail.incomingMessages}
          outgoingMessages={order.detail.outgoingMessages}
        />
      </div>
    </div>
  );
};
const OrderDetails = () => {
  const { t } = useTranslation();

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: order,
    isFetching,
    refetch,
    isError,
  } = useOrderDetailsQuery(params.id ?? '', {
    enabled: Boolean(params.id),
  });

  return (
    <div className="">
      <div className=" flex justify-between">
        <Button className="w-auto" type="gray" onClick={() => navigate(-1)}>
          {t('common.back')}
        </Button>
        <div className="flex gap-2">
          <Button className="w-auto" type="gray" onClick={() => refetch()}>
            {t('common.refresh')}
          </Button>
        </div>
      </div>
      <div className="py-4 text-xl font-semibold">
        {`${t('search.order-details')} (Id: ${params.id})`}
      </div>
      <OrderDetailsContent
        order={order}
        isFetching={isFetching}
        isError={isError}
      />
    </div>
  );
};
export default OrderDetails;
