import { parseGMT } from '~/lib/utils/formatDates/formatDates';
import type { TUser } from './types';

export type TUserStatus =
  | 'ACTIVE'
  | 'RESEND'
  | 'INACTIVE'
  | 'DEACTIVATED'
  | 'RESET_REQUIRED'
  | 'PASSWORD_RESENT'
  | 'UNKNOWN_STATUS';

export const getUserStatus = ({
  UserStatus,
  Attributes,
  Enabled,
}: TUser): TUserStatus => {
  const lastLoggedInValue = Attributes?.find(
    (attribute) => attribute.Name === 'custom:lastLoggedIn'
  )?.Value;

  const lastLoggedInDate = lastLoggedInValue
    ? parseGMT(lastLoggedInValue)
    : null;

  const lastResetPasswordValue = Attributes?.find(
    (attribute) => attribute.Name === 'custom:lastResetPassword'
  )?.Value;

  const lastResetPasswordDate = lastResetPasswordValue
    ? parseGMT(lastResetPasswordValue)
    : null;

  if (!Enabled) {
    return 'DEACTIVATED';
  }
  if (UserStatus === 'RESET_REQUIRED') {
    return 'RESET_REQUIRED';
  }
  if (UserStatus === 'CONFIRMED') {
    return 'ACTIVE';
  }

  if (
    lastResetPasswordDate &&
    lastLoggedInDate &&
    UserStatus === 'FORCE_CHANGE_PASSWORD'
  ) {
    return 'INACTIVE';
  }
  if (UserStatus === 'FORCE_CHANGE_PASSWORD') {
    return 'RESEND';
  }
  if (UserStatus === 'PASSWORD_RESENT') {
    return 'PASSWORD_RESENT';
  }

  return 'UNKNOWN_STATUS';
};
