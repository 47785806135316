import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import type { BlockTempMessageDto } from '../dtos/BlockTempMessageDto';
import { BlockTempMessageDtoSchema } from '../dtos/BlockTempMessageDto';
import type { BlockReasonDTO } from '../dtos/common/BlockReasonDTO';

export const useBlockMessageQuery = (
  blockReason: BlockReasonDTO | 'EMPTY_REASON',
  blockType: 'temporary' | 'permanent' | null,
  options?: UseQueryOptions<BlockTempMessageDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: BlockTempMessageDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/message/block?reason=${blockReason}&type=${blockType}`,
    },
    { ...options, queryKey: [blockReason, blockType] }
  );
};
