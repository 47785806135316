import z from 'zod';

export const BlockReasonDTOSchema = z.union([
  z.literal('UNCOMPLETED_ORDERS'),
  z.literal('PURCHASE_AFTER_BOARDING'),
  z.literal('TICKET_FORGERY_OR_TRANSFER'),
  z.literal('INCORRECT_CUSTOMER_TYPE'),
  z.literal('INCORRECT_VALIDITY_OR_TICKET_TYPE'),
  z.literal('DEFAULT'),
]);

export type BlockReasonDTO = z.infer<typeof BlockReasonDTOSchema>;
