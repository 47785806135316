import { Table, Tooltip } from 'flowbite-react';
import { useMemo, useState } from 'react';

import { MessageCell } from '../components/TicketCells';
import { useTranslation } from 'react-i18next';
import { DeliveryCell } from '~/components/Cells/DeliveryCell';
import { MessageTypeCell } from '~/components/Cells/MessageTypeCell';
import type { IncomingMessageDto } from '~/lib/dtos/common/IncomingMessageDto';
import { type OutgoingMessageDto } from '~/lib/dtos/common/OutgoingMessageDto';
import type { DeliveryNotificationDto } from '~/lib/dtos/common/DeliveryNotificationDto';
import DownIcon from './DownIcon';
import UpIcon from './UpIcon';
import { formatDate } from '~/lib/utils/formatDates/formatDates';
import SortIndicator from '~/components/SortIndicator';

type UIIncomingMessage = IncomingMessageDto & {
  messageType: 'incomingMessage';
};
type UIOutgoingMessage = OutgoingMessageDto & {
  messageType: 'outgoingMessage';
};

const sortMessages = ({
  messages,
  sortDirection,
}: {
  messages: (UIIncomingMessage | UIOutgoingMessage)[];
  sortDirection: 'asc' | 'desc';
}) => {
  return messages.sort((a, b) =>
    sortDirection === 'asc'
      ? a.createdAt.localeCompare(b.createdAt)
      : -a.createdAt.localeCompare(b.createdAt)
  );
};

const MessagesTable = ({
  incomingMessages,
  outgoingMessages,
  deliveryNotifications,
}: {
  incomingMessages: IncomingMessageDto[];
  outgoingMessages: OutgoingMessageDto[];
  deliveryNotifications: DeliveryNotificationDto[];
}) => {
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const messages = useMemo(() => {
    outgoingMessages.forEach((message) => {
      const properDeliveryNotification = deliveryNotifications.find(
        (deliveryNotification) =>
          deliveryNotification.outgoingMessageId.value === message.id.value
      );
      message.deliveryNotification = properDeliveryNotification;
    });

    const incomingWithTypes: UIIncomingMessage[] = incomingMessages.map(
      (msg) => ({
        ...msg,
        messageType: 'incomingMessage',
      })
    );
    const outgoingWithTypes: UIOutgoingMessage[] = outgoingMessages.map(
      (msg) => ({
        ...msg,
        messageType: 'outgoingMessage',
      })
    );

    const messagesBeforeSort = [...incomingWithTypes, ...outgoingWithTypes];

    return sortMessages({ messages: messagesBeforeSort, sortDirection });
  }, [
    incomingMessages,
    outgoingMessages,
    deliveryNotifications,
    sortDirection,
  ]);

  const changeSortDirection = () => {
    setSortDirection((sortDirection) =>
      sortDirection === 'asc' ? 'desc' : 'asc'
    );
  };
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center">
      <Table className="border-spacing-0 w-full border border-separate border-gray-300 rounded-md shadow-md">
        {messages.length > 0 ? (
          <>
            <TableHead
              areIncoming={incomingMessages.length > 0}
              areOutgoing={outgoingMessages.length > 0}
              sortDirection={sortDirection}
              changeSortDirection={changeSortDirection}
            />
            <Table.Body className="divide-y">
              {messages.map((message, id) => {
                return (
                  <TableRow
                    message={message}
                    key={id}
                    areIncoming={incomingMessages.length > 0}
                    areOutgoing={outgoingMessages.length > 0}
                  />
                );
              })}
            </Table.Body>
          </>
        ) : (
          <Table.Head className="text-center">
            <Table.HeadCell>{t('common.loading')}</Table.HeadCell>
          </Table.Head>
        )}
      </Table>
    </div>
  );
};

const TableRow = ({
  message,
  areIncoming,
  areOutgoing,
}: {
  areIncoming: boolean;
  areOutgoing: boolean;
  message: UIIncomingMessage | UIOutgoingMessage;
}) => {
  const { t } = useTranslation();
  if (message.messageType === 'outgoingMessage') {
    return (
      <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
        <Table.Cell className="text-center border border-gray-100">
          <div className="flex justify-center h-full">
            <Tooltip
              content={t('helper-texts.messages.created.system')}
              placement="right"
            >
              {formatDate(message.createdAt)}
            </Tooltip>
          </div>
        </Table.Cell>
        <DeliveryCell
          isResent={false}
          fromDate={message.createdAt}
          toDate={message.deliveryNotification?.timestamp ?? null}
          status={message.deliveryNotification?.status ?? null}
        />

        <MessageTypeCell
          type={message.type}
          className="text-center border border-gray-100"
        />

        <>
          {areIncoming && (
            <Table.Cell className="text-center border border-gray-100"></Table.Cell>
          )}
          <MessageCell
            message={message.text.value}
            className="text-center border border-gray-100"
          />
        </>
      </Table.Row>
    );
  } else {
    return (
      <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
        <Table.Cell className="text-center border border-gray-100">
          <div className="flex justify-center h-full">
            <Tooltip
              content={t('helper-texts.messages.created.customer')}
              placement="right"
            >
              {formatDate(message.createdAt)}
            </Tooltip>
          </div>
        </Table.Cell>

        <Table.Cell className="text-center border border-gray-100">
          <div className="flex justify-center h-full">
            <Tooltip
              content={t('helper-texts.messages.delivered.customer')}
              placement="right"
            >
              {formatDate(message.deliveredAt)}
            </Tooltip>
          </div>
        </Table.Cell>

        <MessageTypeCell
          type={message.type}
          className="text-center border border-gray-100"
        />

        <>
          <MessageCell
            message={message.text.value}
            className="text-center border border-gray-100"
          />
          {areOutgoing && (
            <Table.Cell className="text-center border border-gray-100"></Table.Cell>
          )}
        </>
      </Table.Row>
    );
  }
};

interface TableHeadProps {
  changeSortDirection: () => void;
  sortDirection: 'asc' | 'desc';
  areIncoming: boolean;
  areOutgoing: boolean;
}
const TableHead = ({
  changeSortDirection,
  sortDirection,
  areIncoming,
  areOutgoing,
}: TableHeadProps) => {
  const { t } = useTranslation();
  return (
    <Table.Head className="bg-gray-100">
      <Table.HeadCell>
        <div
          className="gap flex justify-center my-auto text-center align-middle cursor-pointer"
          onClick={changeSortDirection}
        >
          <div className=" my-auto align-baseline">{t('tables.created')}</div>
          <SortIndicator sortOrder={sortDirection} />
        </div>
      </Table.HeadCell>

      <Table.HeadCell>
        <div className="flex justify-center my-auto text-center align-middle">
          {t('tables.delivered-at')}
        </div>
      </Table.HeadCell>

      <Table.HeadCell>
        <div className="flex justify-center my-auto text-center align-middle">
          {t('common.type')}
        </div>
      </Table.HeadCell>
      {areIncoming && (
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('tables.incoming-message')}
          </div>
        </Table.HeadCell>
      )}
      {areOutgoing && (
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('tables.outgoing-message')}
          </div>
        </Table.HeadCell>
      )}
    </Table.Head>
  );
};
export default MessagesTable;
