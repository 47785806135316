import { z } from 'zod';
import { UserDtoSchema } from './UserDto';
import { DeliveryNotificationDtoSchema } from './common/DeliveryNotificationDto';
import { PreOrderDtoSchema } from './common/PreOrderDto';
import { BasicUserSchema } from './BasicUserDto';
import { BlockadeOperationTypeDtoSchema } from './common/BlockadeOperationTypeDTO';

export const PreOrderUserDtoSchema = z.object({
  preOrder: PreOrderDtoSchema,
  user: BasicUserSchema,
  deliveryNotification: DeliveryNotificationDtoSchema.nullable(),
});

export const BlockadeMessagesDtoSchema = z.object({
  blockadeMessages: z.array(
    z.object({
      blockadeOperationType: BlockadeOperationTypeDtoSchema,
      createdAt: z.string().nullable(),
      id: z.object({
        value: z.number(),
      }),
      message: z.string(),
      phoneNumber: z.object({
        value: z.string(),
      }),
      responseDeliveredAt: z.string().nullable(),
      userId: z.object({
        value: z.number(),
      }),
      deliveryNotificationStatus: z.string().nullable(),
    })
  ),
});

export type BlockadeMessagesDto = z.infer<typeof BlockadeMessagesDtoSchema>;
