import z from 'zod';
import { TicketTypeDtoSchema } from './common/TicketTypeDto';
import { OrderStatusDtoSchema } from './common/OrderStatusDto';
import { IncomingMessageDtoSchema } from './common/IncomingMessageDto';
import { OutgoingMessageDtoSchema } from './common/OutgoingMessageDto';
import { BasicUserSchema } from './BasicUserDto';
import { DeliveryNotificationDtoSchema } from './common/DeliveryNotificationDto';

export const OrderSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  userId: z.object({
    value: z.number(),
  }),
  ticketType: TicketTypeDtoSchema,
  status: OrderStatusDtoSchema,
  startingZoneId: z.array(
    z.object({
      value: z.number(),
    })
  ),
  distance: z.object({
    value: z.number(),
  }),
  price: z.object({
    amount: z.number(),
    vat: z.number(),
  }),
  processId: z.object({
    value: z.string(),
  }),
  createdAt: z.string(),
  modifiedAt: z.string(),
  forBike: z.boolean(),
  // operator: OperatorsSchema,
});

export const OrderDetailsDtoSchema = z.object({
  detail: z.object({
    user: BasicUserSchema,
    order: OrderSchema,
    incomingMessages: z.array(IncomingMessageDtoSchema),
    outgoingMessages: z.array(OutgoingMessageDtoSchema),
    deliveryNotifications: z.array(DeliveryNotificationDtoSchema),
  }),
});

export type OrderDto = z.infer<typeof OrderSchema>;

export type OrderDetailsDto = z.infer<typeof OrderDetailsDtoSchema>;
