import { OrderStatusCell } from '~/components/Cells/OrderStatusCell';
import { PreOrderStatusCell } from '~/components/Cells/PreOrderStatusCell';
import type { Nullable } from '~/features/search/types';
import type { MessageTypeDto } from '~/lib/dtos/common/MessageTypeDto';
import type { OrderStatusDto } from '~/lib/dtos/common/OrderStatusDto';
import type { PreOrderStatusDto } from '~/lib/dtos/common/PreOrderStatusDto';
import type { TicketPaymentStatusDto } from '~/lib/dtos/common/TicketPaymentStatusDto';
import { MessageTypeCell } from './MessageTypeCell';

export const StatusCell = ({
  preOrderStatus,
  orderStatus,
  ticketPaymentStatus,
  type,
}: {
  preOrderStatus: Nullable<PreOrderStatusDto>;
  orderStatus: Nullable<OrderStatusDto>;
  ticketPaymentStatus: Nullable<TicketPaymentStatusDto>;
  type: MessageTypeDto;
}) => {
  if (preOrderStatus !== null) {
    return <PreOrderStatusCell preOrderStatus={preOrderStatus} />;
  }

  if (orderStatus !== null || ticketPaymentStatus !== null) {
    return (
      <OrderStatusCell
        orderStatus={orderStatus}
        paymentStatus={ticketPaymentStatus}
      />
    );
  }

  if (
    preOrderStatus === null &&
    orderStatus === null &&
    ticketPaymentStatus === null
  ) {
    return <MessageTypeCell type={type} />;
  }

  return <>{String(type)}</>;
};
