import { Table, Tooltip } from 'flowbite-react';
import {
  OperatorCell,
  PhoneNumberCell,
  TicketValidityCell,
} from '../components/TicketCells';
import { useTranslation } from 'react-i18next';
import { PaymentStatusCell } from '~/components/Cells/PaymentStatusCell';
import { RefundStatusCell } from '~/components/Cells/RefundStatusCell';
import { TicketTypeCell } from '~/components/Cells/TicketTypeCell';
import type { TicketDetailsDto } from '~/lib/dtos/TicketDto';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';

const TicketTable = ({
  ticketResponse,
}: {
  ticketResponse: TicketDetailsDto;
}) => {
  const ticket = ticketResponse.detail.ticket;
  const { t } = useTranslation();
  const validFrom = ticket.ticketTimeValidity.validFrom;
  const validTo = ticket.ticketTimeValidity.validTo;
  const createdAt = ticket.createdAt;

  if (!ticket) {
    return (
      <Table>
        <Table.Head className="text-center">
          <Table.Cell className="px-4 text-center border border-gray-100">
            {t('common.loading')}
          </Table.Cell>
        </Table.Head>
      </Table>
    );
  }

  return (
    <div className="flex">
      <Table className="border-spacing-0 w-full border border-separate border-gray-300 rounded-md shadow-lg">
        <Table.Head className="">
          <Table.HeadCell className="text-center bg-gray-100">
            {t('tables.property')}
          </Table.HeadCell>
          <Table.HeadCell className="text-center bg-gray-100">
            {t('tables.value')}
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className=" whitespace-nowrap">
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.created')}
            </Table.Cell>
            <Table.Cell className="px-4 text-center border border-gray-100">
              <DateBreakHandler date={createdAt} />
            </Table.Cell>
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.payment-id')}
            </Table.Cell>
            <Table.Cell className="flex justify-center px-4 text-center border border-gray-100">
              <Tooltip
                content={t('helper-texts.order-info.four-t-payment-id')}
                placement="right"
              >
                {ticketResponse.detail.paymentId?.value}
              </Tooltip>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.phone-number-customer-profile')}
            </Table.Cell>
            <PhoneNumberCell
              className="px-4 text-center border border-gray-100"
              phoneNumber={ticketResponse.detail.user.phoneNumber.value}
              customerId={ticketResponse.detail.user.id.value}
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.operator')}
            </Table.Cell>
            <OperatorCell
              operator={
                ticketResponse.detail.incomingMessages[0].provider.value
              }
              className="px-4 text-center border border-gray-100"
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.senders-number')}
            </Table.Cell>
            <Table.Cell className="flex justify-center px-4 text-center border border-gray-100">
              <Tooltip
                content={t('helper-texts.order-info.sender-number')}
                placement="right"
              >
                {ticket.codes.sendersNumber.value}
              </Tooltip>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.ticket-type')}
            </Table.Cell>
            <TicketTypeCell
              type={ticket.type}
              distance={ticket.distance.value}
              className="px-4 text-center border border-gray-100"
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.ticket-price')}
            </Table.Cell>
            <Table.Cell className="px-4 text-center border border-gray-100">
              {ticket.price.amount}
            </Table.Cell>
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.payment-status')}
            </Table.Cell>
            <PaymentStatusCell paymentStatus={ticket.paymentStatus} />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.refund-status')}
            </Table.Cell>
            <RefundStatusCell
              refundStatus={
                ticketResponse.detail.refund
                  ? ticketResponse.detail.refund.status
                  : null
              }
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.ticket-validity-start')}
            </Table.Cell>
            <Table.Cell className="px-4 text-center border border-gray-100">
              <DateBreakHandler date={validFrom} />
            </Table.Cell>
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.ticket-validity-end')}
            </Table.Cell>
            <TicketValidityCell
              className="px-4 text-center border border-gray-100"
              ticketValidEnd={validTo}
              isRefunded={ticket.paymentStatus === 'PAYMENT_REFUNDED'}
            />
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
export default TicketTable;
