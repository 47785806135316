import z from 'zod';

export const DeliveryNotificationDtoSchema = z.object({
  createdAt: z.string(),
  externalId: z.object({
    value: z.number(),
  }),
  id: z.object({
    value: z.number(),
  }),
  outgoingMessageId: z.object({
    value: z.number(),
  }),
  status: z.string(),
  timestamp: z.string(),
});

export type DeliveryNotificationDto = z.infer<
  typeof DeliveryNotificationDtoSchema
>;
