import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import type { DashboardOperator } from '../dtos/DashboardOperatorDto';
import { DashboardOperatorDtoSchema } from '../dtos/DashboardOperatorDto';

export const useDashboardOperatorQuery = <
  Data = DashboardOperator,
  Error = unknown
>(
  refreshTime: null | string,
  operator: 'hi3g' | 'sonofon' | 'tdc' | 'telia',
  options?: UseQueryOptions<DashboardOperator, Error, Data>
) => {
  return useAuthenticatedQuery(
    {
      schema: DashboardOperatorDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/dashboard/basic/${operator}`,
    },
    {
      ...options,
      queryKey: ['dashboardOperator', refreshTime, operator],
    }
  );
};
