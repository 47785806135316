import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import type { PreOrderDetailsDto } from '../dtos/PreOrderDetailsDto';
import { PreOrderDetailsDtoSchema } from '../dtos/PreOrderDetailsDto';

export const usePreOrderDetailsQuery = (
  id: string,
  options: UseQueryOptions<PreOrderDetailsDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: PreOrderDetailsDtoSchema,
      url: `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/preorder/${id}`,
    },
    { ...options, queryKey: ['preOrderDetails', id] }
  );
};
