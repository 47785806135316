import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import NotyfContext from '~/context/NotyfContext';
import { useModal } from '~/hooks/useModal';
import type { UserDto } from '~/lib/dtos/UserDto';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import { useBlockMessageQuery } from '~/lib/hooks/useBlockMessageQuery';
import { useQueryClient } from 'react-query';
import { NoteModal } from '~/components/Modal/NoteModal';
import Select from 'react-select';
import { useTranslateBlockReasons } from '~/hooks/useTranslateBlockReasons';
import type { BlockReasonDTO } from '~/lib/dtos/common/BlockReasonDTO';
import { useBlockCustomerMutation } from '~/lib/hooks/useBlockCustomerMutation';

export const CustomerProfileBlockActions = ({
  user,
}: {
  user: UserDto['user'];
}) => {
  const queryClient = useQueryClient();

  const hasCustomerBlockPermission = useHasPermissions(
    'BLOCK_UNBLOCK_CUSTOMER'
  );

  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);

  const [modalIsVisibleTempBlock, toggleModalVisibilityTempBlock] = useModal();
  const [modalIsVisiblePermBlock, toggleModalVisibilityPermBlock] = useModal();
  const [modalIsVisibleUnblock, toggleModalVisibilityUnblock] = useModal();

  const {
    blockCustomerMutation: {
      mutate: blockCustomer,
      isLoading: isBlockingCustomer,
    },
    unblockCustomerMutation: {
      mutate: unblockCustomer,
      isLoading: isUnblockingCustomer,
    },
  } = useBlockCustomerMutation(user.phoneNumber.value);

  const blockTemporarily = (
    customerId: number,
    telephoneNumber: string,
    note: string
  ) => {
    if (blockReason === 'EMPTY_REASON') {
      notyf.error(t('customer.choose-blocking-reason'));
      return;
    }

    blockCustomer(
      {
        customerId,
        note,
        blockReason,
        type: 'temporary',
        toggleModal: toggleModalVisibilityTempBlock,
      },
      {
        onSuccess: () => {
          setBlockReason('EMPTY_REASON');
        },
      }
    );
  };

  const blockTemporarilyClick = (customerInfo: UserDto['user']) => {
    if (customerInfo.canBlockTemporarily) {
      setBlockType('temporary');
      toggleModalVisibilityTempBlock();
    } else {
      notyf.error(t('customer.can-not-temporarily-block-user-if-blocked'));
    }
  };

  const blockPermanentlyRequest = (
    customerId: number,
    telephoneNumber: string,
    note: string
  ) => {
    if (blockReason === 'EMPTY_REASON') {
      notyf.error(t('customer.choose-blocking-reason'));
      return;
    }

    blockCustomer(
      {
        customerId,
        note,
        blockReason,
        type: 'permanent',
        toggleModal: toggleModalVisibilityPermBlock,
      },
      {
        onSuccess: () => {
          setBlockReason('EMPTY_REASON');
        },
      }
    );
  };
  const blockPermanentlyButtonClick = (customerInfo: UserDto['user']) => {
    if (customerInfo.canBlockPermanently) {
      setBlockType('permanent');
      toggleModalVisibilityPermBlock();
    } else {
      notyf.error(t('customer.can-not-permanently-block-user-if-blocked'));
    }
  };
  const unblockRequest = (
    customerId: number,
    telephoneNumber: string,
    note: string
  ) => {
    unblockCustomer({
      customerId,
      note,
      toggleModal: toggleModalVisibilityUnblock,
    });
  };
  const unblock = (customerInfo: UserDto['user']) => {
    if (customerInfo.canUnblock) {
      toggleModalVisibilityUnblock();
    } else {
      notyf.error(t('customer.can-not-unblock-user-if-not-blocked'));
    }
  };
  const { translateBlockReasons } = useTranslateBlockReasons();

  const [blockReason, setBlockReason] = useState<
    BlockReasonDTO | 'EMPTY_REASON'
  >('EMPTY_REASON');

  const [blockType, setBlockType] = useState<'temporary' | 'permanent' | null>(
    null
  );

  const blockReasonOptions: {
    value: BlockReasonDTO | 'EMPTY_REASON';
    label: string;
  }[] = [
    {
      value: 'EMPTY_REASON',
      label: t('customer.select-blocking-reason'),
    },
    {
      value: 'UNCOMPLETED_ORDERS',
      label: translateBlockReasons('UNCOMPLETED_ORDERS'),
    },
    {
      value: 'PURCHASE_AFTER_BOARDING',
      label: translateBlockReasons('PURCHASE_AFTER_BOARDING'),
    },
    {
      value: 'TICKET_FORGERY_OR_TRANSFER',
      label: translateBlockReasons('TICKET_FORGERY_OR_TRANSFER'),
    },
    {
      value: 'INCORRECT_CUSTOMER_TYPE',
      label: translateBlockReasons('INCORRECT_CUSTOMER_TYPE'),
    },
    {
      value: 'INCORRECT_VALIDITY_OR_TICKET_TYPE',
      label: translateBlockReasons('INCORRECT_VALIDITY_OR_TICKET_TYPE'),
    },
  ];

  const { data: blockMessageData, isLoading: blockMessageLoading } =
    useBlockMessageQuery(blockReason, blockType, {
      enabled: blockReason !== 'EMPTY_REASON' && blockType !== null,
    });

  return (
    <div className="flex items-center justify-center w-full gap-4">
      <Button
        className="w-auto"
        type={user.canBlockTemporarily ? 'default' : 'gray'}
        onClick={() => {
          blockTemporarilyClick(user);
        }}
        disabled={!hasCustomerBlockPermission}
      >
        {t('customer.block-temporarily')}
      </Button>

      <NoteModal
        isVisible={modalIsVisibleTempBlock}
        toggleVisibility={() => {
          toggleModalVisibilityTempBlock();
          setBlockReason('EMPTY_REASON');
          setBlockType(null);
        }}
        isLoading={isBlockingCustomer}
        onSubmit={(note) =>
          blockTemporarily(user.userId.value, user.phoneNumber.value, note)
        }
        submitText={t('notes.customer.block-temp.button-text')}
        title={
          t('notes.customer.block-temp.title') + ' ' + user.phoneNumber.value
        }
      >
        <div className="flex pb-2">
          <Select
            value={
              blockReasonOptions.find(({ value }) => value === blockReason) ??
              null
            }
            onChange={(option) => {
              if (!option) {
                setBlockReason('EMPTY_REASON');
                return;
              }
              setBlockReason(option.value);
            }}
            defaultValue={
              blockReasonOptions.find(
                ({ value }) => value === 'EMPTY_REASON'
              ) ?? null
            }
            isSearchable={false}
            options={
              blockReasonOptions.filter(
                ({ value }) => value !== 'EMPTY_REASON'
              ) ?? []
            }
          />
        </div>
        {blockReason !== 'EMPTY_REASON' && (
          <>
            <div className="text-left">
              <strong>{t('customer.message-to-be-sent')}</strong>
            </div>
            <p className="pb-2">
              {blockMessageLoading || !blockMessageData
                ? t('common.loading')
                : blockMessageData.message}
            </p>
          </>
        )}
      </NoteModal>

      <Button
        className="w-auto"
        type={user.canBlockPermanently ? 'default' : 'gray'}
        onClick={() => {
          blockPermanentlyButtonClick(user);
        }}
        disabled={!hasCustomerBlockPermission}
      >
        {t('customer.block-permanently')}
      </Button>

      <NoteModal
        isVisible={modalIsVisiblePermBlock}
        toggleVisibility={() => {
          toggleModalVisibilityPermBlock();
          setBlockReason('EMPTY_REASON');
          setBlockType(null);
        }}
        isLoading={isBlockingCustomer}
        onSubmit={(note) =>
          blockPermanentlyRequest(
            user.userId.value,
            user.phoneNumber.value,
            note
          )
        }
        submitText={t('notes.customer.block-perm.button-text')}
        title={
          t('notes.customer.block-perm.title') + ' ' + user.phoneNumber.value
        }
      >
        <div className="flex pb-2">
          <Select
            value={
              blockReasonOptions.find(({ value }) => value === blockReason) ??
              null
            }
            onChange={(option) => {
              if (!option) {
                setBlockReason('EMPTY_REASON');
                return;
              }
              setBlockReason(option.value);
            }}
            defaultValue={
              blockReasonOptions.find(
                ({ value }) => value === 'EMPTY_REASON'
              ) ?? null
            }
            isSearchable={false}
            options={
              blockReasonOptions.filter(
                ({ value }) => value !== 'EMPTY_REASON'
              ) ?? []
            }
          />
        </div>
        {blockReason !== 'EMPTY_REASON' && (
          <>
            <div className="text-left">
              <strong>{t('customer.message-to-be-sent')}</strong>
            </div>
            <p className="pb-2">
              {blockMessageLoading || !blockMessageData
                ? t('common.loading')
                : blockMessageData.message}
            </p>
          </>
        )}
      </NoteModal>

      <Button
        className={user.canUnblock ? '' : 'disabled'}
        type={user.canUnblock ? 'default' : 'gray'}
        onClick={() => {
          unblock(user);
        }}
        disabled={!hasCustomerBlockPermission}
      >
        {t('customer.unblock')}
      </Button>

      <NoteModal
        isVisible={modalIsVisibleUnblock}
        toggleVisibility={toggleModalVisibilityUnblock}
        isLoading={isUnblockingCustomer}
        onSubmit={(note) =>
          unblockRequest(user.userId.value, user.phoneNumber.value, note)
        }
        submitText={t('notes.customer.unblock.button-text')}
        title={t('notes.customer.unblock.title') + ' ' + user.phoneNumber.value}
      />
    </div>
  );
};
