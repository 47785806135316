import { I18n } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language.includes('dn')) {
      setCurrentLanguage('dn');
      i18n.changeLanguage('dn');
      I18n.setLanguage('dn');
    } else {
      setCurrentLanguage('en');
      i18n.changeLanguage('en');
      I18n.setLanguage('en');
    }
  }, []);

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (language: string) => {
    if (language === 'en') {
      setCurrentLanguage('en');
      i18n.changeLanguage('en');
      I18n.setLanguage('en');
    }
    if (language === 'dn') {
      setCurrentLanguage('dn');
      i18n.changeLanguage('dn');
      I18n.setLanguage('dn');
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {' '}
      <div className="py-2 text-lg font-semibold">
        {t('main.select-language')}
      </div>
      <div className="flex gap-2">
        <button
          onClick={() => changeLanguage('dn')}
          className={
            currentLanguage === 'dn'
              ? `text-white  focus:ring-4 focus:outline-none 
           font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center
           bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`
              : 'text-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-gray-200 hover:bg-gray-300 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
          }
        >
          {t('main.danish')}
        </button>
        <button
          onClick={() => changeLanguage('en')}
          className={
            currentLanguage === 'en'
              ? `text-white  focus:ring-4 focus:outline-none 
           font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center
           bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`
              : 'text-black  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-gray-200 hover:bg-gray-300 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
          }
        >
          {t('main.english')}
        </button>
      </div>
    </>
  );
};
export default LanguageSelect;
