import i18n from 'i18next';

export const options = {
  afterClose: () => {},
  buttons: [
    {
      label: i18n.t('common.yes'),
      onClick: () => {},
    },
    {
      label: i18n.t('common.no'),
      onClick: () => {},
    },
  ],
  childrenElement: () => <div />,
  closeOnClickOutside: true,
  closeOnEscape: true,
  keyCodeForClose: [8, 32],
  message: '',
  onClickOutside: () => {},
  onKeypressEscape: () => {},
  overlayClassName: 'whitespace-pre-line',
  title: '',
  willUnmount: () => {},
};
