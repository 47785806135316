import z from 'zod';

export const OutGoingMessageTypeDtoSchema = z.union([
  z.literal('WELCOME'),
  z.literal('ALREADY_PROCESSING'),
  z.literal('GET_CONFIRMATION'),
  z.literal('HINTS'),
  z.literal('ERROR_PARSING'),
  z.literal('TICKET'),
  z.literal('ONE_ZONE_ERROR'),
  z.literal('WRONG_DISTANCE'),
  z.literal('INVALID_ZONES'),
  z.literal('WRONG_START_ZONE'),
  z.literal('CANCEL'),
  z.literal('ERROR'),
  z.literal('FIRST_WARNING'),
  z.literal('TEMPORARILY_BLOCKED'),
  z.literal('PERMANENTLY_BLOCKED'),
  z.literal('TICKET_REFUNDED'),
  z.literal('CREATING_TEMPORARY_BLOCK'),
  z.literal('CREATING_PERMANENT_BLOCK'),
  z.literal('UNBLOCK'),
  z.literal('WRONG_CONFIRMATION_TRY_AGAIN'),
  z.literal('WARNING_1_MESSAGE'),
  z.literal('WARNING_2_MESSAGE'),
  z.literal('WARNING_3_MESSAGE'),
  z.literal('WARNING_4_MESSAGE'),
  z.literal('WARNING_5_MESSAGE'),
  z.literal('WARNING_6_MESSAGE'),
  z.literal('DELAYED_MESSAGE'),
  z.literal('TEMPORARILY_BLOCKED_MANUALLY'),
  z.literal('PERMANENTLY_BLOCKED_MANUALLY'),
  z.literal('TEMPORARILY_BLOCKED_AUTOMATICALLY'),
  z.literal('PERMANENTLY_BLOCKED_AUTOMATICALLY'),
  z.literal('RECEIPT_ORDERED'),
  z.literal('NO_TICKETS_FOR_RECEIPT'),
  z.literal('INVALID_RECEIPT_ORDER'),
  z.literal('GENERIC_BILLING_ERROR'),
  z.literal('GENERIC_OPERATOR_ERROR'),
]);

export type OutGoingMessageTypeDto = z.infer<
  typeof OutGoingMessageTypeDtoSchema
>;
