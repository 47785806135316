import z from 'zod';
import { TicketTypeDtoSchema } from './TicketTypeDto';
import { PreOrderStatusDtoSchema } from './PreOrderStatusDto';
import { OperatorsSchema } from './OperatorsDTO';

export const PreOrderDtoSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  userId: z.object({
    value: z.number(),
  }),
  ticketType: TicketTypeDtoSchema,
  status: PreOrderStatusDtoSchema,
  stationName: z.object({
    value: z.string(),
  }),
  distance: z.object({
    value: z.number(),
  }),
  processId: z.object({
    value: z.string(),
  }),
  createdAt: z.string(),
  modifiedAt: z.string(),
  operator: OperatorsSchema,
});

export type PreOrderDto = z.infer<typeof PreOrderDtoSchema>;
