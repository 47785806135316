import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import type { OrderDetailsDto } from '../dtos/OrderDetailsDto';
import { OrderDetailsDtoSchema } from '../dtos/OrderDetailsDto';

export const useOrderDetailsQuery = (
  id: string,
  options: UseQueryOptions<OrderDetailsDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: OrderDetailsDtoSchema,
      url: `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/order/${id}`,
    },
    { ...options, queryKey: ['orderDetails', id] }
  );
};
