import { useTranslation } from 'react-i18next';
import type { PermissionTypeDto } from '~/lib/dtos/PermissionsDto';

export const useTranslatePermissions = () => {
  const { t } = useTranslation();

  const permissionMap: Record<PermissionTypeDto | 'CREATE_EDIT_USER', string> =
    {
      READ_DATA: t('permissions.read-data'),
      RESEND_TICKET: t('permissions.resend-ticket'),
      REFUND_TICKET: t('permissions.refund-ticket'),
      SEND_RECIPT: t('permissions.send-receipt'),
      BLOCK_UNBLOCK_CUSTOMER: t('permissions.block-unblock-customer'),
      VIEW_USERS: t('permissions.view-users'),
      VIEW_AUDIT_LOG: t('permissions.view-audit-log'),
      CHANGE_GROUP: t('permissions.change-group'),
      CREATE_USER: t('permissions.create-user'),
      DEACTIVATE_ACTIVATE_USER: t('permissions.deactivate-activate-user'),
      RESEND_PASSWORD_USER: t('permissions.resend-password-user'),
      EDIT_FULLNAME_USER: t('permissions.edit-fullname-user'),
      DELETE_USER: t('permissions.delete-user'),
      UPLOAD_INSTRUCTIONS: t('permissions.upload-instructions'),
      MANAGE_BULLETINS: t('permissions.manage-bulletins'),
      CREATE_EDIT_USER: t('permissions.create-edit-user'),
      VIEW_DASHBOARD: t('permissions.view-dashboard'),
      MOVE_DSB_QUEUE: t('permissions.move-dsb-queue'),
      MOVE_DSB_REFUND_QUEUE: t('permissions.move-dsb-refund-queue'),
      DOWNLOAD_DATA: t('permissions.download-data'),
    };

  const translatePermissions = (
    permission: PermissionTypeDto | 'CREATE_EDIT_USER'
  ) => {
    return permissionMap[permission] ?? permission;
  };

  return { translatePermissions };
};
