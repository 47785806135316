import { useTranslation } from 'react-i18next';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';

type PaymentStatus = SearchSchemaType['paymentStatus'];

export const usePaymentStatuses = () => {
  const { t } = useTranslation();

  const paymentStatusMap: Record<Exclude<PaymentStatus, 'NONE'>, string> = {
    CAPTURED: t('search.captured'),
    CAPTURE_FAILED: t('search.capture-failed'),
    REFUNDED: t('search.refunded'),
    REFUND_FAILED: t('search.refund-failed'),
    AUTHORIZATION_FAILED: t('search.authorization-failed'),
    AUTHORIZED: t('search.authorized'),
  };

  const translatePaymentTypes = (
    paymentStatus: Exclude<PaymentStatus, 'NONE'>
  ) => {
    if (!paymentStatus) {
      return '';
    }

    return paymentStatusMap[paymentStatus];
  };

  const translatedOptions = Object.entries(paymentStatusMap).map(
    ([key, value]) => ({ label: value, value: key })
  ) as { label: string; value: Exclude<PaymentStatus, 'NONE'> }[];

  return { translatePaymentTypes, translatedOptions };
};
