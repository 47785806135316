import { useTranslation } from 'react-i18next';
import type { TicketNoteTypeDto } from '~/lib/dtos/common/TicketNoteType';

export const useTranslateTicketNoteType = () => {
  const { t } = useTranslation();

  const ticketNoteTypeMap: Record<TicketNoteTypeDto, string> = {
    REFUND: t('refund.refund'),
    RESEND: t('tables.resend'),
    SEND_RECEIPT: t('tables.send-receipt'),
    STANDALONE: t('tables.ticket-note'),
  };

  const translateTicketNoteType = (ticketNoteType: TicketNoteTypeDto) => {
    return ticketNoteTypeMap[ticketNoteType] ?? ticketNoteType;
  };

  return { translateTicketNoteType };
};
