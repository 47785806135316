import { useLocation } from 'react-router';
import { decodeParams } from '~/lib/utils/decodeParams/decodeParams';
import type { AuditLogSearchSchemaType } from '~/features/maintenance/audit/useAuditLog';
import { subDays } from 'date-fns';

export const useDecodeAuditLogParams = () => {
  const { search } = useLocation();

  const decodedParams = decodeParams<Partial<AuditLogSearchSchemaType>>(
    search.replaceAll('?', '')
  );

  const defaultParams: AuditLogSearchSchemaType = {
    startDate: subDays(new Date(), 30).toISOString(),
    endDate: new Date().toISOString(),
    type: undefined,
    userName: '',
    email: '',
    sortOrder: 'desc',
    page: '1',
    limit: '10',
  };
  const finalParams = { ...defaultParams, ...decodedParams };

  return [finalParams, decodedParams] as const;
};
