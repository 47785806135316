import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import { useModal } from '~/hooks/useModal';
import { Auth } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import type { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import NotyfContext from '~/context/NotyfContext';
import { NoteModal } from '~/components/Modal/NoteModal';

const resendTicketMutation = async (note: string, ticketId: string) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/ticket/${ticketId}/resend`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ note }),
      method: 'POST',
    }
  ).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

type Props = {
  ticketId: string;
};

export const ResendTicketCell = ({ ticketId }: Props) => {
  const notyf = useContext(NotyfContext);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useModal();

  const { mutate, isLoading: isResendingTicket } = useMutation({
    mutationFn: ({ note, ticketId }: { note: string; ticketId: string }) => {
      return resendTicketMutation(note, ticketId);
    },
    onSuccess: () => {
      setIsModalOpen();
      notyf.success(t('status.success'));
      queryClient.invalidateQueries({ queryKey: ['ticket', ticketId] });
    },
    onError: () => {
      notyf.error(t('status.error'));
    },
  });

  const hasPermissions = useHasPermissions('RESEND_TICKET');

  const sendEmail = (note: string) => {
    mutate({ note, ticketId });
  };

  return (
    <Table.Cell className="border border-gray-100">
      <button
        disabled={!hasPermissions}
        onClick={() => {
          setIsModalOpen();
        }}
        className="px-3 flex  mx-auto py-1.5 bg-blue-500 hover:bg-blue-600 text-white rounded-md cursor-pointer disabled:bg-gray-200 disabled:text-black disabled:cursor-not-allowed"
      >
        {t('tables.resend')}
      </button>
      <NoteModal
        isVisible={isModalOpen}
        toggleVisibility={setIsModalOpen}
        isLoading={isResendingTicket}
        onSubmit={sendEmail}
        submitText={t('notes.ticket.resend.button-text')}
        title={t('notes.ticket.resend.title')}
      />
    </Table.Cell>
  );
};
