import type { UseQueryOptions } from 'react-query';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import Header from '~/components/Header';
import type { TUser, TUserGroup } from '../types';
import { Container } from '~/components/Layout';
import { MapUserLevels } from '~/features/profile/Profile';
import Button from '~/components/Button';
import * as z from 'zod';
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextInput } from '~/components/Input';
import ErrorMessage from '~/components/ErrorMessage';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import { GroupSelectControlled } from '../GroupSelect';
import { useContext, useEffect, useMemo, useState } from 'react';
import PermissionsTable, {
  fetchAllPermissionsRequest,
  fetchGroupPermissionsRequest,
} from '../PermissionsTable';
import type { PermissionsDto } from '~/lib/dtos/PermissionsDto';

import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import NotyfContext from '~/context/NotyfContext';
import { useAuthenticatedQuery } from '~/lib/hooks/common/useAuthenticatedQuery';
import { BOUserSchema } from '~/lib/dtos/BOUserDto';
import type { BOUserSchemaDto } from '~/lib/dtos/BOUserDto';

type UserProfileFormData = {
  fullName: string;
};

const EditUserProfileSchema = z.object({
  fullName: z
    .string()
    .min(3, 'Full name must be at least 3 characters long')
    .max(100, 'Full name must be at most 100 characters long'),
});

type EditFullNameProps = {
  username: string;
  fullNameFromData: string;
};

const EditFullName: React.FC<EditFullNameProps> = ({
  fullNameFromData,
  username,
}: EditFullNameProps) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();

  const [editFullName, setEditFullName] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>(fullNameFromData);

  const methods = useFormContext<UserProfileFormData>();

  const { control, watch } = methods;
  const formFullName = watch('fullName');

  const changeFullNameRequest = async (mutationData: {
    username: string;
    newFullName: string;
  }) => {
    const { username, newFullName } = mutationData;
    const session = await Auth.currentSession();

    const jwtToken = session.getIdToken().getJwtToken();

    return fetch(
      `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/users/change_fullname`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          username: username,
          newFullName: newFullName,
        }),
      }
    ).then((res) => {
      if (res.ok) {
        return res;
      }

      throw new Error();
    });
  };

  const { mutate, isLoading } = useMutation(changeFullNameRequest, {
    onSuccess: () => {
      setEditFullName(false);
      setFullName(formFullName);
      methods.reset({ fullName: '' });
    },
  });

  const handleSaveClick = () => {
    if (formFullName.trim() === '') {
      notyf.error(t('users.full-name-cannot-be-empty'));
      return;
    }
    mutate({ username, newFullName: formFullName });
  };

  return (
    <div className="flex">
      {editFullName ? (
        <div className="flex">
          <Controller
            name="fullName"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <TextInput {...rest} placeholder={fullName} />
            )}
            rules={{ required: 'Full name is required' }}
          />
          {methods.formState.errors.fullName && (
            <ErrorMessage
              errorMessage={methods.formState.errors.fullName.message}
            />
          )}
          <div className="gap-x-1 flex px-2 mt-auto">
            <div>
              <Button onClick={handleSaveClick}>
                {isLoading ? t('common.saving') : t('common.save')}
              </Button>
            </div>
            <div>
              <Button type="gray" onClick={() => setEditFullName(false)}>
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex">
          {t('users.full-name') + ': '}
          <span
            className="px-2 font-semibold cursor-pointer"
            onClick={() => setEditFullName(true)}
          >
            {fullName}
          </span>
          <svg
            onClick={() => setEditFullName(true)}
            className=" cursor-pointer"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.8787 3.70705C17.0503 2.53547 18.9498 2.53548 20.1213 3.70705L20.2929 3.87862C21.4645 5.05019 21.4645 6.94969 20.2929 8.12126L18.5556 9.85857L8.70713 19.7071C8.57897 19.8352 8.41839 19.9261 8.24256 19.9701L4.24256 20.9701C3.90178 21.0553 3.54129 20.9554 3.29291 20.7071C3.04453 20.4587 2.94468 20.0982 3.02988 19.7574L4.02988 15.7574C4.07384 15.5816 4.16476 15.421 4.29291 15.2928L14.1989 5.38685L15.8787 3.70705ZM18.7071 5.12126C18.3166 4.73074 17.6834 4.73074 17.2929 5.12126L16.3068 6.10738L17.8622 7.72357L18.8787 6.70705C19.2692 6.31653 19.2692 5.68336 18.8787 5.29283L18.7071 5.12126ZM16.4477 9.13804L14.8923 7.52185L5.90299 16.5112L5.37439 18.6256L7.48877 18.097L16.4477 9.13804Z"
              fill="currentColor"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
type TError = {
  code: string;
  error: string;
  message: string;
  statusCode: number;
};

// typeguards
function isUser(obj: any): obj is TUser {
  return (
    obj &&
    typeof obj.Username === 'string' &&
    Array.isArray(obj.Attributes) &&
    typeof obj.UserCreateDate === 'string' &&
    typeof obj.UserLastModifiedDate === 'string' &&
    typeof obj.Enabled === 'boolean' &&
    typeof obj.UserStatus === 'string' &&
    Array.isArray(obj.Groups)
  );
}
type Args = {
  searchParams: {
    username: string;
  };
  options?: UseQueryOptions<BOUserSchemaDto>;
};

export const useBOUserQuery = ({ searchParams, options }: Args) => {
  const decodedUsername = decodeURIComponent(searchParams.username ?? '');
  return useAuthenticatedQuery(
    {
      schema: BOUserSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/users/${encodeURIComponent(decodedUsername ?? '')}`,
    },
    { ...options, queryKey: ['bo-user', searchParams] }
  );
};

const UserProfile: React.FC = () => {
  const { t } = useTranslation();
  const { username } = useParams<{ username: string }>();
  const decodedUsername = decodeURIComponent(username ?? '');
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState<TUserGroup | null>(null);
  const [groupPermissions, setGroupPermissions] =
    useState<null | PermissionsDto>(null);
  const [allPermissions, setAllPermissions] = useState<null | PermissionsDto>(
    null
  );
  // useAuthenticatedQuery

  const { data, isLoading, isError, refetch } = useBOUserQuery({
    searchParams: { username: decodedUsername },
    options: { refetchOnWindowFocus: false },
  });

  const methods = useForm<z.infer<typeof EditUserProfileSchema>>({
    resolver: zodResolver(EditUserProfileSchema),
    defaultValues: { fullName: '' },
  });

  const hasChangeGroupPermission = useHasPermissions('CHANGE_GROUP');

  const hasEditFullNamePermission = useHasPermissions('EDIT_FULLNAME_USER');

  const hasPermissionsToViewAuditLog = useHasPermissions('VIEW_AUDIT_LOG');

  const fetchGroupPermissionsData = async () => {
    if (!selectedGroup) return;

    try {
      const permissions = await fetchGroupPermissionsRequest(selectedGroup);
      setGroupPermissions(permissions);
    } catch (error) {
      console.error(`Failed to fetch group data for ${selectedGroup}`, error);
    }
  };

  const fetchAllPermissionsData = async () => {
    try {
      const permissions = await fetchAllPermissionsRequest();
      setAllPermissions(permissions);
    } catch (error) {
      console.error(`Failed to fetch group data for ${selectedGroup}`, error);
    }
  };

  useEffect(() => {
    if (selectedGroup) fetchGroupPermissionsData();
    if (!groupPermissions) fetchAllPermissionsData();
  }, [selectedGroup]);

  const { email, mappedGroups, fullNameFromData } = useMemo(() => {
    if (!isUser(data))
      return { email: '', mappedGroups: [], fullNameFromData: '' };

    const email = data.Attributes?.find(
      (attribute) => attribute.Name === 'email'
    )?.Value as string;

    const mappedGroups = data.Groups.sort((role) => role.Precedence).map(
      (role) => role.GroupName as TUserGroup
    );

    const fullNameFromData = data
      ? data.Attributes?.find((attribute) => attribute.Name === 'name')?.Value
      : '';

    return { email, mappedGroups, fullNameFromData };
  }, [data]);

  if (isLoading) {
    return (
      <Container>
        <div className="w-full p-2 text-center">{t('common.loading')}</div>
        <BarLoader color="#7f7f7f" className="mx-auto" />
      </Container>
    );
  }

  if (isError) {
    return <div>Error</div>;
  }

  if (!data) {
    return null;
  }

  const searchParams = new URLSearchParams();
  searchParams.append('email', email ?? '');

  return (
    <FormProvider {...methods}>
      <Container>
        <div className="flex flex-col w-full overflow-x-auto">
          <div className="flex justify-between">
            <Button className="w-auto" type="gray" onClick={() => navigate(-1)}>
              {t('common.back')}
            </Button>
            {hasPermissionsToViewAuditLog ? (
              <Link to={`/maintenance/audit_log?${searchParams.toString()}`}>
                <Button>{t('users.check-user-history')}</Button>
              </Link>
            ) : null}
          </div>
          <Header>{t('users.user-profile') + ` (${email})`}</Header>
          <div className="flex justify-between">
            <div className="my-auto">
              <div className="pt-4">
                {hasEditFullNamePermission ? (
                  <EditFullName
                    fullNameFromData={fullNameFromData ?? ''}
                    username={data.Username}
                  />
                ) : (
                  <div className=" lex">
                    {t('users.full-name')}:
                    <span className="mx-1 font-semibold">
                      {fullNameFromData}
                    </span>
                  </div>
                )}
              </div>
              <div className="py-2">
                <span className="mr-1">{t('users.user-level')}:</span>

                {hasChangeGroupPermission ? (
                  <GroupSelectControlled
                    username={data.Username}
                    propsGroups={data.Groups}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                  />
                ) : (
                  <span className="font-semibold">
                    <MapUserLevels roles={mappedGroups} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        {groupPermissions && allPermissions ? (
          <PermissionsTable
            groupPermissions={groupPermissions}
            allPermissions={allPermissions}
          />
        ) : null}
      </Container>
    </FormProvider>
  );
};

export default UserProfile;
