import { Spinner, Table } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../../components/Button';
import Pagination from '../components/Pagination';
import { GoToCell, PhoneNumberCell } from '../components/TicketCells';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import { goToTop } from '../utils';
import RecordsPerPageSelect from '../components/RecordsPerPageSelect';
import { useDecodeSearchParams } from '~/hooks/useDecodeSearchParams';
import { DeliveryCell } from '~/components/Cells/DeliveryCell';
import { useBlockadeMessagesCountQuery } from '~/lib/hooks/useBlockadeMessagesCountQuery';
import { useBlockadeMessagesQuery } from '~/lib/hooks/useBlockadeMessagesQuery';
import type { BlockadeMessagesDto } from '~/lib/dtos/BlockadeMessagesDto';
import { buildParams } from '~/lib/utils/buildParams/buildParams';
import { defaultValues } from '~/hooks/useSearch';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';
import { BlockadeTypeCell } from '../incomingMessages/components/BlockadeTypeCell';
import { useTranslateColumnNames } from '~/hooks/useTranslateColumnNames';
import type { ColumnsVisibilityDto } from '~/lib/dtos/common/ColumnsVisibilityDto';
import ColumnVisibilityControl from '../components/ColumnVisibilityControl';

const initialColumnVisibility: ColumnsVisibilityDto = {
  DETAILS: true,
  CREATED: true,
  PHONE_NUMBER: true,
  DELIVERY_STATUS: true,
  STATUS: true,
};

const BlockadeMessagesTable = () => {
  const [params, originalParams] = useDecodeSearchParams();

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(
    params.blockadeMessagesPage
      ? Number(params.blockadeMessagesPage)
      : Number(defaultValues.blockadeMessagesPage)
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    params.blockadeMessagesResultsPerPage
      ? Number(params.blockadeMessagesResultsPerPage)
      : Number(defaultValues.blockadeMessagesResultsPerPage)
  );

  const navigate = useNavigate();

  const {
    data: blockadeMessages,
    isError,
    isLoading,
  } = useBlockadeMessagesQuery({
    searchParams: {
      startDate: params.startDate!,
      endDate: params.endDate!,
      limit: String(itemsPerPage),
      operator: params.operators ?? [],
      page: String(currentPage),
      phone: params.phoneNumber,
    },
    options: {
      enabled: Boolean(params.endDate && params.startDate),
    },
  });

  const blockadeMessagesCount = useBlockadeMessagesCountQuery({
    searchParams: {
      startDate: params.startDate!,
      endDate: params.endDate!,
      limit: String(itemsPerPage),
      operator: params.operators ?? [],
      page: String(currentPage),
      phone: params.phoneNumber,
    },
    options: {
      enabled: Boolean(params.endDate && params.startDate),
    },
  });

  useEffect(() => {
    if (!blockadeMessagesCount.data?.availablePages) {
      return;
    }

    if (blockadeMessagesCount.data.availablePages <= currentPage - 1) {
      setCurrentPage(blockadeMessagesCount.data.availablePages);
      return;
    }
  }, [blockadeMessagesCount.data?.availablePages]);

  useEffect(() => {
    const updatedParams = buildParams({
      ...originalParams,
      blockadeMessagesPage: currentPage.toString(),
      blockadeMessagesResultsPerPage: itemsPerPage.toString(),
    });

    navigate('/orders?' + updatedParams);
  }, [currentPage, itemsPerPage]);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const [columnVisibility, setColumnVisibility] =
    useState<ColumnsVisibilityDto>(() => {
      const savedVisibility = localStorage.getItem(
        'BlockadeMessagesTableColumnVisibility'
      );
      return savedVisibility
        ? JSON.parse(savedVisibility)
        : initialColumnVisibility;
    });

  const handleColumnVisibilityChange =
    (column: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedColumnVisibility = {
        ...columnVisibility,
        [column]: e.target.checked,
      };
      setColumnVisibility(updatedColumnVisibility);

      localStorage.setItem(
        'BlockadeMessagesTableColumnVisibility',
        JSON.stringify(updatedColumnVisibility)
      );
    };

  const { translateColumnName } = useTranslateColumnNames();

  return (
    <div className="flex flex-col justify-center px-2 mt-4 overflow-x-auto">
      <div className="flex justify-between">
        <Header className="mt-auto mb-2">
          {t('search.blocking-messages')}
        </Header>
        <div className="flex">
          <div className="flex flex-col mt-auto">
            <ColumnVisibilityControl
              columnVisibility={columnVisibility}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
              translateColumnName={translateColumnName}
              prefix="blocking_messages"
              excludedColumns={['DETAILS', 'PHONE_NUMBER']}
            />
          </div>
          <RecordsPerPageSelect
            type="lg"
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>

      <Table className="border-spacing-0 mt-2 border border-separate border-gray-300 rounded-md shadow-lg">
        <TableBody
          blockadeMessages={blockadeMessages?.blockadeMessages}
          isError={isError}
          isLoading={isLoading}
          columnVisibility={columnVisibility}
        />
      </Table>
      {isLoading ? (
        <div className="w-full mt-2 text-center">
          <Spinner />
        </div>
      ) : (
        <Pagination
          onPageChange={handlePageClick}
          pageCount={blockadeMessagesCount.data?.availablePages ?? 0}
          currentPage={currentPage - 1}
          resultsNumber={blockadeMessagesCount.data?.count ?? 0}
        />
      )}

      {itemsPerPage > 10 && (
        <div className="flex justify-between">
          <Button type="gray" onClick={() => navigate(-1)}>
            {t('common.back')}
          </Button>
          <Button type="gray" onClick={goToTop}>
            {t('common.go-to-top')}
          </Button>
        </div>
      )}
    </div>
  );
};

type TableBodyProps = {
  isLoading: boolean;
  isError: boolean;
  blockadeMessages?: BlockadeMessagesDto['blockadeMessages'];
  columnVisibility: ColumnsVisibilityDto;
};

const TableBody = ({
  blockadeMessages,
  isLoading,
  isError,
  columnVisibility,
}: TableBodyProps) => {
  const { t } = useTranslation();

  const { translateColumnName } = useTranslateColumnNames();
  if (isError) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>{t('status.error')}</Table.HeadCell>
        </Table.Head>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>{t('common.loading')}</Table.HeadCell>
        </Table.Head>
      </div>
    );
  }

  if (!blockadeMessages) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>
            {t('common.no-orders-for-this-search-criteria')}
          </Table.HeadCell>
        </Table.Head>
      </div>
    );
  }

  if (blockadeMessages.length === 0) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>
            {t('common.no-orders-for-this-search-criteria')}
          </Table.HeadCell>
        </Table.Head>
      </div>
    );
  }

  return (
    <>
      <Table.Head className="bg-gray-100">
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {translateColumnName('DETAILS')}
          </div>
        </Table.HeadCell>
        {columnVisibility.CREATED && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('CREATED')}
            </div>
          </Table.HeadCell>
        )}
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {translateColumnName('PHONE_NUMBER')}
          </div>
        </Table.HeadCell>
        {columnVisibility.DELIVERY_STATUS && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('DELIVERY_STATUS')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.OUTGOING_MESSAGE && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('OUTGOING_MESSAGE')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.STATUS && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('STATUS')}
            </div>
          </Table.HeadCell>
        )}
      </Table.Head>
      <Table.Body className="divide-y">
        {blockadeMessages.map(
          ({
            id,
            createdAt,
            message,
            responseDeliveredAt,
            userId,
            phoneNumber,
            deliveryNotificationStatus,
            blockadeOperationType,
          }) => {
            return (
              <Table.Row
                key={id.value}
                className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
              >
                <GoToCell type="blockades" id={String(id.value)} />

                {columnVisibility.CREATED && (
                  <Table.Cell className="text-center border border-gray-100">
                    <DateBreakHandler date={createdAt} />
                  </Table.Cell>
                )}

                <PhoneNumberCell
                  className="text-center border border-gray-100"
                  customerId={userId.value}
                  phoneNumber={String(phoneNumber.value)}
                />

                {columnVisibility.DELIVERY_STATUS && (
                  <DeliveryCell
                    fromDate={createdAt}
                    toDate={responseDeliveredAt ?? null}
                    status={deliveryNotificationStatus ?? null}
                  />
                )}

                {columnVisibility.OUTGOING_MESSAGE && (
                  <Table.Cell className="text-center border border-gray-100">
                    {message}
                  </Table.Cell>
                )}

                {columnVisibility.STATUS && (
                  <BlockadeTypeCell
                    type={blockadeOperationType}
                    className="text-center border border-gray-100"
                  />
                )}
              </Table.Row>
            );
          }
        )}
      </Table.Body>
    </>
  );
};

export default BlockadeMessagesTable;
