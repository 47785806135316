import { Auth } from 'aws-amplify';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslateEventLogTypes } from './useTranslateEventLogTypes';
import type { LogTypesDto } from '~/lib/dtos/LogTypesDto';

const fetchLogTypes = async () => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/logs/types`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );

  if (!res.ok) {
    throw new Error("Couldn't fetch log types.");
  }

  return res.json();
};

export const useFetchLogTypes = () => {
  const { data, error, isLoading } = useQuery<LogTypesDto>(
    'logTypes',
    fetchLogTypes
  );

  const { translateEvents } = useTranslateEventLogTypes();

  const logTypesOptions = useMemo(() => {
    if (!data?.types) {
      return [];
    }

    return data.types.map((logType) => ({
      value: logType,
      label: translateEvents(logType),
    }));
  }, [data]);

  return {
    data,
    error,
    isLoading,
    logTypesOptions,
  };
};
