import { Auth } from 'aws-amplify';
import debounce from 'lodash.debounce';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import type { QueryFunctionContext, InfiniteData } from 'react-query';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import type { TUser } from './types';

export interface UserResponse {
  Users: TUser[];
  NextToken?: string;
}

interface ViewUsersProps {
  company?: string;
  itemsPerPage?: number;
  showAll: boolean;
  setUsers: Dispatch<SetStateAction<[] | TUser[]>>;
}

const getAuthToken = async (): Promise<string> => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();
  return jwtToken;
};

const fetchUsers = async ({
  pageParam,
  queryKey,
}: QueryFunctionContext<QueryKey>): Promise<UserResponse> => {
  const nextToken = pageParam;
  const [_key, company, itemsPerPage, showAll, email] = queryKey;
  const token = await getAuthToken();
  const headers = { Authorization: 'Bearer ' + token };

  let urlPath = `${
    import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
  }/users?limit=${itemsPerPage}`;

  if (!showAll) {
    if (!company) throw new Error('Company is required');
    urlPath += '&company=' + company.toLowerCase();
  }
  if (email) {
    urlPath += '&email=' + email;
  }
  if (nextToken) {
    urlPath += '&nextToken=' + encodeURIComponent(nextToken);
  }
  const res = await fetch(urlPath, {
    headers,
  });
  if (!res.ok) {
    throw new Error('Error fetching users');
  }
  return res.json();
};

type QueryKey = [string, string | undefined, number, boolean, string | null];

export type UpdateType =
  | { type: 'fullName'; newFullName: string }
  | { type: 'activate' }
  | { type: 'deactivate' }
  | { type: 'delete' }
  | { type: 'resendPassword' };

export const useUsers = ({
  company,
  itemsPerPage,
  showAll,
  setUsers,
}: ViewUsersProps) => {
  const [displayPage, setDisplayPage] = useState(0);

  const { data, error, fetchNextPage, hasNextPage, isLoading, isFetching } =
    useInfiniteQuery<UserResponse, Error, UserResponse, QueryKey>(
      ['users', company, itemsPerPage ?? 0, showAll, null],
      fetchUsers,
      {
        getNextPageParam: (lastPage, allPages) => {
          if (!lastPage.NextToken) return undefined;
          return lastPage.NextToken;
        },
        refetchOnWindowFocus: false,
      }
    );

  const currentPage = displayPage + 1;
  const lastPage = data?.pages.length || 1;

  const [shouldFetchNextPage, setShouldFetchNextPage] = useState(false);

  const incrementPage = () => {
    if (hasNextPage) {
      fetchNextPage().then(() => {
        setShouldFetchNextPage(true);
      });
    }
  };
  useEffect(() => {
    if ((shouldFetchNextPage && data?.pages.length) ?? 0 > displayPage + 1) {
      setDisplayPage((old) => old + 1);
      setShouldFetchNextPage(false);
    }
  }, [shouldFetchNextPage, data?.pages.length, displayPage]);

  const decrementPage = () => {
    setDisplayPage((old) => Math.max(old - 1, 0));
  };

  const updateUser = (username: string, updateType: UpdateType) => {
    setUsers((oldUsers) => {
      const updatedUsers = oldUsers.map((user) => {
        if (user.Username !== username) {
          return user;
        }

        switch (updateType.type) {
          case 'fullName':
            return {
              ...user,
              Attributes: user.Attributes.map((attr) =>
                attr.Name === 'name'
                  ? { Name: 'name', Value: updateType.newFullName }
                  : attr
              ),
            };
          case 'activate':
            return { ...user, Enabled: true };
          case 'deactivate':
            return { ...user, Enabled: false };
          case 'delete':
            return { ...user, UserStatus: 'DELETED' };
          case 'resendPassword':
            return { ...user, UserStatus: 'PASSWORD_RESENT' };
          default:
            return user;
        }
      });

      return updatedUsers.filter((user) => user.UserStatus !== 'DELETED');
    });
  };
  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    updateUser,
    incrementPage,
    decrementPage,
    currentPage,
    displayPage,
    lastPage,
  };
};
