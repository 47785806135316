import { Auth } from 'aws-amplify';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import debounce from 'lodash.debounce';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import type { EventLogTypeDto } from '~/lib/dtos/common/EventLogTypeDto';
import type { LogsDto } from '~/lib/dtos/LogsDto';
import { subDays } from 'date-fns';
import { buildParams } from '~/lib/utils/buildParams/buildParams';
import { useNavigate } from 'react-router';
import { useDecodeAuditLogParams } from '~/hooks/useDecodeAuditLogParams';

export type AuditLogSearchSchemaType = {
  limit: string;
  page: string;
  type?: EventLogTypeDto;
  name?: string;
  userName?: string;
  email?: string;
  sortBy?: 'username' | 'name' | 'email';
  sortOrder?: 'asc' | 'desc';
  startDate?: string;
  endDate?: string;
};

const fetchLogs = async (params: AuditLogSearchSchemaType) => {
  const paramsWithoutFalsyValues = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v)
  );
  const searchParams = new URLSearchParams(paramsWithoutFalsyValues);

  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/logs?${searchParams}`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );

  return res.json();
};

export const useAuditLog = () => {
  const [initialParams] = useDecodeAuditLogParams();

  const {
    startDate: startDateFromParams = subDays(new Date(), 30),
    endDate: endDateFromParams = new Date(),
    type: initialtype = null,
    userName: initialUserName = '',
    email: initialEmail = '',
    sortOrder: initialSortOrder = 'desc',
    page: pageFromParams = '1',
    limit: limitFromParams = '10',
  } = initialParams;

  const initialStartDate = new Date(startDateFromParams);
  const initialEndDate = new Date(endDateFromParams);
  const initialPage = Number(pageFromParams);
  const initialLimit = Number(limitFromParams);

  const hasViewAuditLogPermissions = useHasPermissions('VIEW_AUDIT_LOG');

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const [type, setType] = useState<EventLogTypeDto | null>(initialtype);

  const [userName, setUserName] = useState(initialUserName);
  const [email, setEmail] = useState(initialEmail);

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(initialSortOrder);

  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);

  const [firstLoad, setFirstLoad] = useState(false);
  const [secondLoad, setSecondLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const {
      limit,
      page,
      type,
      userName,
      email,
      sortOrder,
      startDate,
      endDate,
    } = initialParams;

    if (email) {
      setEmail(email);
    }

    if (userName) {
      setUserName(userName);
    }

    if (limit) {
      setLimit(parseInt(limit));
    }

    if (page) {
      setPage(parseInt(page));
    }
    if (type) {
      setType(type);
    }

    if (sortOrder) {
      setSortOrder(sortOrder);
    }

    if (startDate) {
      setStartDate(new Date(startDate));
    }

    if (endDate) {
      setEndDate(new Date(endDate));
    }
  }, []);

  useEffect(() => {
    const params = buildParams({
      page: page.toString(),
      limit: limit.toString(),
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      userName,
      email,
      sortOrder,
      type,
    });
    navigate('/maintenance/audit_log?' + params, { replace: true });
    // this useEffect is called twice on initial load because of setting the initial values from the url
    // we want to inicate if initial load is done or not so we can set the page to 1 when the user changes the search criteria
    if (!firstLoad) {
      setFirstLoad(true);
    } else if (firstLoad && !secondLoad) {
      setSecondLoad(true);
    }
  }, [userName, email, type, startDate, endDate, limit, page]);

  useEffect(() => {
    const isReloaded = sessionStorage.getItem('reloaded');
    if (isReloaded) {
      setToDefault();
      sessionStorage.removeItem('reloaded');
    }
    if (secondLoad) {
      setPage(1);
    }
  }, [userName, email, type, startDate, endDate, limit]);

  useEffect(() => {
    // Indicate browser refresh to reset values to default
    const handleBeforeUnload = () => {
      sessionStorage.setItem('reloaded', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const { data, isLoading, error, isFetching } = useQuery<LogsDto>(
    [
      'logs',
      { page, limit, startDate, endDate, userName, email, sortOrder, type },
    ],
    () =>
      fetchLogs({
        page: page.toString(),
        limit: limit.toString(),
        email,
        name: userName,
        type: type ?? undefined,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: hasViewAuditLogPermissions,
    }
  );

  const updateUserName = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const updateEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const debouncedUsernameUpdate = debounce(updateUserName, 1000);

  const debouncedEmailUpdate = debounce(updateEmail, 1000);
  const setToDefault = () => {
    setStartDate(subDays(new Date(), 30));
    setEndDate(new Date());
    setType(null);
    setSortOrder('desc');
    setPage(1);
    setLimit(10);
    setEmail('');
    setUserName('');
  };
  return {
    data,
    isLoading,
    error,
    isFetching,
    searchCriteria: {
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      type,
      setType,
      userName,
      debouncedUsernameUpdate,
      debouncedEmailUpdate,
      email,
      setEmail,
      sortOrder,
      setSortOrder,
      page,
      setPage,
      limit,
      setLimit,
      setUserName,
      setToDefault,
    },
  };
};
