import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import type { IncomingMessageDetailsDto } from '../dtos/IncomingMessageDetailsDto';
import { IncomingMessageDetailsDtoSchema } from '../dtos/IncomingMessageDetailsDto';

export const useIncomingMessageDetailsQuery = (
  id: string,
  options: UseQueryOptions<IncomingMessageDetailsDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: IncomingMessageDetailsDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/incoming-messages/${id}`,
    },
    { ...options, queryKey: ['incomingMessageDetails', id] }
  );
};
