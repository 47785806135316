import { useTranslation } from 'react-i18next';

type Props = {
  onSelectAll: () => void;
  onClearAll: () => void;
};

export const SelectClearAll = ({ onClearAll, onSelectAll }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="sm:mt-0 flex mt-2">
      <button
        type="button"
        onClick={onClearAll}
        className="mr-4 text-blue-600 cursor-pointer"
      >
        {t('common.deselect-all')}
      </button>
      <button
        type="button"
        onClick={onSelectAll}
        className="mr-4 text-blue-600 cursor-pointer"
      >
        {t('common.select-all')}
      </button>
    </div>
  );
};
