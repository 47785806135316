import { Table } from 'flowbite-react';
import { OperatorCell, PhoneNumberCell } from '../components/TicketCells';
import { useTranslation } from 'react-i18next';
import { TicketTypeCell } from '~/components/Cells/TicketTypeCell';
import type { PreOrderDetailsDto } from '~/lib/dtos/PreOrderDetailsDto';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';

const PreOrderTable = ({
  preOrderResponse,
}: {
  preOrderResponse: PreOrderDetailsDto;
}) => {
  const preOrder = preOrderResponse.detail.preOrder;
  const { t } = useTranslation();

  if (!preOrder) {
    return (
      <div className="overflow-x-none w-full">
        <Table className="border-spacing-0 w-full border border-separate border-gray-300 rounded-md shadow-md">
          <Table.Head className="text-center">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('common.loading')}
            </Table.Cell>
          </Table.Head>
        </Table>
      </div>
    );
  }
  return (
    <div className="overflow-x-none w-full">
      <Table className="border-spacing-0 w-full border border-separate border-gray-300 rounded-md shadow-md">
        <Table.Head className=" text-center">
          <Table.HeadCell>{t('tables.property')}</Table.HeadCell>
          <Table.HeadCell>{t('tables.value')}</Table.HeadCell>
        </Table.Head>
        <Table.Body className=" whitespace-nowrap">
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.created')}
            </Table.Cell>
            <Table.Cell className="px-4 text-center border border-gray-100">
              <DateBreakHandler date={preOrder.createdAt} />
            </Table.Cell>
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.phone-number-customer-profile')}
            </Table.Cell>
            <PhoneNumberCell
              className="px-4 text-center border border-gray-100"
              phoneNumber={preOrderResponse.detail.user.phoneNumber.value}
              customerId={preOrderResponse.detail.user.id.value}
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.operator')}
            </Table.Cell>
            <OperatorCell
              operator={
                preOrderResponse.detail.incomingMessages[0].provider.value
              }
              className="px-4 text-center border border-gray-100"
            />
          </Table.Row>
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <Table.Cell className="px-4 text-center border border-gray-100">
              {t('tables.ticket-type')}
            </Table.Cell>

            <TicketTypeCell
              className="px-4 text-center border border-gray-100"
              type={preOrder.ticketType}
              distance={preOrder.distance.value}
            />
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
export default PreOrderTable;
