import { useEffect, useMemo } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../../components/ErrorMessage';
import DatePicker from 'react-datepicker';
import { buildParams } from '~/lib/utils/buildParams/buildParams';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';
import { startOfDay, subDays } from 'date-fns';

const BasicSearch = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useFormContext<SearchSchemaType>();

  const navigate = useNavigate();

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: startOfDay(subDays(new Date(), 30)),
      endDate: new Date(),
    };
  }, []);

  useEffect(() => {
    reset((prev) => ({ ...prev, searchType: 'basic' }));
  }, [reset]);

  const { t } = useTranslation();

  const onSubmit: SubmitHandler<SearchSchemaType> = (data) => {
    navigate(
      `/orders?` +
        buildParams({
          ...data,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:grid-cols-2 grid gap-2 pb-2">
        <div>
          <label
            htmlFor="phoneNumber"
            className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900"
          >
            {t('tables.phone-number-customer-profile')}
          </label>
          <div className="flex my-auto align-middle">
            <div className="flex mx-1 my-auto text-sm text-gray-500 align-middle">
              +45
            </div>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => {
                return (
                  <input
                    type="tel"
                    id="phoneNumber"
                    className="bg-gray-50 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-gray-500 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="12345678"
                    maxLength={8}
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="ticketId"
            className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900"
          >
            {t('tables.ticket-id')}
          </label>
          <Controller
            control={control}
            name="ticketId"
            render={({ field }) => {
              return (
                <input
                  type="text"
                  id="ticketId"
                  className="bg-gray-50 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="1234 5678 9012 3"
                  {...field}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-4 pb-4">
        <div>
          <label
            htmlFor="phone"
            className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900"
          >
            {t('common.start-date')}
          </label>
          <DatePicker
            readOnly
            showTimeSelect
            dateFormat="d/M/yyyy, HH:mm"
            timeFormat="HH:mm"
            selected={startDate}
            onChange={() => {}}
            className="bg-gray-200"
            calendarStartDay={1}
          />
        </div>
        <div>
          <label
            htmlFor="phone"
            className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900"
          >
            {t('common.end-date')}
          </label>
          <DatePicker
            readOnly
            showTimeSelect
            dateFormat="d/M/yyyy, HH:mm"
            timeFormat="HH:mm"
            selected={endDate}
            onChange={() => {}}
            className="bg-gray-200"
            calendarStartDay={1}
          />
        </div>
      </div>

      <input type="submit" hidden />
      <div className="flex pb-4">
        <div className="">
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {t('common.submit')}
          </button>
        </div>
        <div className="ml-auto">
          <Link to="advanced_search">
            <button className="flex align-middle text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <div className="flex my-auto">{t('main.advanced-search')}</div>
              <div className="w-5 h-5 ml-2 text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="currentColor"
                    d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                  />
                </svg>
              </div>
            </button>
          </Link>
        </div>
      </div>
      {errors.phoneNumber?.message && (
        <ErrorMessage errorMessage={errors.phoneNumber.message} />
      )}
    </form>
  );
};

export default BasicSearch;
