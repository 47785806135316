import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import NotyfContext from '~/context/NotyfContext';
import { options } from '~/features/modal/ConfirmAlert';
import { useHasGroup } from '~/hooks/useHasGroup';
import type { UpdateType } from '../useUsers';

const DeleteUserCell = ({
  username,
  email,
  enabled,
  updateUser,
}: {
  username: string;
  email: string;
  enabled: boolean;
  updateUser: (username: string, updateType: UpdateType) => void;
}) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();

  const mutation = useMutation(
    async ({ username, email }: { username: string; email: string }) => {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await fetch(
        `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/users/delete`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username, email }),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return username;
    },
    {
      onSuccess: (username) => {
        updateUser(username, { type: 'delete' });
        notyf.success(t('users.successfully-removed-user') + ' ' + email);
      },
      onError: (error: any) => {
        notyf.error(t('users.couldnt-remove-user') + ' ' + email);
        if (error.response?.data?.errorType === 'USER_NOT_DEACTIVATED') {
          notyf.error(t('users.can-not-remove-user-if-not-deactivated'));
        } else if (error.response?.data?.errorType === 'AUDIT_LOG_NOT_EMPTY') {
          notyf.error(t('users.can-not-remove-user-if-has-user-history'));
        }
      },
    }
  );

  const isSuperuser = useHasGroup('Superuser');

  const setupAlert = (username: string, email: string) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title =
      t('users.are-you-sure-you-want-to-delete-user') + ' ' + email + '?';
    newOptions.buttons = [
      {
        className: '!bg-red-500',
        label: t('common.yes'),
        onClick: () => mutation.mutate({ username, email }),
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };

  return (
    <td className=" px-6 py-4">
      {enabled || !isSuperuser ? (
        <span className="text-gray-500 cursor-not-allowed">
          {t('tables.delete')}
        </span>
      ) : (
        <span
          className="hover:text-red-600 text-red-500 cursor-pointer"
          onClick={() => confirmAlert(setupAlert(username, email))}
        >
          {t('tables.delete')}
        </span>
      )}
    </td>
  );
};

export default DeleteUserCell;
