import z from 'zod';

export const InspectionStatusDtoSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  status: z.string(),
  ticketId: z.object({
    value: z.number(),
  }),
  createdAt: z.string(),
  modifiedAt: z.nullable(z.string()),
});

export type InspectionStatusDto = z.infer<typeof InspectionStatusDtoSchema>;
