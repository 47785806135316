import type { TUserGroup } from '~/features/maintenance/users/types';
import { useCurrentUserContext } from './useCurrentUser';
export const useHasGroup = (groupNames: TUserGroup | TUserGroup[]) => {
  const { currentUser } = useCurrentUserContext();

  if (!currentUser) {
    return false;
  }
  if (Array.isArray(groupNames)) {
    return groupNames.some((group) => currentUser.groups.includes(group));
  } else if (currentUser.groups.includes(groupNames)) {
    return true;
  } else {
    return false;
  }
};
