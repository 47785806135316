import { useTranslation } from 'react-i18next';
import type { ColumnNameDto } from '~/lib/dtos/common/ColumnNameDto';

export const useTranslateColumnNames = () => {
  const { t } = useTranslation();

  const columnNameMap: Record<ColumnNameDto, string> = {
    DETAILS: t('tables.details'),
    CREATED: t('tables.created'),
    OPERATOR: t('tables.operator'),
    PHONE_NUMBER: t('tables.phone-number-customer-profile'),
    DELIVERY_STATUS: t('tables.delivery-status'),
    EBK_RESPONSE: t('tables.ebk-response'),
    DSB_RESPONSE: t('tables.dsb-response'),
    VALID_FROM: t('tables.valid-from'),
    VALID_TO: t('tables.valid-to'),
    TYPE: t('tables.type'),
    REFUND: t('refund.refund'),
    PAYMENT_STATUS: t('tables.payment-status'),
    STATUS: t('tables.status'),
    INCOMING_MESSAGE: t('tables.incoming-message'),
    OUTGOING_MESSAGE: t('tables.outgoing-message'),
  };

  const translateColumnName = (columnName: ColumnNameDto) => {
    return columnNameMap[columnName] ?? columnName;
  };

  return { translateColumnName };
};
