import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import type { ProjectInfo } from '../types';
import { useFormContext } from 'react-hook-form';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';
import { defaultValues } from '~/hooks/useSearch';
import { subDays } from 'date-fns';
import { useCurrentUserContext } from '~/hooks/useCurrentUser';
import { Spinner } from 'flowbite-react';

const Navbar = ({ projectInfo }: { projectInfo: ProjectInfo | null }) => {
  const { reset } = useFormContext<SearchSchemaType>();

  const resetToDefaults = () => {
    reset({
      ...defaultValues,
      startDate: subDays(new Date(), 1).toISOString(),
      endDate: new Date().toISOString(),
    });
  };

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const [menuVisibility, setMenuVisibility] = useState(false);

  const handleMenuClick = () => {
    setMenuVisibility((menuVisibility) => !menuVisibility);
  };
  const { currentUser } = useCurrentUserContext();
  const hasPermissionsToViewAuditLog = useHasPermissions('VIEW_AUDIT_LOG');
  const hasPermissionsToViewUsers = useHasPermissions('VIEW_USERS');
  const hasPermissionsToViewDashboard = useHasPermissions('VIEW_DASHBOARD');

  const navbarLinkList = [
    {
      name: t('navbar.basic-search'),
      to: '/',
      isDisplayed: true,
    },
    {
      name: t('navbar.advanced-search'),
      to: '/advanced_search',
      isDisplayed: true,
    },
    {
      name: t('navbar.maintenance'),
      to: '/maintenance',
      isDisplayed:
        hasPermissionsToViewAuditLog ||
        hasPermissionsToViewUsers ||
        hasPermissionsToViewDashboard,
    },
    {
      name: t('navbar.bulletins'),
      to: '/bulletins',
      isDisplayed: true,
    },
    {
      name: t('navbar.instructions'),
      to: '/instructions',
      isDisplayed: true,
    },
    {
      name: t('navbar.profile'),
      to: '/profile',
      isDisplayed: true,
    },
  ];

  const navbarEntries = navbarLinkList.map((navLink, id) => {
    if (navLink.to === '/maintenance' && !currentUser) {
      return <Spinner key={id} className="fill-blue-600" />;
    }
    if (!navLink.isDisplayed) return null;
    if (navLink.to === pathname) {
      return (
        <li key={id} onClick={resetToDefaults}>
          <Link
            to={navLink.to}
            className={`lg:bg-transparent lg:text-blue-600 lg:p-0 dark:text-white block py-2 pl-3 pr-4 text-white bg-blue-700 rounded`}
            aria-current="page"
          >
            {navLink.name}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={id} onClick={resetToDefaults}>
          <Link
            to={navLink.to}
            className="hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent block py-2 pl-3 pr-4 text-gray-700 rounded"
          >
            {navLink.name}
          </Link>
        </li>
      );
    }
  });

  return (
    <nav className="bg-[#132184] border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-gray-900">
      <div
        className={
          'flex flex-wrap items-center justify-between mx-auto container'
        }
      >
        <Link to="/" className="flex items-center" onClick={resetToDefaults}>
          <img src="/assets/dot_logo_white.png" className="h-12" />
        </Link>
        <button
          onClick={handleMenuClick}
          data-collapse-toggle="navbar-default"
          type="button"
          className="lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={`lg:flex lg:gap-2 lg:w-auto ${
            menuVisibility ? 'visible' : 'hidden'
          } w-full`}
          id="navbar-default"
        >
          <ul className="bg-gray-50 lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium lg:border-0 lg:bg-white dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700 flex flex-col p-4 mt-4 border border-gray-100 rounded-lg">
            {navbarEntries}
          </ul>
          <ul className="bg-gray-50 lg:flex-row lg:mt-0 lg:text-sm lg:font-medium lg:border-0 lg:bg-white dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700 flex flex-col mt-4 align-middle border border-gray-100 rounded-lg">
            <li
              className={`bg-transparent text-center text-blue-600 lg:p-4 dark:text-white block py-2 pl-3 pr-4 bg-blue-700 rounded`}
              aria-current="page"
            >
              {projectInfo !== null
                ? projectInfo.env + ' system ' + projectInfo.ver
                : 'Loading'}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
