import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Button from '~/components/Button';
import NotyfContext from '~/context/NotyfContext';
import { options } from '~/features/modal/ConfirmAlert';
import { useHasGroup } from '~/hooks/useHasGroup';
import type { TUser } from '../types';
import type { UpdateType } from '../useUsers';

type MutationVariables = { username: string; newFullName: string };
type MutationContext = { previousFullName: string };

const ChangeUserFullNameCell = ({
  groups,
  username: initialUsername,
  email,
  updateUser,
  fullName,
}: {
  groups: TUser['Groups'];
  username: string;
  email?: string;
  updateUser: (username: string, updateType: UpdateType) => void;
  fullName: string;
}) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();

  const mutation = useMutation<
    MutationVariables,
    Error,
    MutationVariables,
    MutationContext
  >(
    async ({ username, newFullName }) => {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await fetch(
        `${
          import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
        }/users/change_fullname`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username, newFullName }),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return { username, newFullName };
    },
    {
      onMutate: async ({ username, newFullName }) => {
        const previousFullName = displayedFullName;
        setDisplayedFullName(newFullName);
        return { previousFullName };
      },
      onError: (error, variables, context) => {
        notyf.error(error.message);
        if (context) {
          setDisplayedFullName(context.previousFullName);
        }
      },
      onSuccess: ({ username, newFullName }) => {
        updateUser(username, { type: 'fullName', newFullName: newFullName });
        setEdit(false);
        notyf.success(t('users.successfully-changed-user-full-name'));
      },
    }
  );

  const group =
    groups.length > 0 &&
    groups.reduce(function (prev, current) {
      return prev.Precedence > current.Precedence ? prev : current;
    });
  const isSuperuser = useHasGroup('Superuser');
  const setupChangeFullNameAlert = (
    username: string,
    email: string | undefined,
    fullName: string,
    newFullName: string
  ) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title =
      t('users.are-you-sure-you-want-to-change-user') +
      ' ' +
      email +
      ' ' +
      t('users.full-name-from') +
      ' ' +
      fullName +
      ' ' +
      t('common.to') +
      ' ' +
      newFullName +
      '?';
    newOptions.buttons = [
      {
        className: '!bg-red-500',
        label: t('common.yes'),
        onClick: () => {
          mutation.mutate({ username: initialUsername, newFullName });
        },
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };
  const cannotEditSuperuser =
    group && group.GroupName === 'Superuser' && !isSuperuser;
  const [newFullName, setNewFullName] = useState<string>(fullName);
  const [displayedFullName, setDisplayedFullName] = useState<string>(fullName);
  const [edit, setEdit] = useState<boolean>(false);
  const submitNewFullName = () => {
    if (newFullName.trim() === '') {
      notyf.error(t('users.full-name-cannot-be-empty'));
      return;
    }
    confirmAlert(
      setupChangeFullNameAlert(
        initialUsername,
        email,
        displayedFullName,
        newFullName
      )
    );
  };
  if (cannotEditSuperuser) {
    return (
      <td className=" px-6 py-4">
        <span className="my-auto cursor-not-allowed">
          {newFullName ? newFullName : fullName}
        </span>
      </td>
    );
  }
  if (edit) {
    return (
      <td className="flex items-center px-6 py-6 my-auto align-middle">
        <input
          className=" outline-gray-300 mr-2 border-gray-300"
          type="text"
          value={newFullName}
          onChange={(e) => setNewFullName(e.target.value)}
          id=""
          onKeyPress={(e) => (e.key === 'Enter' ? submitNewFullName : null)}
        />
        <Button onClick={submitNewFullName} type="simple">
          {t('common.submit')}
        </Button>
        <Button
          type="simple"
          onClick={() => setEdit(false)}
          className="px-2 py-1 my-auto ml-2"
        >
          x
        </Button>
      </td>
    );
  }
  return (
    <td className=" px-6 py-4">
      <span className="my-auto cursor-pointer" onClick={() => setEdit(true)}>
        {displayedFullName ? displayedFullName : fullName}
      </span>
    </td>
  );
};
export default ChangeUserFullNameCell;
