import type { TicketTypeDto } from '../../dtos/common/TicketTypeDto';

type SchemaTicketTypes =
  | 'ADULT'
  | 'CHILD'
  | 'SUPPLEMENTARY'
  | 'BIKE'
  | 'DOG'
  | 'DSB1';

type BikePossession = 'HAS_BIKE' | 'WITHOUT_BIKE';

export const convertTicketTypes = ({
  ticketTypes = [],
  bikePossession = [],
  zones = [],
}: {
  ticketTypes?: SchemaTicketTypes[];
  bikePossession?: BikePossession[];
  zones?: string[];
}): TicketTypeDto[] => {
  const backendTicketTypes: TicketTypeDto[] = [];

  for (const ticketType of ticketTypes ?? []) {
    switch (ticketType) {
      case 'ADULT':
        if (
          bikePossession?.includes('HAS_BIKE') &&
          bikePossession?.includes('WITHOUT_BIKE')
        ) {
          backendTicketTypes.push('ADULT_WITH_BIKE', 'ADULT');
        }

        if (bikePossession?.includes('HAS_BIKE')) {
          backendTicketTypes.push('ADULT_WITH_BIKE');
        }

        if (bikePossession?.includes('WITHOUT_BIKE')) {
          backendTicketTypes.push('ADULT');
        }

        if (ticketTypes?.includes('SUPPLEMENTARY') && zones?.length !== 0) {
          backendTicketTypes.push('SUPPLEMENTARY_ADULT', 'ADULT');
        }

        if (ticketTypes?.includes('SUPPLEMENTARY') && zones?.length === 0) {
          backendTicketTypes.push('SUPPLEMENTARY_ADULT');
        }

        break;

      case 'CHILD':
        if (
          bikePossession?.includes('HAS_BIKE') &&
          bikePossession?.includes('WITHOUT_BIKE')
        ) {
          backendTicketTypes.push('CHILD_WITH_BIKE', 'CHILD');
        }

        if (bikePossession?.includes('HAS_BIKE')) {
          backendTicketTypes.push('CHILD_WITH_BIKE');
        }

        if (bikePossession?.includes('WITHOUT_BIKE')) {
          backendTicketTypes.push('CHILD');
        }

        if (ticketTypes?.includes('SUPPLEMENTARY') && zones?.length !== 0) {
          backendTicketTypes.push('SUPPLEMENTARY_CHILD', 'CHILD');
        }

        if (ticketTypes?.includes('SUPPLEMENTARY') && zones?.length === 0) {
          backendTicketTypes.push('SUPPLEMENTARY_CHILD');
        }

        break;

      case 'SUPPLEMENTARY':
        break;

      case 'DOG':
        backendTicketTypes.push('DOG');
        break;

      case 'BIKE':
        backendTicketTypes.push('BIKE');
        break;

      case 'DSB1':
        backendTicketTypes.push('DSB1');
        break;

      default:
        throw new Error(`Unknown ticket type: ${ticketType}`);
    }
  }

  return [...new Set(backendTicketTypes)];
};
