import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { fetchResourceWithToken } from '~/features/search/utils';

type TFileType = 'other-message' | 'preorder' | 'order' | 'ticket';

interface DownloadHookProps {
  id?: number;
  createdAt?: string;
  fileType: TFileType;
  mCode?: string;
}
interface DownloadMutationProps {
  id: number;
  createdAt: string;
  fileType: TFileType;
  mCode?: string;
}
const getFileName = (
  fileType: TFileType,
  createdAt: string,
  mCode?: string
) => {
  const date = dayjs(createdAt);
  const formattedDate = date.format('DD.MMM.YYYY HH.mm.ss').toLowerCase();
  if (fileType === 'ticket' && mCode) {
    return `${formattedDate} Besked ${mCode} detaljer.xlsx`;
  }
  return `${formattedDate} Besked detaljer.xlsx`;
};

const downloadSearchResultMutation = async ({
  id,
  createdAt,
  fileType,
  mCode,
}: DownloadMutationProps) => {
  const response = await fetchResourceWithToken(getDownloadUrl(fileType, id));

  if (!response.ok) {
    throw new Error('Failed to download file');
  }

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', getFileName(fileType, createdAt, mCode));
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

const getDownloadUrl = (fileType: TFileType, id: number) => {
  const fileTypeMapping: { [key in TFileType]?: string } = {
    'other-message': 'incoming-messages',
  };

  const endpoint = fileTypeMapping[fileType] || fileType;

  const url = `${
    import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
  }/${endpoint}/${id}/file`;

  return url;
};

export const useDownloadSearchResults = ({
  id,
  createdAt,
  fileType,
  mCode,
}: DownloadHookProps) => {
  const downloadSearchResult = useMutation(downloadSearchResultMutation);

  const download = () => {
    if (id && createdAt) {
      downloadSearchResult.mutate({ id, createdAt, fileType, mCode });
    }
  };

  return {
    download,
  };
};
