import z from 'zod';

export const TicketPaymentStatusDtoSchema = z.union([
  z.literal('PAYMENT_PROCESSING'),
  z.literal('PAYMENT_REJECTED'),
  z.literal('PAYMENT_COMPLETED'),
  z.literal('PAYMENT_REFUNDED'),
]);

export type TicketPaymentStatusDto = z.infer<
  typeof TicketPaymentStatusDtoSchema
>;
