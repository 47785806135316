import z from 'zod';
import { PreOrderDtoSchema } from './common/PreOrderDto';
import { IncomingMessageDtoSchema } from './common/IncomingMessageDto';
import { OutgoingMessageDtoSchema } from './common/OutgoingMessageDto';
import { DeliveryNotificationDtoSchema } from './common/DeliveryNotificationDto';
import { BasicUserSchema } from './BasicUserDto';

export const PreOrderDetailsDtoSchema = z.object({
  detail: z.object({
    user: BasicUserSchema,
    preOrder: PreOrderDtoSchema,
    incomingMessages: z.array(IncomingMessageDtoSchema),
    outgoingMessages: z.array(OutgoingMessageDtoSchema),
    deliveryNotifications: z.array(DeliveryNotificationDtoSchema),
  }),
});

export type PreOrderDetailsDto = z.infer<typeof PreOrderDetailsDtoSchema>;
