import React from 'react';
import { Checkbox } from '~/components/Checkbox';
import type { ColumnsVisibilityDto } from '~/lib/dtos/common/ColumnsVisibilityDto';
import type { ColumnNameDto } from '~/lib/dtos/common/ColumnNameDto';

interface ColumnVisibilityControlProps {
  columnVisibility: ColumnsVisibilityDto;
  handleColumnVisibilityChange: (
    column: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  translateColumnName: (columnName: ColumnNameDto) => string;
  prefix: string;
  excludedColumns?: string[];
}

const ColumnVisibilityControl: React.FC<ColumnVisibilityControlProps> = ({
  columnVisibility,
  handleColumnVisibilityChange,
  translateColumnName,
  prefix,
  excludedColumns = [],
}) => {
  const filteredColumns = Object.entries(columnVisibility).filter(
    ([column]) => !excludedColumns.includes(column)
  );

  const midpoint = Math.ceil(filteredColumns.length / 2);

  return (
    <>
      <div className="flex justify-end">
        {filteredColumns.slice(0, midpoint).map(([column, isVisible]) => (
          <Checkbox
            key={`${prefix}_${column}`}
            checked={isVisible}
            onChange={handleColumnVisibilityChange(column)}
            value={`${prefix}_${column}`}
            label={translateColumnName(column as ColumnNameDto)}
          />
        ))}
      </div>

      <div className="flex justify-end">
        {filteredColumns.slice(midpoint).map(([column, isVisible]) => (
          <Checkbox
            key={`${prefix}_${column}`}
            checked={isVisible}
            onChange={handleColumnVisibilityChange(column)}
            value={`${prefix}_${column}`}
            label={translateColumnName(column as ColumnNameDto)}
          />
        ))}
      </div>
    </>
  );
};

export default ColumnVisibilityControl;
