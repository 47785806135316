import { useNavigate, useParams } from 'react-router';
import Button from '../../../components/Button';

import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import MessagesTable from '../messages/MessagesTable';
import { BarLoader } from 'react-spinners';
import { useBlockadeMessageDetailsQuery } from '~/lib/hooks/useBlockadeMessageDetailsQuery';
import type { BlockadeMessageDetailsDto } from '~/lib/dtos/BlockadeMessageDetailsDto';
import BlockadeMessageTable from './BlockadeMessageTable';

const BlockadeMessageDetails = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: blockageMessage,
    isFetching,
    isError,
    refetch,
  } = useBlockadeMessageDetailsQuery(params.id ?? '', {
    enabled: Boolean(params.id),
  });

  const { t } = useTranslation();

  return (
    <div className="">
      <div className=" flex justify-between">
        <Button className="w-auto" type="gray" onClick={() => navigate(-1)}>
          {t('common.back')}
        </Button>
        <div className="flex gap-2">
          <Button className="w-auto" type="gray" onClick={() => refetch()}>
            {t('common.refresh')}
          </Button>
        </div>
      </div>
      <div className="py-4 text-xl font-semibold">
        {`${t('search.message-details')} (Id: ${String(params.id)})`}
      </div>
      <BlockadeMessageDetailsContent
        data={blockageMessage}
        isFetching={isFetching}
        isError={isError}
      />
    </div>
  );
};

const BlockadeMessageDetailsContent = ({
  data,
  isFetching,
  isError,
}: {
  data?: BlockadeMessageDetailsDto;
  isFetching: boolean;
  isError: boolean;
}) => {
  const { t } = useTranslation();

  // const { download } = useDownloadSearchResults({
  //   id: data?.blockadeMessageDetails..id.value,
  //   createdAt: data?.blockadeMessageDetails.preOrder.createdAt,
  //   fileType: 'preorder',
  // });

  // const downloadVisible = useHasPermissions('DOWNLOAD_DATA');

  if (isFetching) {
    return (
      <div className="flex justify-center">
        <BarLoader color="#7f7f7f" />
      </div>
    );
  }

  if (isError || !data) {
    return <Header>{t('status.error')}</Header>;
  }

  return (
    <div className="flex w-full px-4 pb-4 overflow-x-auto">
      <div className="flex flex-col">
        <BlockadeMessageTable
          blockadeMessageDetails={data.blockadeMessageDetails}
        />
      </div>
      <div className="flex flex-col w-full ml-8">
        <div className="flex justify-between pt-4 pb-2">
          <div className="mt-auto text-xl font-semibold text-left">
            {t('tables.messages')}
          </div>
          <div className=" mt-auto">
            {/* <Button onClick={download} isVisible={downloadVisible}>
              {t('common.download-messages')}
            </Button> */}
          </div>
        </div>
        <MessagesTable
          deliveryNotifications={
            data.blockadeMessageDetails.deliveryNotifications
          }
          incomingMessages={[]}
          outgoingMessages={data.blockadeMessageDetails.outgoingMessages}
        />
      </div>
    </div>
  );
};
export default BlockadeMessageDetails;
