import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import Header from '../../components/Header';
import { Container } from '../../components/Layout';

import InstructionsTable from './InstructionsTable';

const Instructions: React.FC = () => {
  const { t } = useTranslation();
  const hasManageBulletinsPermission = useHasPermissions('UPLOAD_INSTRUCTIONS');
  return (
    <div>
      <Header className="pb-2">{t('instructions.instructions')}</Header>

      <Container>
        {hasManageBulletinsPermission ? (
          <Link to="publish">
            <button className="my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              {t('instructions.publish') + ' ' + t('instructions.instruction')}
            </button>
          </Link>
        ) : null}

        <InstructionsTable edit={hasManageBulletinsPermission} />
      </Container>
    </div>
  );
};
export default Instructions;
