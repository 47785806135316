import type { TicketTypes } from '../searchSchema';

export const hasAtLeastOneZoneOrSpecialType = ({
  zones,
  ticketTypes,
}: {
  zones: string[];
  ticketTypes: TicketTypes;
}): boolean => {
  const specialTypes: TicketTypes = ['BIKE', 'SUPPLEMENTARY', 'DSB1'];

  return (
    zones.length > 0 ||
    ticketTypes.some((ticketType) => specialTypes.includes(ticketType))
  );
};
