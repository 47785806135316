import z from 'zod';

export const TicketNoteTypeDtoSchema = z.union([
  z.literal('STANDALONE'),
  z.literal('REFUND'),
  z.literal('RESEND'),
  z.literal('SEND_RECEIPT'),
]);

export type TicketNoteTypeDto = z.infer<typeof TicketNoteTypeDtoSchema>;
