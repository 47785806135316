import type { UseQueryOptions } from 'react-query';
import type { UserDto } from '../dtos/UserDto';
import { UserDtoSchema } from '../dtos/UserDto';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';

export const useUserQuery = (
  id: string,
  options?: UseQueryOptions<UserDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: UserDtoSchema,
      url: `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/user/${id}`,
    },
    { ...options, queryKey: ['user', id] }
  );
};
