import { z } from 'zod';
import { DeliveryNotificationDtoSchema } from './common/DeliveryNotificationDto';
import { BasicUserSchema } from './BasicUserDto';
import { TicketTypeDtoSchema } from './common/TicketTypeDto';
import { OrderStatusDtoSchema } from './common/OrderStatusDto';
import { OperatorsSchema } from './common/OperatorsDTO';

export const OrderSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  userId: z.object({
    value: z.number(),
  }),
  ticketType: TicketTypeDtoSchema,
  status: OrderStatusDtoSchema,
  startingZoneId: z.array(
    z.object({
      value: z.number(),
    })
  ),
  distance: z.object({
    value: z.number(),
  }),
  price: z.object({
    amount: z.number(),
    vat: z.number(),
  }),
  processId: z.object({
    value: z.string(),
  }),
  createdAt: z.string(),
  modifiedAt: z.string(),
  forBike: z.boolean(),
  operator: OperatorsSchema,
});

export const OrderUserDtoSchema = z.object({
  order: OrderSchema,
  user: BasicUserSchema,
  deliveryNotification: DeliveryNotificationDtoSchema.nullable(),
});

export const OrdersDtoSchema = z.object({
  orderUserList: z.array(OrderUserDtoSchema),
  availablePages: z.number(),
  count: z.number(),
});
export type OrderUserDto = z.infer<typeof OrderUserDtoSchema>;
export type OrdersDto = z.infer<typeof OrdersDtoSchema>;
