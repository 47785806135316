import { useTranslation } from 'react-i18next';
import type { Nullable } from '~/features/search/types';
import { Cell } from './Cell';
import { getFormattedDelay } from '~/features/search/utils';

type Props = { status: Nullable<string>; delay: number };

export const EbkDsbStatusCell = ({ status, delay }: Props) => {
  const { color, name } = useEbkDsbStatusMap(status);
  const { t } = useTranslation();
  return (
    <Cell
      content={
        <div className="flex flex-col items-center justify-center gap-1">
          <div>{name ?? '-'}</div>
          {delay >= 15 && (
            <div>{t('status.delay') + ': ' + getFormattedDelay(delay)}</div>
          )}
        </div>
      }
      color={color}
    />
  );
};

const useEbkDsbStatusMap = (status: Nullable<string>) => {
  const { t } = useTranslation();

  if (!status) {
    return { color: null, name: null };
  }

  const [code] = status.split(' ');

  if (['200', '201'].includes(code)) {
    return {
      color: 'bg-green-300',
      name: t('status.registered'),
    };
  }

  if (['500', '502', '503'].includes(code)) {
    return {
      color: 'bg-red-400',
      name: t('status.error'),
    };
  }

  return {
    color: 'bg-yellow-300',
    name: status,
  };
};
