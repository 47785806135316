import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import NotyfContext from '~/context/NotyfContext';
import { options } from '~/features/modal/ConfirmAlert';

const deleteBulletinMutation = async (bulletinId: number) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/bulletins/${bulletinId}`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

export const useDeleteBulletinMutation = () => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const deleteBulletin = useMutation(deleteBulletinMutation, {
    onSuccess: () => {
      notyf.success(t('bulletins.successfully-created-bulletin'));
      queryClient.invalidateQueries({ queryKey: ['bulletins'] });
    },
    onError: (e: { message: string }) => {
      notyf.error(e.message);
    },
  });
  const setupDeleteBulletinAlert = (bulletinId: number, subject: string) => {
    const options = {
      title:
        t('bulletins.are-you-sure-you-want-to-delete-bulletin') +
        ' ' +
        subject +
        '?',
      buttons: [
        {
          className: '!bg-red-500',
          label: t('common.yes'),
          onClick: () => deleteBulletin.mutate(bulletinId),
        },
        {
          label: t('common.no'),
          onClick: () => {},
        },
      ],
    };

    return () => confirmAlert(options);
  };

  return { deleteBulletin, setupDeleteBulletinAlert };
};
