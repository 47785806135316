import React, { useContext, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useHasGroup } from '~/hooks/useHasGroup';
import NotyfContext from '~/context/NotyfContext';
import { options } from '~/features/modal/ConfirmAlert';
import type { TUser, TUserGroup } from './types';
import { Auth } from 'aws-amplify';

export const GroupSelect = ({
  username,
  propsGroups,
}: {
  username: string;
  propsGroups: TUser['Groups'];
}) => {
  const [selectedGroup, setSelectedGroup] = useState<TUserGroup | null>(null);

  const changeGroup = async ({
    username,
    prevGroup,
    newGroup,
  }: {
    username: string;
    prevGroup: TUserGroup;
    newGroup: TUserGroup;
  }) => {
    const session = await Auth.currentSession();

    const jwtToken = session.getIdToken().getJwtToken();

    return fetch(
      `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/users/change_userlevel`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          username,
          prevGroup,
          newGroup,
        }),
      }
    ).then((res) => {
      if (res.ok) {
        notyf.success(t('users.change-groups-success'));
        setSelectedGroup(newGroup);
        return;
      }

      notyf.error(t('users.problem-occured'));
      throw new Error();
    });
  };

  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);

  const setupAlert = ({
    username,
    prevGroup,
    newGroup,
  }: {
    username: string;
    prevGroup: TUserGroup;
    newGroup: TUserGroup;
  }) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title =
      t('users.are-you-sure') +
      ' ' +
      username +
      ' ' +
      t('users.role-from') +
      ' ' +
      prevGroup +
      ' ' +
      t('common.to') +
      ' ' +
      newGroup +
      '?';
    newOptions.buttons = [
      {
        className: '!bg-red-500',
        label: t('common.yes'),
        onClick: () => changeGroup({ username, prevGroup, newGroup }),
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };

  useEffect(() => {
    if (propsGroups.length > 0) {
      const group = propsGroups.reduce(function (prev, current) {
        return prev.Precedence > current.Precedence ? prev : current;
      });
      setSelectedGroup(group.GroupName);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newGroup = e.target.value as TUserGroup;
    const prevGroup = selectedGroup;
    if (!newGroup || !prevGroup) return;
    confirmAlert(setupAlert({ username, newGroup, prevGroup }));
  };

  const isSuperuser = useHasGroup('Superuser');

  const isAdministrator = useHasGroup('Administrator');

  if (isSuperuser) {
    return (
      <select
        value={selectedGroup ?? ''}
        onChange={handleChange}
        className="py-1 mr-1"
      >
        <option value={'Superuser'}>{t('users.super-user')}</option>
        <option value={'Administrator'}>{t('users.administrator')}</option>
        <option value={'TeamLeader'}>{t('users.team-leader')}</option>
        <option value={'FraudManager'}>{t('users.fraud-manager')}</option>
        <option value={'BackofficeEmployee'}>
          {t('users.backoffice-employee')}
        </option>
        <option value={'CustomerServiceRW'}>
          {t('users.customer-fervice-rw')}
        </option>
        <option value={'CustomerServiceR'}>
          {t('users.customer-service-r')}
        </option>
        {selectedGroup === 'ProductOwner' && (
          <option value={'ProductOwner'}>{t('users.product-owner')}</option>
        )}
      </select>
    );
  }

  if (isAdministrator && selectedGroup === 'Superuser') {
    return <div>{t('users.super-user')}</div>;
  }

  if (isAdministrator) {
    return (
      <select
        value={selectedGroup ?? ''}
        onChange={handleChange}
        className="py-1 mr-1"
      >
        <option value={'Administrator'}>{t('users.administrator')}</option>
        <option value={'TeamLeader'}>{t('users.team-leader')}</option>
        <option value={'FraudManager'}>{t('users.fraud-manager')}</option>
        <option value={'BackofficeEmployee'}>
          {t('users.backoffice-employee')}
        </option>
        <option value={'CustomerServiceRW'}>
          {t('users.customer-fervice-rw')}
        </option>
        <option value={'CustomerServiceR'}>
          {t('users.customer-service-r')}
        </option>
        {selectedGroup === 'ProductOwner' && (
          <option value={'ProductOwner'}>{t('users.product-owner')}</option>
        )}
      </select>
    );
  }
  return <div>{selectedGroup}</div>;
};

export const GroupSelectControlled = ({
  username,
  propsGroups,
  selectedGroup,
  setSelectedGroup,
}: {
  username: string;
  propsGroups: TUser['Groups'];
  selectedGroup: TUserGroup | null;
  setSelectedGroup: React.Dispatch<React.SetStateAction<TUserGroup | null>>;
}) => {
  const changeGroup = async ({
    username,
    prevGroup,
    newGroup,
  }: {
    username: string;
    prevGroup: TUserGroup;
    newGroup: TUserGroup;
  }) => {
    const session = await Auth.currentSession();

    const jwtToken = session.getIdToken().getJwtToken();

    return fetch(
      `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/users/change_userlevel`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          username,
          prevGroup,
          newGroup,
        }),
      }
    ).then((res) => {
      if (res.ok) {
        notyf.success(t('users.change-groups-success'));
        setSelectedGroup(newGroup);
        return;
      }

      notyf.error(t('users.problem-occured'));
      throw new Error();
    });
  };

  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);

  const setupAlert = ({
    username,
    prevGroup,
    newGroup,
  }: {
    username: string;
    prevGroup: TUserGroup;
    newGroup: TUserGroup;
  }) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title =
      t('users.are-you-sure') +
      ' ' +
      username +
      ' ' +
      t('users.role-from') +
      ' ' +
      prevGroup +
      ' ' +
      t('common.to') +
      ' ' +
      newGroup +
      '?';
    newOptions.buttons = [
      {
        className: '!bg-red-500',
        label: t('common.yes'),
        onClick: () => changeGroup({ username, prevGroup, newGroup }),
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };

  useEffect(() => {
    if (propsGroups.length > 0) {
      const group = propsGroups.reduce(function (prev, current) {
        return prev.Precedence > current.Precedence ? prev : current;
      });
      setSelectedGroup(group.GroupName);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newGroup = e.target.value as TUserGroup;
    const prevGroup = selectedGroup;
    if (!newGroup || !prevGroup) return;

    confirmAlert(setupAlert({ username, newGroup, prevGroup }));
  };

  const isSuperuser = useHasGroup('Superuser');

  const isAdministrator = useHasGroup('Administrator');

  if (isSuperuser) {
    return (
      <select
        value={selectedGroup ?? ''}
        onChange={handleChange}
        className="py-1 mr-1"
      >
        <option value={'Superuser'}>{t('users.super-user')}</option>
        <option value={'TeamLeader'}>{t('users.team-leader')}</option>
        <option value={'Administrator'}>{t('users.administrator')}</option>
        <option value={'FraudManager'}>{t('users.fraud-manager')}</option>
        <option value={'BackofficeEmployee'}>
          {t('users.backoffice-employee')}
        </option>
        <option value={'CustomerServiceRW'}>
          {t('users.customer-fervice-rw')}
        </option>
        <option value={'CustomerServiceR'}>
          {t('users.customer-service-r')}
        </option>
        {selectedGroup === 'ProductOwner' && (
          <option value={'ProductOwner'}>{t('users.product-owner')}</option>
        )}
      </select>
    );
  }

  if (isAdministrator && selectedGroup === 'Superuser') {
    return <div>{t('users.super-user')}</div>;
  }
  if (isAdministrator && selectedGroup === 'ProductOwner') {
    return <div>{t('users.product-owner')}</div>;
  }
  if (isAdministrator) {
    return (
      <select
        value={selectedGroup ?? ''}
        onChange={handleChange}
        className="py-1 mr-1"
      >
        <option value={'Administrator'}>{t('users.administrator')}</option>
        <option value={'TeamLeader'}>{t('users.team-leader')}</option>
        <option value={'FraudManager'}>{t('users.fraud-manager')}</option>
        <option value={'BackofficeEmployee'}>
          {t('users.backoffice-employee')}
        </option>
        <option value={'CustomerServiceRW'}>
          {t('users.customer-fervice-rw')}
        </option>
        <option value={'CustomerServiceR'}>
          {t('users.customer-service-r')}
        </option>
      </select>
    );
  }
  return <div>{selectedGroup}</div>;
};
