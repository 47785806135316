import { useLocation } from 'react-router';
import { decodeParams } from '~/lib/utils/decodeParams/decodeParams';
import { format, parse, startOfDay, addDays } from 'date-fns';

type CustomerResultsParams = {
  userId?: string;
  startDate?: string;
  endDate?: string;
  limit?: string;
  page?: string;
};

type InitialCustomerResultsParams = {
  userId: string;
  startDate: Date;
  endDate: Date;
  limit: string;
  page: string;
};

export const useDecodeCustomerResultsData = () => {
  const { search } = useLocation();

  const decodedParams = decodeParams<Partial<CustomerResultsParams>>(
    search.replaceAll('?', '')
  );

  const initialPage = Number(decodedParams.page ?? 1);

  const initialStartDate = decodedParams.startDate
    ? new Date(decodedParams.startDate)
    : addDays(new Date(), -30);

  const initialEndDate = decodedParams.endDate
    ? new Date(decodedParams.endDate)
    : new Date();

  const initialUserId = decodedParams.userId ?? 'defaultUserId';

  const initialLimit = decodedParams.limit ?? '10';

  const finalParams: InitialCustomerResultsParams = {
    userId: initialUserId,
    startDate: initialStartDate,
    endDate: initialEndDate,
    page: initialPage.toString(),
    limit: initialLimit,
  };

  return [finalParams, decodedParams] as const;
};
