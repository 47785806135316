import { useState } from 'react';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import { useDashboardOperatorQuery } from '~/lib/hooks/useDashboardOperatorQuery';
import { useDashboardQuery } from '~/lib/hooks/useDashboardQuery';

export const useDashboard = () => {
  const hasViewAuditLogPermissions = useHasPermissions('VIEW_DASHBOARD');

  const [refresh, setRefresh] = useState<string | null>(null);

  const { data, isLoading, error, isFetching } = useDashboardQuery(refresh, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: hasViewAuditLogPermissions && refresh !== null,
  });

  const hi3gData = useDashboardOperatorQuery(refresh, 'hi3g', {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: hasViewAuditLogPermissions && refresh !== null,
  });

  const sonofonData = useDashboardOperatorQuery(refresh, 'sonofon', {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: hasViewAuditLogPermissions && refresh !== null,
  });

  const tdcData = useDashboardOperatorQuery(refresh, 'tdc', {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: hasViewAuditLogPermissions && refresh !== null,
  });

  const teliaData = useDashboardOperatorQuery(refresh, 'telia', {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: hasViewAuditLogPermissions && refresh !== null,
  });

  return {
    operatorsData: [
      hi3gData.data?.dashboard.operatorData,
      sonofonData.data?.dashboard.operatorData,
      tdcData.data?.dashboard.operatorData,
      teliaData.data?.dashboard.operatorData,
    ],
    operatorsLoading:
      hi3gData.isLoading ||
      sonofonData.isLoading ||
      tdcData.isLoading ||
      teliaData.isLoading,

    operatorsError:
      hi3gData.error || sonofonData.error || tdcData.error || teliaData.error,
    data,
    isLoading,
    isFetching,
    error,
    searchCriteria: {
      setRefresh,
      refresh,
    },
  };
};
