import { useLocation } from 'react-router';
import { decodeParams } from '~/lib/utils/decodeParams/decodeParams';
import { convertTicketTypes } from '~/lib/utils/convertTicketTypes/convertTicketTypes';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';

export const useDecodeSearchParams = () => {
  const { search } = useLocation();

  const decodedParams = decodeParams<Partial<SearchSchemaType>>(
    search.replaceAll('?', ''),
    ['operators', 'ticketTypes', 'zones', 'bikePossession']
  );

  const convertedTicketTypes = convertTicketTypes({
    bikePossession: decodedParams.bikePossession,
    ticketTypes: decodedParams.ticketTypes,
    zones: decodedParams.zones,
  });

  return [
    { ...decodedParams, ticketTypes: convertedTicketTypes },
    decodedParams,
  ] as const;
};
