export const decodeParams = <T extends Record<string, string | string[]>>(
  queryString: string,
  ensureArray?: string[]
): T => {
  const sanitizedQueryString = queryString.replaceAll('?', '');

  const decodePair = (key: string, val: string) => ({
    key: decodeURIComponent(key),
    val: decodeURIComponent(val),
  });

  const query: Record<string, string | string[]> = {};

  for (const pair of sanitizedQueryString.split('&')) {
    const [key, val] = pair.split('=');

    if (!key || !val) {
      continue;
    }

    const { key: decodedKey, val: decodedVal } = decodePair(key, val);

    if (!query[decodedKey]) {
      query[decodedKey] = decodedVal;
      continue;
    }

    if (Array.isArray(query[decodedKey])) {
      (query[decodedKey] as string[]).push(decodedVal);
      continue;
    }

    query[decodedKey] = [query[decodedKey] as string, decodedVal];
  }

  if (ensureArray) {
    for (const key of ensureArray) {
      if (query[key] && !Array.isArray(query[key])) {
        query[key] = [query[key] as string];
      }
    }
  }

  //would be nice to remove type assertion at some point
  return query as T;
};
