import type { UseQueryOptions } from 'react-query';
import { clearMcode } from '~/features/search/utils';
import type { TicketDetailsDto } from '../dtos/TicketDto';
import { TicketDetailsDtoSchema } from '../dtos/TicketDto';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';

export const useTicketQuery = (
  {
    ticketId,
    mCode,
    paymentId,
  }: { ticketId?: string; mCode?: string; paymentId?: string },
  options?: UseQueryOptions<TicketDetailsDto>
) => {
  const getUrl = ({
    mCode,
    paymentId,
    ticketId,
  }: {
    mCode?: string;
    paymentId?: string;
    ticketId?: string;
  }) => {
    if (mCode && mCode.length > 0) {
      return `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/ticket/mcode/${clearMcode(mCode)}`;
    }
    if (paymentId && paymentId.length > 0) {
      return `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/ticket/payment/${paymentId}`;
    }

    return `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/ticket/${
      ticketId ?? ''
    }`;
  };

  return useAuthenticatedQuery(
    {
      schema: TicketDetailsDtoSchema,
      url: getUrl({ ticketId, mCode, paymentId }),
    },
    {
      ...options,
      queryKey: ['ticket', ticketId],
      enabled: !!mCode || !!paymentId || !!ticketId,
    }
  );
};
