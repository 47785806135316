import z from 'zod';
import { OperatorsSchema } from './common/OperatorsDTO';

export const IncomingMessagesDtoSchema = z.object({
  incomingMessages: z.array(
    z.object({
      createdAt: z.string().nullable(),
      id: z.object({
        value: z.number(),
      }),
      message: z.string(),
      phoneNumber: z.object({
        value: z.string(),
      }),
      responseDeliveredAt: z.string().nullable(),
      userId: z.object({
        value: z.number(),
      }),
      deliveryNotificationStatus: z.string().nullable(),
      operator: OperatorsSchema,
    })
  ),
});

export type IncomingMessagesDto = z.infer<typeof IncomingMessagesDtoSchema>;
