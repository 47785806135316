import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { formatDate } from '~/lib/utils/formatDates/formatDates';
import type { useDashboard } from './useDashBoard';

type TableEbkDsbProps = Pick<
  ReturnType<typeof useDashboard>,
  'isLoading' | 'error' | 'data'
>;

const TableEbkDsb = ({ data, isLoading, error }: TableEbkDsbProps) => {
  const { t } = useTranslation();

  if (error) {
    return (
      <Table className="shadow-lg">
        <Table.Head className="text-center">
          <Table.HeadCell>{JSON.stringify(error)}</Table.HeadCell>
        </Table.Head>
      </Table>
    );
  }

  if (isLoading) {
    return (
      <Table className="shadow-lg">
        <Table.Head className="text-center">
          <Table.HeadCell>
            <ClipLoader />
          </Table.HeadCell>
        </Table.Head>
      </Table>
    );
  }

  if (!data) {
    return (
      <Table className="shadow-lg">
        <Table.Head className="text-center">
          <Table.HeadCell>No data</Table.HeadCell>
        </Table.Head>
      </Table>
    );
  }

  const {
    isEbkEnabled,
    latestSuccessfulSendToTicketInspection,
    numberOfSuccessfulRegistrationToTicketInspection,
    percentOfSuccessfulRegistrationInTicketInspection,
    isDsbEnabled,
    latestSuccessfulSendToFinancialAuthority,
    numberOfSuccessfulRegistrationToFinancialAuthority,
    percentOfSuccessfulRegistrationInFinancialAuthority,
    numberOfTicketsSentWithinLastHour,
  } = data.dashboard;

  return (
    <Table className="border-spacing-0 mb-4 overflow-x-auto border border-separate border-black rounded-md shadow-lg">
      <Table.Head className="bg-gray-100">
        <Table.HeadCell className=" border-b border-r border-black" colSpan={2}>
          <div className="flex justify-center my-auto text-center align-middle">
            Reporting
          </div>
        </Table.HeadCell>
        <Table.HeadCell colSpan={1} className=" border-b border-r border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Latest
          </div>
        </Table.HeadCell>
        <Table.HeadCell colSpan={3} className=" border-b border-black">
          <div className="flex justify-center my-auto text-center align-middle">
            Within last hour
          </div>
        </Table.HeadCell>
      </Table.Head>
      <Table.Head className=" bg-gray-100">
        <Table.HeadCell className="text-center border-b border-black">
          Ebk / Dsb
        </Table.HeadCell>
        <Table.HeadCell className="text-center border-b border-r border-black">
          Enabled
        </Table.HeadCell>

        <Table.HeadCell className="text-center border-b border-r border-black">
          Report write success
        </Table.HeadCell>
        <Table.HeadCell className="text-center border-b border-black">
          Nr of sent tickets
        </Table.HeadCell>
        <Table.HeadCell className="text-center border-b border-black">
          Nr of write success
        </Table.HeadCell>
        <Table.HeadCell className="text-center border-b border-black">
          Success %
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="text-gray-800 divide-y">
        <Table.Row>
          <Table.Cell className=" text-center">EBK</Table.Cell>
          <Table.Cell className="text-center border-r border-black">
            {isEbkEnabled ? (
              <span className="text-green-600">{t('common.yes')}</span>
            ) : (
              <span className="text-red-500">{t('common.no')}</span>
            )}
          </Table.Cell>

          <Table.Cell className="text-center border-r border-black">
            {formatDate(latestSuccessfulSendToTicketInspection)}
          </Table.Cell>

          <Table.Cell className=" text-center">
            {numberOfTicketsSentWithinLastHour}
          </Table.Cell>
          <Table.Cell className=" text-center">
            {numberOfSuccessfulRegistrationToTicketInspection}
          </Table.Cell>
          <Table.Cell className=" text-center">
            {percentOfSuccessfulRegistrationInTicketInspection.toFixed(2)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className=" text-center">DSB Financial</Table.Cell>
          <Table.Cell className="text-center border-r border-black">
            {isDsbEnabled ? (
              <span className="text-green-600">{t('common.yes')}</span>
            ) : (
              <span className="text-red-500">{t('common.no')}</span>
            )}
          </Table.Cell>
          <Table.Cell className="text-center border-r border-black">
            {formatDate(latestSuccessfulSendToFinancialAuthority)}
          </Table.Cell>

          <Table.Cell className=" text-center">
            {numberOfTicketsSentWithinLastHour}
          </Table.Cell>
          <Table.Cell className=" text-center">
            {numberOfSuccessfulRegistrationToFinancialAuthority}
          </Table.Cell>
          <Table.Cell className=" text-center">
            {percentOfSuccessfulRegistrationInFinancialAuthority.toFixed(2)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default TableEbkDsb;
