import z from 'zod';
import { IncomingMessageTypeDtoSchema } from './IncomingMessageTypeDto';

export const IncomingMessageDtoSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  text: z.object({
    value: z.string(),
  }),
  provider: z.object({
    value: z.string(),
  }),
  externalId: z.object({
    value: z.number(),
  }),
  processId: z.object({
    value: z.string(),
  }),
  userId: z.object({
    value: z.number(),
  }),
  type: IncomingMessageTypeDtoSchema,
  deliveredAt: z.string(),
  createdAt: z.string(),
});

export type IncomingMessageDto = z.infer<typeof IncomingMessageDtoSchema>;
