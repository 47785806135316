import type { z } from 'zod';

import { Auth } from 'aws-amplify';
import { getJson } from '../../http/getJson';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

type UseAuthenticatedQueryOptions<T extends z.ZodTypeAny> = {
  schema: T;
  url: string;
};

export const useAuthenticatedQuery = <
  T extends z.ZodTypeAny,
  Data = z.TypeOf<T>,
  Error = unknown
>(
  { schema, url }: UseAuthenticatedQueryOptions<T>,
  queryOptions?: UseQueryOptions<z.TypeOf<T>, Error, Data>
): UseQueryResult<Data, Error> => {
  const queryFn = async (): Promise<z.TypeOf<T>> => {
    const session = await Auth.currentSession();

    const jwtToken = session.getIdToken().getJwtToken();

    return getJson({
      headers: { Authorization: `Bearer ${jwtToken}` },
      url,
      schema,
    });
  };

  return useQuery({
    ...queryOptions,
    refetchOnWindowFocus: false,
    queryFn,
  });
};
