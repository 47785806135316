import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import NotyfContext from '~/context/NotyfContext';
import { options } from '~/features/modal/ConfirmAlert';

const changeBulletinMutation = async (bulletinData: {
  id: number;
  publishedBy: string;
  subject: string;
  content: string;
}) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(`${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/bulletins`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bulletinData),
    method: 'PUT',
  }).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};
export const useChangeBulletinMutation = (options: {
  onSuccess: () => void;
}) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(changeBulletinMutation, {
    onSuccess: () => {
      notyf.success(t('bulletins.successfully-created-bulletin'));
      queryClient.invalidateQueries({ queryKey: ['bulletins'] });
      queryClient.invalidateQueries({ queryKey: ['bulletin'] });
      options.onSuccess && options.onSuccess();
    },
    onError: (e: { message: string }) => {
      notyf.error(e.message);
    },
  });
};
