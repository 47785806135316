import React from 'react';
import { clsx } from 'clsx';

type TButton = {
  children: JSX.Element | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  type?: ButtonType;
  disabled?: boolean;
  isVisible?: boolean;
};

type ButtonType = 'gray' | 'red' | 'simple' | 'default';

const buttonTypeMap: Record<ButtonType, string> = {
  gray: 'text-black bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800',
  red: 'text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800',
  simple:
    'px-3 py-2 bg-gray-50 border border-gray-300 rounded-lg hover:bg-gray-100 cursor-pointer shadow-sm',
  default:
    'text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800',
};

const Button = ({
  children,
  onClick,
  className,
  type = 'default',
  disabled = false,
  isVisible = true,
}: TButton) => {
  if (!isVisible) {
    return null;
  }

  const buttonTypeMapped = buttonTypeMap[type];

  return (
    <button
      onClick={onClick}
      type="submit"
      className={clsx(buttonTypeMapped, className)}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default Button;
