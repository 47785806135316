import { useTranslation } from 'react-i18next';
import { snakeToTitle } from '~/features/search/utils';
import type { TUser } from '../types';
import type { UpdateType } from '../useUsers';
import ResendUserPasswordCell from './ResendUserPasswordCell';
import { parseGMT } from '~/lib/utils/formatDates/formatDates';
import { useTranslateUserStatus } from '~/hooks/useTranslateUserStatus';
import { getUserStatus } from '../utils';

type TUserStatusCellProps = {
  canResend: boolean;
  user: TUser;
  updateUser: (username: string, updateType: UpdateType) => void;
};

const UserStatusCell = ({
  canResend,
  updateUser,
  user,
}: TUserStatusCellProps) => {
  const { translateUserStatus } = useTranslateUserStatus();

  const userStatus = getUserStatus(user);
  if (canResend && (userStatus === 'RESEND' || userStatus === 'INACTIVE')) {
    return <ResendUserPasswordCell updateUser={updateUser} user={user} />;
  }
  return <td className="px-6 py-4">{translateUserStatus(userStatus)}</td>;
};
export default UserStatusCell;
