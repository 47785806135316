import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { Container } from '../../components/Layout';
import NotyfContext from '../../context/NotyfContext';
import { useCurrentUserContext } from '../../hooks/useCurrentUser';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useMutation, useQueryClient } from 'react-query';
import { LoadingCurrentUser } from '~/components/LoadingCurrentUser';

const addBulletinMutation = async (bulletinData: {
  publishedBy: string;
  subject: string;
  content: string;
}) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(`${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/bulletins`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bulletinData),
    method: 'POST',
  }).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

const CreateBulletin: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUserContext();
  const [bulletinData, setBulletinData] = useState({
    author: currentUser?.email,
    subject: '',
    content: EditorState.createEmpty(),
  });

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const notyf = useContext(NotyfContext);

  const { mutate } = useMutation(addBulletinMutation, {
    onSuccess: () => {
      notyf.success(t('bulletins.successfully-created-bulletin'));
      navigate('/bulletins');
      queryClient.invalidateQueries({ queryKey: ['bulletins'] });
    },
    onError: (e: { message: string }) => {
      notyf.error(e.message);
    },
  });

  if (!currentUser) return <LoadingCurrentUser currentUser={currentUser} />;

  const handleSubmit = () => {
    const subjectLength = bulletinData.subject.length;
    const rawContentState = convertToRaw(
      bulletinData.content.getCurrentContent()
    );
    const jsonData = JSON.stringify(rawContentState);

    const contentLength = jsonData.length;

    const hasText = rawContentState.blocks.some(
      (block) => block.text.length > 0
    );

    if (subjectLength < 1)
      return notyf.error(t('bulletins.bulletin-subject-validation-min'));
    if (subjectLength > 200)
      return notyf.error(t('bulletins.bulletin-subject-validation-max'));

    if (!hasText)
      return notyf.error(t('bulletins.bulletin-content-validation-min'));
    if (contentLength > 10000)
      return notyf.error(t('bulletins.bulletin-content-validation-max'));

    mutate({
      publishedBy: currentUser.email,
      subject: bulletinData.subject,
      content: jsonData,
    });
  };

  return (
    <div>
      <Header className="pb-2">{t('bulletins.bulletins')}</Header>

      <Container>
        <div className="flex flex-col gap-2">
          <div className="">
            <span className="font-semibold">{t('tables.author') + ': '}</span>
            {currentUser?.email}
          </div>
          <div className="flex flex-col">
            <span className="py-1 font-semibold">{t('bulletins.subject')}</span>
            <input
              type="text"
              name="subject"
              className="md:w-1/2 px-2 py-1 rounded-sm border-[#ced4da]"
              onChange={(e) =>
                setBulletinData((data) => {
                  return { ...data, [e.target.name]: e.target.value };
                })
              }
              value={bulletinData.subject}
            />
          </div>
          <div className="flex flex-col">
            <span className="py-1 font-semibold">{t('bulletins.content')}</span>

            <div data-color-mode="light">
              <Editor
                editorState={bulletinData.content}
                toolbarClassName="toolbarClassName mb-0"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName bg-white p-2 border-[#F1F1F1] border border-t-0"
                onEditorStateChange={(editorState) =>
                  setBulletinData({ ...bulletinData, content: editorState })
                }
              />
            </div>
          </div>
          <div>
            <Button onClick={handleSubmit} className="my-4">
              {t('bulletins.publish-bulletin')}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default CreateBulletin;
