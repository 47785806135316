import z from 'zod';

export const IncomingMessageTypeDtoSchema = z.union([
  z.literal('NEW_ORDER'),
  z.literal('HINTS'),
  z.literal('CANCEL'),
  z.literal('CONFIRMATION'),
  z.literal('DELAYED'),
  z.literal('ORDER_RECEIPT'),
]);

export type IncomingMessageTypeDto = z.infer<
  typeof IncomingMessageTypeDtoSchema
>;
