import { useTranslation } from 'react-i18next';
import type { Nullable } from '~/features/search/types';
import { Cell } from './Cell';
import type { TicketPaymentStatusDto } from '~/lib/dtos/common/TicketPaymentStatusDto';

export const PaymentStatusCell = ({
  paymentStatus,
}: {
  paymentStatus: Nullable<TicketPaymentStatusDto>;
}) => {
  const { color, name, helperText } = usePaymentStatusMap(paymentStatus);

  return (
    <Cell
      content={name}
      color={color}
      helperText={helperText}
      helperTextPlacement={'right'}
    />
  );
};

const usePaymentStatusMap = (
  paymentStatus: Nullable<TicketPaymentStatusDto>
) => {
  const { t } = useTranslation();

  const paymentStatusMap: Record<
    TicketPaymentStatusDto,
    { color: string; name: string; helperText: string }
  > = {
    PAYMENT_COMPLETED: {
      color: 'bg-green-300',
      name: t('status.payment-completed'),
      helperText: t('helper-texts.order-info.payment-status.payment-completed'),
    },
    PAYMENT_REFUNDED: {
      color: 'bg-green-100',
      name: t('status.payment-refunded'),
      helperText: t('helper-texts.order-info.payment-status.payment-refunded'),
    },
    PAYMENT_PROCESSING: {
      color: 'bg-yellow-300',
      name: t('status.payment-processing'),
      helperText: t(
        'helper-texts.order-info.payment-status.payment-processing'
      ),
    },
    PAYMENT_REJECTED: {
      color: 'bg-red-400',
      name: t('status.payment-rejected'),
      helperText: t('helper-texts.order-info.payment-status.payment-rejected'),
    },
  };

  return paymentStatus
    ? paymentStatusMap[paymentStatus]
    : { color: null, name: null, helperText: null };
};
