import { Table } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pagination from '../search/components/Pagination';
import { useHasPermissions } from '../../hooks/useHasPermissions';
import { BarLoader } from 'react-spinners';
import { useDeleteBulletinMutation } from '~/lib/hooks/useDeleteBulletinMutation';
import { useBulletins } from './useBulletins';
import type { BulletinDto } from '~/lib/dtos/BulletinDto';
import { formatDate } from '~/lib/utils/formatDates/formatDates';

const BulletinsTable = ({
  newest,
  edit,
  itemsPerPage,
}: {
  newest: boolean;
  edit: boolean;
  itemsPerPage: number;
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [bulletinsOnPage, setBulletinsOnPage] = useState<
    BulletinDto['bulletin'][] | null
  >(null);

  const { setupDeleteBulletinAlert } = useDeleteBulletinMutation();

  const { data, error } = useBulletins(currentPage, itemsPerPage ?? 5);

  useEffect(() => {
    if (newest && data?.bulletinList) {
      setBulletinsOnPage(data.bulletinList.slice(0, 3));
    } else if (!newest && data) {
      setBulletinsOnPage(data.bulletinList);
    }
  }, [currentPage, data, error, newest]);

  useEffect(() => {
    if (error !== null) {
      // handle error
    }
    if (newest && data?.bulletinList) {
      setBulletinsOnPage(data.bulletinList.slice(0, 3));
    } else if (!newest && data) {
      setBulletinsOnPage(data.bulletinList);
    }
  }, [currentPage, data, error, newest]);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const hasPermissions = useHasPermissions('MANAGE_BULLETINS');
  const { t } = useTranslation();

  return (
    <>
      <Table className="border-spacing-0 border border-separate border-gray-300 rounded-md shadow-lg">
        <>
          {bulletinsOnPage !== null ? (
            <>
              {bulletinsOnPage.length > 0 ? (
                <>
                  <Table.Head className="bg-gray-100">
                    <Table.HeadCell>{t('bulletins.published')}</Table.HeadCell>
                    <Table.HeadCell>{t('bulletins.modified')}</Table.HeadCell>
                    <Table.HeadCell>{t('bulletins.subject')}</Table.HeadCell>
                    <Table.HeadCell>
                      {t('bulletins.published-by')}
                    </Table.HeadCell>
                    {edit && hasPermissions && (
                      <Table.HeadCell>{t('bulletins.actions')}</Table.HeadCell>
                    )}
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {bulletinsOnPage.map((bulletin, id) => {
                      return (
                        <Table.Row
                          key={id}
                          className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
                        >
                          <Table.Cell className="whitespace-nowrap">
                            {formatDate(bulletin.publishedAt)}
                          </Table.Cell>
                          <Table.Cell className="whitespace-nowrap">
                            {bulletin.publishedAt.split('.')[0] !==
                            bulletin.modifiedAt.split('.')[0]
                              ? formatDate(bulletin.modifiedAt)
                              : '-'}
                          </Table.Cell>
                          <Table.Cell className="text-blue-500 cursor-pointer">
                            <Link to={`/bulletin/${bulletin.id.value}`}>
                              {bulletin.subject}
                            </Link>
                          </Table.Cell>
                          <Table.Cell> {bulletin.publishedBy}</Table.Cell>
                          {edit && hasPermissions && (
                            <Table.Cell
                              onClick={setupDeleteBulletinAlert(
                                bulletin.id.value,
                                bulletin.subject
                              )}
                              className="text-red-500 cursor-pointer"
                            >
                              {t('tables.delete')}
                            </Table.Cell>
                          )}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </>
              ) : (
                <Table.Head className="text-center">
                  <Table.HeadCell>
                    {t('bulletins.no-bulletins-yet')}
                  </Table.HeadCell>
                </Table.Head>
              )}
            </>
          ) : (
            <Table.Head className="justify-center text-center">
              <Table.HeadCell className="flex justify-center mx-auto">
                <BarLoader color="#7f7f7f" className="mx-auto" />
              </Table.HeadCell>
            </Table.Head>
          )}
        </>
      </Table>
      {!newest && (
        <Pagination
          resultsNumber={data?.count ?? 0}
          onPageChange={handlePageClick}
          pageCount={data?.availablePages ?? 1}
          currentPage={currentPage - 1}
        />
      )}
    </>
  );
};
export default BulletinsTable;
