import { Table } from 'flowbite-react';

type Props = { name: string };

export const HeadCell = ({ name }: Props) => {
  return (
    <Table.HeadCell className="px-4 text-center border-2 border-gray-100">
      {name}
    </Table.HeadCell>
  );
};
