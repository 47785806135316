import type { AuthenticatorDictionary } from './types';

export const enDict: AuthenticatorDictionary = {
  'Account recovery requires verified contact information':
    'Account recovery requires verified contact information',
  'Add your Profile': 'Add your Profile',
  'Add your Website': 'Add your Website',
  'Back to Sign In': 'Back to Sign In',
  'Change Password': 'Change Password',
  Changing: 'Changing',
  Code: 'Code',
  'Confirm Password': 'Confirm Password',
  'Confirm Sign Up': 'Confirm Sign Up',
  'Confirm SMS Code': 'Confirm SMS Code',
  'Confirm TOTP Code': 'Confirm TOTP Code',
  Confirm: 'Confirm',
  'Confirmation Code': 'Confirmation Code',
  Confirming: 'Confirming',
  'Create a new account': 'Create a new account',
  'Create Account': 'Create Account',
  'Creating Account': 'Creating Account',
  'Dismiss alert': 'Dismiss alert',
  Email: 'E-mail',
  'Enter your Birthdate': 'Enter your Birthdate',
  'Enter your code': 'Enter your code',
  'Enter your Confirmation Code': 'Enter your Confirmation Code',
  'Enter your Email': 'Enter your Email',
  'Enter your Family Name': 'Enter your Family Name',
  'Enter your Given Name': 'Enter your Given Name',
  'Enter your Middle Name': 'Enter your Middle Name',
  'Enter your Name': 'Enter your Name',
  'Enter your Nickname': 'Enter your Nickname',
  'Enter your Password': 'Enter password',
  'Enter your phone number': 'Enter your phone number',
  'Enter your Preferred Username': 'Enter your Preferred Username',
  'Enter your Username': 'Enter your e-mail',
  'Forgot password?': 'Forgot password?',
  'Forgot your password?': 'Forgot your password?',
  'Hide password': 'Hide password',
  'It may take a minute to arrive': 'It may take a minute to arrive',
  Loading: 'Loading',
  'New Password': 'New password',
  or: 'or',
  Password: 'Password',
  'Phone Number': 'Phone Number',
  'Please confirm your Password': 'Please confirm your Password',
  'Resend Code': 'Resend Code',
  'Reset your password': 'Reset your password',
  'Reset your Password': 'Reset your Password',
  'Send code': 'Send code',
  'Send Code': 'Send Code',
  Sending: 'Sending',
  'Setup TOTP': 'Setup TOTP',
  'Show password': 'Show password',
  'Sign in to your account': 'Sign in to your account',
  'Sign In with Amazon': 'Sign In with Amazon',
  'Sign In with Apple': 'Sign In with Apple',
  'Sign In with Facebook': 'Sign In with Facebook',
  'Sign In with Google': 'Sign In with Google',
  'Sign in': 'Sign in',
  'Sign In': 'Sign In',
  'Signing in': 'Signing in',
  Skip: 'Skip',
  Submit: 'Submit',
  Submitting: 'Submitting',
  Username: 'E-mail',
  'Verify Contact': 'Verify Contact',
  Verify: 'Verify',
  'We Emailed You': 'We Emailed You',
  'We Sent A Code': 'We Sent A Code',
  'We Texted You': 'We Texted You',
  'Your code is on the way. To log in, enter the code we emailed to':
    'Your code is on the way. To log in, enter the code we emailed to',
  'Your code is on the way. To log in, enter the code we sent you':
    'Your code is on the way. To log in, enter the code we sent you',
  'Your code is on the way. To log in, enter the code we texted to':
    'Your code is on the way. To log in, enter the code we texted to',
  'User password cannot be reset in the current state.':
    'Check your email for temporary password to DOT SMS Backoffice or contact your Administrator.',
  'Reset Password': 'Reset Password',
  'Enter your username': 'Enter your username',
  'User is disabled.': 'User is disabled.',
  'Temporary password has expired and must be reset by an administrator.':
    'Temporary password has expired and must be reset by an administrator.',
  'Username/client id combination not found.': 'User not found.',
  'Code *': 'Code *',
  'Attempt limit exceeded, please try after some time.':
    'Password reset attempts limit exceeded, please try after some time.',
  'User does not exist.': 'User does not exists.',
  'Incorrect username or password.': 'Incorrect e-mail or password.',
  'Invalid verification code provided, please try again.':
    'Invalid verification code provided, please try again.',
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
    'Password does not conform to policy: Password not long enough',
  "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+":
    'E-mail cannot have whitespace.',
  "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+":
    'E-mail cannot have whitespace.',
  'Password does not conform to policy: Password must have lowercase characters':
    'Password does not conform to policy: Password must have lowercase characters',
  'Password does not conform to policy: Password not long enough':
    'Password does not conform to policy: Password not long enough',
  'Password does not conform to policy: Password must have uppercase characters':
    'Password does not conform to policy: Password must have uppercase characters',
  'Your passwords must match': 'Your passwords must match',
  'Username cannot be empty': 'E-mail cannot be empty',
  'Password attempts exceeded':
    'Login attempt limit exceeded, please try after some time.',
};
