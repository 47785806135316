import { useTranslation } from 'react-i18next';
import type { Nullable } from '~/features/search/types';
import { Cell } from './Cell';
import { getFormattedDelay } from '~/features/search/utils';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';
import type { PropsWithChildren } from 'react';
import { differenceInSeconds, parseISO } from 'date-fns';
import { Tooltip } from 'flowbite-react';

const DISPLAY_DURATION_FROM_SECONDS = 15;

type DeliveryCellProps = {
  isResent?: boolean;
  fromDate: Nullable<string>;
  toDate: Nullable<string>;
  status: Nullable<string>;
};

const convertUppercaseToLowercase = (input: string): string =>
  input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();

export const DeliveryCell = ({
  fromDate,
  isResent = false,
  status,
  toDate,
}: DeliveryCellProps) => {
  const { t } = useTranslation();

  if (!status) {
    return (
      <Cell
        color="bg-yellow-400"
        content={
          <ContentWithResend isResent={isResent}>
            {t('status.missing-delivery-info')}
          </ContentWithResend>
        }
      />
    );
  }

  if (status === 'FAILED') {
    return (
      <Cell
        color="bg-red-400"
        content={
          <ContentWithResend isResent={isResent}>
            {convertUppercaseToLowercase(status)}
          </ContentWithResend>
        }
      />
    );
  }

  if (!toDate) {
    return <Cell content={null} />;
  }

  if (!fromDate) {
    return (
      <Cell
        color="bg-green-400"
        content={
          <ContentWithResend isResent={isResent}>
            <DateBreakHandler date={toDate} />
          </ContentWithResend>
        }
      />
    );
  }

  const delayInSeconds = differenceInSeconds(
    parseISO(toDate),
    parseISO(fromDate)
  );

  if (delayInSeconds < DISPLAY_DURATION_FROM_SECONDS) {
    return (
      <Cell
        color="bg-green-300"
        content={
          <ContentWithResend isResent={isResent}>
            <DateBreakHandler date={toDate} />
          </ContentWithResend>
        }
      />
    );
  }

  return (
    <Cell
      content={
        <ContentWithResend isResent={isResent}>
          <div className="flex flex-col items-center justify-center gap-1">
            <DateBreakHandler date={toDate} />
            <div>
              {t('status.delay') + ': ' + getFormattedDelay(delayInSeconds)}
            </div>
          </div>
        </ContentWithResend>
      }
      color="bg-red-400"
    />
  );
};

type ContentWithResendProps = PropsWithChildren<
  Pick<DeliveryCellProps, 'isResent'>
>;

const ContentWithResend = ({ children, isResent }: ContentWithResendProps) => {
  const { t } = useTranslation();

  if (!isResent) {
    return (
      <div className="flex justify-center">
        <Tooltip
          content={t('helper-texts.messages.delivered.system')}
          placement="right"
        >
          {children}
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <Tooltip
        content={t('helper-texts.messages.delivered.system')}
        placement="right"
      >
        {children} <div>{`(${t('common.resent')})`}</div>
      </Tooltip>
    </div>
  );
};
