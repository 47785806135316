import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import Button from '../../../components/Button';
import { useBasicUserQuery } from '~/lib/hooks/useBasicUserQuery';
import { Link } from 'react-router-dom';
import { useDecodeSearchParams } from '~/hooks/useDecodeSearchParams';
import { updateSearchParams } from '~/lib/utils/updateParams/updateParams';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';
import { useTranslateTicketTypes } from '~/hooks/useTranslateTicketTypes';
import { formatDate } from '~/lib/utils/formatDates/formatDates';
import { mapOperator } from '../utils';

const operatorsAll = ['dk.telia', 'dk.sonofon', 'dk.tdc', 'dk.hi3g'];

const getDisplayedNumber = (phoneNumber?: string): string => {
  if (!phoneNumber) {
    return '-';
  }

  if (phoneNumber.includes('*')) {
    return phoneNumber;
  }

  if (phoneNumber.length !== 8) {
    return `*${phoneNumber}*`;
  }

  return `45${phoneNumber}`;
};

const ResultsNavbar = () => {
  const [, state] = useDecodeSearchParams();

  const { search } = useLocation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isSuccess } = useBasicUserQuery(
    state.phoneNumber ? `45${state.phoneNumber}` : '',
    {
      enabled:
        Boolean(state.phoneNumber) &&
        state?.phoneNumber?.length === 8 &&
        !state.phoneNumber.includes('*'),
    }
  );

  const updateSearch = () => {
    navigate(
      '/orders?' +
        updateSearchParams(search.slice(1), 'endDate', new Date().toISOString())
    );
  };

  const displayOperators = () => {
    if (!state.operators || state.operators.length === 0) {
      return t('common.none');
    }

    if (state.operators.length === operatorsAll.length) {
      return t('common.all');
    }

    return state.operators.map(mapOperator).join(', ');
  };

  const goBack = () => {
    if (state.searchType === 'basic') {
      navigate('/');
    } else if (state.searchType === 'advanced') {
      navigate('/advanced_search');
    } else {
      navigate(-1);
    }
  };

  if (!search) {
    navigate('/');
    return null;
  }

  return (
    <div className="overflow-y-clip flex flex-col overflow-x-auto">
      <div className="sm:gap-0 flex justify-between gap-2 pb-4">
        <Button type="gray" onClick={goBack}>
          {t('common.back')}
        </Button>
        <div className="sm:pt-0 flex">
          <div className="flex justify-end gap-2">
            <Button onClick={updateSearch}>{t('search.update-search')}</Button>
          </div>
        </div>
      </div>

      <div className="">
        <div>
          <Table className="border-spacing-0 mt-2 border border-separate border-gray-300 rounded-md shadow-lg">
            <Table.Head className="bg-gray-100">
              <Table.HeadCell className="text-center">
                {t('tables.phone-number-customer-profile')}
              </Table.HeadCell>
              <Table.HeadCell className="text-center">
                {t('tables.ticket-id')}
              </Table.HeadCell>
              <Table.HeadCell className="text-center">
                {t('tables.payment-id')}
              </Table.HeadCell>
              <Table.HeadCell className="text-center">
                {t('common.start-date')}
              </Table.HeadCell>
              <Table.HeadCell className="text-center">
                {t('common.end-date')}
              </Table.HeadCell>
              <Table.HeadCell className="text-center">
                {t('search.operators')}
              </Table.HeadCell>
              <Table.HeadCell className="text-center">
                {t('search.ticket-types')}
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
                <Table.Cell
                  className={'cursor-pointer px-3 py-1.5 text-center'}
                >
                  {isSuccess ? (
                    <Link
                      to={`/customer/${data.user.id.value}`}
                      className="text-blue-500"
                    >
                      45{state.phoneNumber}
                    </Link>
                  ) : (
                    getDisplayedNumber(state.phoneNumber)
                  )}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-center border border-gray-100">
                  {state.ticketId}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-center border border-gray-100">
                  {state.fourTPaymentId}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-center border border-gray-100">
                  {formatDate(state.startDate ?? '')}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-center border border-gray-100">
                  {formatDate(state.endDate ?? '')}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-center border border-gray-100">
                  {displayOperators()}
                </Table.Cell>
                <Table.Cell className="text-center border border-gray-100">
                  <TicketTypeHandler />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ResultsNavbar;

const TicketTypeHandler = () => {
  const [, { zones, bikePossession, ticketTypes }] = useDecodeSearchParams();

  const { translateTicketTypes } = useTranslateTicketTypes();

  const { t } = useTranslation();

  const customerTypes: Partial<SearchSchemaType['ticketTypes']> = [
    'ADULT',
    'CHILD',
    'DOG',
  ];

  const specialTypes: Partial<SearchSchemaType['ticketTypes']> = [
    'BIKE',
    'DSB1',
    'SUPPLEMENTARY',
  ];

  const ticketTypesString = `${(zones ?? []).join(', ')} (${(
    bikePossession ?? []
  )
    .map(translateTicketTypes)
    .join(', ')}), ${(ticketTypes ?? [])
    .filter((ticketType) => specialTypes.includes(ticketType))
    .map(translateTicketTypes)}`;

  const customersString = (ticketTypes ?? [])
    .filter((ticketType) => customerTypes.includes(ticketType))
    .map(translateTicketTypes)
    .join(', ');

  return (
    <div className="flex flex-col gap-2 text-left">
      <span>{`${t('search.ticket-types')}: ${ticketTypesString || '-'}`}</span>
      <span>{`${t('search.customer-type')}: ${customersString || '-'}`}</span>
    </div>
  );
};
