import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AdvancedSearch from './features/search/advanced/AdvancedSearch';
import Bulletins from './features/bulletins/Bulletins';
import InstructionPublish from './features/instructions/InstructionPublish';
import Instructions from './features/instructions/Instructions';
import { LayoutNavbar } from './components/Layout';
import LoginPage from './features/authentication/LoginPage';
import Main from './features/main/Main';
import AuditLog from './features/maintenance/audit/AuditLog';
import Maintenance from './features/maintenance/Maintenance';
import Profile from './features/profile/Profile';
import { RequireAuth } from './features/authentication/RequireAuth';
import SearchResults from './features/search/components/SearchResults';
import CustomerProfile from './features/customer/CustomerProfile';
import Bulletin from './features/bulletins/Bulletin';
import { CurrentUserContextProvider } from './context/UserContext';
import Users from './features/maintenance/users/Users';
import TicketDetails from './features/search/tickets/TicketDetails';
import OrderDetails from './features/search/orders/OrderDetails';
import PreOrderDetails from './features/search/preorders/PreOrderDetails';
import { MessageDetails } from './features/search/incomingMessages/IncomingMessageDetails';
import { useProjectInfo } from './hooks/useProjectInfo';
import CreateBulletin from './features/bulletins/CreateBulletin';
import CreateUser from './features/maintenance/users/CreateUser';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FormProvider } from 'react-hook-form';
import { useSearch } from './hooks/useSearch';
import UserProfile from './features/maintenance/users/user/User';
import Dashboard from './features/maintenance/dashboard/Dashboard';
import setupI18nAmplify from './features/authentication/dictionaries/setupI18nAmplify';
import './App.css';
import 'notyf/notyf.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'draft-js/dist/Draft.css';
import Queues from './features/maintenance/queues/Queues';
import ProtectedRoute from './components/ProtectedRoute';
import BlockadeMessageDetails from './features/search/blockades/BlockadeMessageDetails';

Amplify.configure({
  Auth: {
    identityPoolId: import.meta.env.VITE_REACT_APP_IDENTITY_POOL_ID,
    region: import.meta.env.VITE_REACT_APP_REGION,
    userPoolId: import.meta.env.VITE_REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env
      .VITE_REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: import.meta.env.VITE_AWS_USER_FILES_S3_BUCKET,
      region: import.meta.env.VITE_REACT_APP_REGION,
    },
  },
  API: {
    endpoints: [
      {
        name: 'DOT',
        endpoint: import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT,
      },
    ],
  },
});

const queryClient = new QueryClient();

void setupI18nAmplify();

const App: React.FC = () => {
  const { projectInfo } = useProjectInfo();

  const { methods } = useSearch();

  return (
    <QueryClientProvider client={queryClient}>
      <Authenticator.Provider>
        <CurrentUserContextProvider>
          <FormProvider {...methods}>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <LayoutNavbar projectInfo={projectInfo} />
                    </RequireAuth>
                  }
                >
                  <Route index element={<Main />} />
                  <Route path="advanced_search" element={<AdvancedSearch />} />
                  <Route path="maintenance" element={<Maintenance />}>
                    <Route
                      path="users"
                      element={
                        <ProtectedRoute
                          permissions={['VIEW_USERS']}
                          fallback="/"
                        >
                          <Users />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="users/add"
                      element={
                        <ProtectedRoute
                          permissions={['CREATE_USER']}
                          fallback="/"
                        >
                          <CreateUser />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="audit_log"
                      element={
                        <ProtectedRoute
                          permissions={['VIEW_AUDIT_LOG']}
                          fallback="/"
                        >
                          <AuditLog />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="status_search"
                      element={
                        <ProtectedRoute
                          permissions={['VIEW_DASHBOARD']}
                          fallback="/"
                        >
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="move_dsb_queue"
                      element={
                        <ProtectedRoute
                          permissions={['MOVE_DSB_QUEUE']}
                          fallback="/"
                        >
                          <Queues />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                  <Route path="user/:username" element={<UserProfile />} />
                  <Route path="bulletins" element={<Bulletins />} />
                  <Route path="bulletins/create" element={<CreateBulletin />} />
                  <Route path="bulletin/:bulletinId" element={<Bulletin />} />
                  <Route path="instructions" element={<Instructions />} />
                  <Route
                    path="instructions/publish"
                    element={<InstructionPublish />}
                  />
                  <Route path="profile" element={<Profile />} />
                  <Route
                    path="customer/:customerId"
                    element={<CustomerProfile />}
                  />
                  <Route path="ticket/:ticketId" element={<TicketDetails />} />
                  <Route path="orders" element={<SearchResults />} />
                  <Route path="order/:id" element={<OrderDetails />} />
                  <Route path="preorder/:id" element={<PreOrderDetails />} />
                  <Route
                    path="otherMessage/:id"
                    element={<MessageDetails type="other_message" />}
                  />
                  <Route
                    path="invalidOrder/:id"
                    element={<MessageDetails type="invalid_order" />}
                  />
                  <Route
                    path="incomingMessage/:id"
                    element={<MessageDetails type="incoming_message" />}
                  />
                  <Route
                    path="blockades/:id"
                    element={<BlockadeMessageDetails />}
                  />
                </Route>
                <Route
                  path="login"
                  element={<LoginPage projectInfo={projectInfo} />}
                />
              </Routes>
            </BrowserRouter>
          </FormProvider>
        </CurrentUserContextProvider>
      </Authenticator.Provider>
    </QueryClientProvider>
  );
};
export default App;
