import { useTranslation } from 'react-i18next';
import { BarLoader } from 'react-spinners';
import type { CurrentUserData } from '~/context/UserContext';

export const LoadingCurrentUser = ({
  currentUser,
}: {
  currentUser: CurrentUserData | null;
}) => {
  const { t } = useTranslation();
  if (currentUser) return null;

  return (
    <div className="flex flex-col justify-center pt-8 mx-auto text-lg text-center">
      {t('common.loading-permissions')}
      <div className="flex justify-center w-full py-4 mx-auto">
        <BarLoader color="#7f7f7f" />
      </div>
    </div>
  );
};
