import { API, Auth } from 'aws-amplify';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';

export const clearMcode = (mCode: string) => {
  return mCode.replaceAll('-', '').replaceAll(' ', '');
};

const getPaymentStatus = (
  isAdvanced?: boolean,
  paymentStatus?: SearchSchemaType['paymentStatus']
) => {
  if (paymentStatus === 'NONE') {
    return 'NONE';
  }

  if (isAdvanced) {
    return paymentStatus === 'AUTHORIZATION_FAILED' ||
      paymentStatus === 'AUTHORIZED'
      ? paymentStatus
      : 'NONE';
  }

  return paymentStatus !== 'AUTHORIZATION_FAILED' &&
    paymentStatus !== 'AUTHORIZED'
    ? paymentStatus
    : 'NONE';
};

export const createUrl = (
  path?: string,
  phoneNumber?: string,
  currentPage?: number,
  itemsPerPage?: number,
  isAdvanced?: boolean,
  startDate?: string,
  endDate?: string,
  paymentId?: string,
  mCode?: string,
  operators?: string[],
  distance?: string[],
  ticketType?: string[],
  paymentStatus?: SearchSchemaType['paymentStatus']
) => {
  const pStatus = getPaymentStatus(isAdvanced, paymentStatus);

  const combinedDistance =
    distance && distance.length !== 7
      ? '&distance=' + distance.join('&distance=')
      : '';

  const combinedTicketType =
    ticketType && ticketType.length !== 9
      ? '&ticketType=' + ticketType.join('&ticketType=')
      : '';

  const combinedOperators =
    operators && operators.length !== 4
      ? '&operator=' + operators.join('&operator=')
      : '';

  if (mCode && mCode.length > 0) {
    return `/ticket/mcode/${clearMcode(mCode)}`;
  }

  if (paymentId && paymentId.length > 0) {
    return `/ticket/payment/${paymentId}`;
  }

  return `/${path}${isAdvanced ? '/search' : ''}?phone=${
    phoneNumber ?? ''
  }&page=${currentPage !== 0 ? currentPage : 1}&limit=${itemsPerPage}${
    isAdvanced
      ? '&startDate=' + startDate + '&endDate=' + endDate
      : '&endDate=' + endDate
  }${combinedOperators}${combinedTicketType}${combinedDistance}&paymentStatus=${pStatus}`;
};

export const mapOperator = (operator: string) => {
  switch (operator) {
    case 'dk.telia':
      return 'Telia';
    case 'dk.hi3g':
      return '3';
    case 'dk.sonofon':
      return 'Telenor';
    case 'dk.tdc':
      return 'Nuuday/TDC';
    default:
      return operator;
  }
};

export const fetchResourceWithToken = async (url: string) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(url, {
    headers: { Authorization: `Bearer ${jwtToken}` },
  });
  return res;
};

export const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const getFormattedDelay = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const remainingSecondsFinal = remainingSeconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSecondsFinal).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export function snakeToTitle(input: string | undefined): string {
  if (input === undefined) return '-';

  const words = input.split('_');

  const output = words
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');

  return output;
}
