import clsx from 'clsx';
import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import type { BlockadeOperationTypeDto } from '~/lib/dtos/common/BlockadeOperationTypeDTO';

interface BlockadeTypeCellProps {
  type: BlockadeOperationTypeDto;
  className?: string;
}

export const BlockadeTypeCell = ({
  type,
  className,
}: BlockadeTypeCellProps) => {
  const { t } = useTranslation();

  const blockadeTypeMap: Record<BlockadeOperationTypeDto, string> = {
    TEMPORARILY_BLOCKED_MANUALLY: t(
      'outgoing-message-status.temporarily-blocked-manually'
    ),
    PERMANENTLY_BLOCKED_MANUALLY: t(
      'outgoing-message-status.permanently-blocked-manually'
    ),
    TEMPORARILY_BLOCKED_AUTOMATICALLY: t(
      'outgoing-message-status.temporarily-blocked-automatically'
    ),
    PERMANENTLY_BLOCKED_AUTOMATICALLY: t(
      'outgoing-message-status.permanently-blocked-automatically'
    ),
    UNBLOCKED_AUTOMATICALLY: t(
      'outgoing-message-status.unblocked-automatically'
    ),
    UNBLOCKED_MANUALLY: t('outgoing-message-status.unblocked-manually'),
  };

  const returnBlockadeType = (type: BlockadeOperationTypeDto) => {
    return blockadeTypeMap[type] || type;
  };

  return (
    <Table.Cell className={clsx('px-3 py-1.5', className)}>
      {returnBlockadeType(type)}
    </Table.Cell>
  );
};
