import { useTranslation } from 'react-i18next';
import { useDashboard } from './useDashBoard';
import Header from '~/components/Header';
import TableSms from './TableSms';
import TableEbkDsb from './TableEbkDsb';
import ButtonWithLoading from '~/components/ButtonWithLoading';
import { formatDate } from '~/lib/utils/formatDates/formatDates';

const Dashboard = () => {
  const { t } = useTranslation();

  const {
    data,
    error,
    isLoading,
    isFetching,
    searchCriteria,
    operatorsData,
    operatorsLoading,
  } = useDashboard();

  const { setRefresh, refresh } = searchCriteria;

  const resetToDefaults = () => {
    setRefresh(new Date().toISOString());
  };

  return (
    <div className="flex flex-col flex-1 overflow-x-auto">
      <div className="flex items-center justify-between">
        <Header size="2xl">{t('dashboard.dashboard-title')}</Header>
        <div className="whitespace-nowrap flex flex-col justify-end mt-auto ml-auto">
          <div className="flex justify-end">
            <ButtonWithLoading
              text={t('search.update-search')}
              loading={isFetching}
              onClick={resetToDefaults}
              type="button"
            />
          </div>

          {!isFetching && data && refresh && (
            <div>Last update: {formatDate(refresh)}</div>
          )}
        </div>
      </div>
      {refresh ? (
        <div className="">
          <Header>SMS</Header>
          <TableSms
            operatorsLoading={operatorsLoading}
            error={error}
            operatorsData={operatorsData ?? []}
          />
          <Header>EBK / DSB</Header>
          <TableEbkDsb isLoading={isLoading} error={error} data={data} />
        </div>
      ) : (
        <div>
          <div className="flex flex-col justify-center pt-8 mx-auto text-center">
            {t('dashboard.get-data')}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
