import z from 'zod';

export const DashboardDtoSchema = z.object({
  dashboard: z.object({
    isEbkEnabled: z.boolean(),
    isDsbEnabled: z.boolean(),
    latestSuccessfulSendToTicketInspection: z.string(),
    latestSuccessfulSendToFinancialAuthority: z.string(),
    numberOfTicketsSentWithinLastHour: z.number(),
    numberOfSuccessfulRegistrationToTicketInspection: z.number(),
    numberOfSuccessfulRegistrationToFinancialAuthority: z.number(),
    percentOfSuccessfulRegistrationInTicketInspection: z.number(),
    percentOfSuccessfulRegistrationInFinancialAuthority: z.number(),
  }),
});

export type DashboardDto = z.infer<typeof DashboardDtoSchema>;
