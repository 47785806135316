import { useNavigate, useParams } from 'react-router';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import MessagesTable from '../messages/MessagesTable';
import IncomingMessageTable from './IncomingMessageTable';
import { useIncomingMessageDetailsQuery } from '~/lib/hooks/useIncomingMessageDetailsQuery';
import { useMessageHeadingMap } from '~/features/customer/FullUserResultsTable/useMessageMap';
import type { MessageTypeDto } from '~/lib/dtos/common/MessageTypeDto';
import { useDownloadSearchResults } from '~/hooks/useDownloadSearchResults';
import type { IncomingMessageDetailsDto } from '~/lib/dtos/IncomingMessageDetailsDto';
import { BarLoader } from 'react-spinners';
import { useHasGroup } from '~/hooks/useHasGroup';
import { useHasPermissions } from '~/hooks/useHasPermissions';

export const MessageDetails = ({ type }: { type: MessageTypeDto }) => {
  const params = useParams<{ id: string }>();

  const { data, isFetching, isError, refetch } = useIncomingMessageDetailsQuery(
    params.id ?? '',
    {
      enabled: Boolean(params.id),
    }
  );

  const navigate = useNavigate();
  const messageDetailsHeadingMapped = useMessageHeadingMap({ type });
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="flex justify-between px-4">
        <Button className="w-auto" type="gray" onClick={() => navigate(-1)}>
          {t('common.back')}
        </Button>
        <div className="flex gap-2">
          <Button className="w-auto" type="gray" onClick={() => refetch()}>
            {t('common.refresh')}
          </Button>
        </div>
      </div>
      <div className="py-4 text-xl font-semibold">
        <div className="px-4">
          {`${messageDetailsHeadingMapped} (Id: ${params.id})`}
        </div>
      </div>
      <IncomingMessageDetailsContent
        data={data}
        isError={isError}
        isFetching={isFetching}
      />
    </div>
  );
};

const IncomingMessageDetailsContent = ({
  data,
  isFetching,
  isError,
}: {
  data?: IncomingMessageDetailsDto;
  isFetching: boolean;
  isError: boolean;
}) => {
  const { t } = useTranslation();

  const { download } = useDownloadSearchResults({
    id: data?.incomingMessageDetails.incomingMessageId.value,
    createdAt: data?.incomingMessageDetails.incomingMessages[0].createdAt ?? '',
    fileType: 'other-message',
  });

  const downloadVisible = useHasPermissions('DOWNLOAD_DATA');

  if (isFetching) {
    return (
      <div className="flex justify-center">
        <BarLoader color="#7f7f7f" className="mx-auto" />
      </div>
    );
  }

  if (isError || !data) {
    return <Header>{t('status.error')}</Header>;
  }

  return (
    <div className="flex w-full px-4 pb-4 overflow-x-auto">
      <div className="flex flex-col">
        <IncomingMessageTable
          incomingMessageDetails={data.incomingMessageDetails}
        />
      </div>
      <div className="flex flex-col w-full ml-8">
        <div className="flex justify-between pt-4 pb-2">
          <div className="mt-auto text-xl font-semibold text-left">
            {t('tables.messages')}
          </div>
          <div className=" mt-auto">
            <Button onClick={download} isVisible={downloadVisible}>
              {t('common.download-messages')}
            </Button>
          </div>
        </div>
        <MessagesTable
          deliveryNotifications={
            data.incomingMessageDetails.deliveryNotifications
          }
          incomingMessages={data.incomingMessageDetails.incomingMessages}
          outgoingMessages={data.incomingMessageDetails.outgoingMessages}
        />
      </div>
    </div>
  );
};
