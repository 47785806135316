import z from 'zod';

export const TicketTypeDtoSchema = z.union([
  z.literal('ADULT'),
  z.literal('ADULT_WITH_BIKE'),
  z.literal('CHILD'),
  z.literal('CHILD_WITH_BIKE'),
  z.literal('SUPPLEMENTARY_ADULT'),
  z.literal('SUPPLEMENTARY_CHILD'),
  z.literal('BIKE'),
  z.literal('DOG'),
  z.literal('DSB1'),
]);

export type TicketTypeDto = z.infer<typeof TicketTypeDtoSchema>;
