import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import type { BlockadeMessageDetailsDto } from '../dtos/BlockadeMessageDetailsDto';
import { BlockadeMessageDetailsDtoSchema } from '../dtos/BlockadeMessageDetailsDto';

export const useBlockadeMessageDetailsQuery = (
  id: string,
  options: UseQueryOptions<BlockadeMessageDetailsDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: BlockadeMessageDetailsDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/blockade-messages/${id}`,
    },
    { ...options, queryKey: ['blockadeMessageDetails', id] }
  );
};
