import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import Button from '~/components/Button';
import NotyfContext from '~/context/NotyfContext';
import { options } from '~/features/modal/ConfirmAlert';
import { useHasGroup } from '~/hooks/useHasGroup';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import type { TUser } from '../types';
import type { UpdateType } from '../useUsers';
import { getUserStatus } from '../utils';

const ResendUserPasswordCell = ({
  user,
  updateUser,
}: {
  user: TUser;
  updateUser: (username: string, updateType: UpdateType) => void;
}) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const { Groups: groups, Username: username, Attributes: attributes } = user;
  const userStatus = getUserStatus(user);
  const mutation = useMutation(
    async (username: string) => {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await fetch(
        `${
          import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
        }/users/resend_password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username }),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return username;
    },
    {
      onSuccess: (username) => {
        updateUser(username, { type: 'resendPassword' });
        notyf.success(t('users.successfully-resent-password'));
      },
      onError: (error: any) => {
        notyf.error(error.message);
      },
    }
  );

  const email = user.Attributes?.find((attribute) => attribute.Name === 'email')
    ?.Value as string;

  const group =
    groups.length > 0 &&
    groups.reduce((prev, current) =>
      prev.Precedence > current.Precedence ? prev : current
    );

  const isSuperuser = useHasGroup('Superuser');
  const hasResendPasswordPermission = useHasPermissions('RESEND_PASSWORD_USER');

  const cannotEditSuperuser =
    group && group.GroupName === 'Superuser' && !isSuperuser;

  const setupResendAlert = (username: string, email: string | undefined) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title =
      t('users.are-you-sure-you-want-to-resend-password-for') +
      ' ' +
      email +
      '?';
    newOptions.buttons = [
      {
        className: '!bg-red-500',
        label: t('common.yes'),
        onClick: () => mutation.mutate(username),
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };

  if (
    hasResendPasswordPermission &&
    !cannotEditSuperuser &&
    (userStatus === 'RESEND' || userStatus === 'INACTIVE')
  ) {
    return (
      <td className=" px-6 py-4 text-center">
        <Button
          type="simple"
          onClick={() => confirmAlert(setupResendAlert(username, email))}
        >
          {t('tables.resend')}
        </Button>

        {userStatus === 'INACTIVE' ? (
          <>
            <br />
            {t('users.waiting-for-confirmaiton')}
          </>
        ) : null}
      </td>
    );
  }
  return (
    <td className=" px-6 py-4">
      <span className="text-gray-500 cursor-not-allowed">
        {t('tables.resend')}
      </span>
    </td>
  );
};
export default ResendUserPasswordCell;
