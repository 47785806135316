import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import Header from '~/components/Header';
import { Modal } from '~/components/Modal/Modal';
import { useModal } from '~/hooks/useModal';
import { useCustomerNotesTable } from './useCustomerNotesTable';
import { useEffect, useState } from 'react';
import Pagination from '~/features/search/components/Pagination';
import { formatDate } from '~/lib/utils/formatDates/formatDates';

type Props = {
  customerId: string;
};

export const CustomerNotesTable = ({ customerId }: Props) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const { notes, error, isLoading, availablePages, count } =
    useCustomerNotesTable({
      userId: customerId,
      limit: 4,
      page,
    });

  useEffect(() => {
    if (!availablePages) {
      return;
    }

    if (availablePages <= page - 1) {
      setPage(availablePages);
      return;
    }
  }, [availablePages]);

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  return (
    <div className="flex flex-col justify-center mt-4">
      <Header className="mt-auto mb-2">{t('customer.customer-notes')}</Header>
      <Table className="shadow-lg">
        <TableBody notes={notes} isLoading={isLoading} error={error} />
      </Table>
      <Pagination
        currentPage={page - 1}
        onPageChange={handlePageClick}
        pageCount={availablePages}
        resultsNumber={count}
      />
    </div>
  );
};

type TableBodyProps = Pick<
  ReturnType<typeof useCustomerNotesTable>,
  'notes' | 'error' | 'isLoading'
>;

const TableBody = ({ notes }: TableBodyProps) => {
  const { t } = useTranslation();

  if (notes.length === 0) {
    return (
      <Table.Head className="flex justify-center w-full text-center">
        <Table.HeadCell>{t('customer.no-results')}</Table.HeadCell>
      </Table.Head>
    );
  }

  return (
    <>
      <Table.Head className="bg-gray-100">
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('tables.created')}
          </div>
        </Table.HeadCell>
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('customer.note')}
          </div>
        </Table.HeadCell>

        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {t('customer.user')}
          </div>
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">
        {notes.map(({ content, createdAt, createdBy, id }) => {
          return (
            <Table.Row
              key={id.value}
              className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
            >
              <Table.Cell className="px-3 py-1.5 text-center border-2 border-gray-100">
                {formatDate(createdAt)}
              </Table.Cell>
              <ModalCell content={content} />
              <Table.Cell className="px-3 py-1.5 text-center border-2 border-gray-100">
                {createdBy}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </>
  );
};

export const ModalCell = ({ content }: { content: string }) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useModal();

  return (
    <>
      <Table.Cell
        className="max-w-xs px-6 py-4 text-center border-2 border-gray-100"
        onClick={() => {
          setIsModalOpen();
        }}
      >
        <div className="text-blue-500 truncate cursor-pointer">{content}</div>
      </Table.Cell>
      <Modal isVisible={isModalOpen} toggleVisibility={setIsModalOpen}>
        <div className="w-96 flex flex-col p-8">
          <Header className="pb-2 text-left">{t('customer.note')}</Header>
          <span className="text-left break-words">{content}</span>
        </div>
      </Modal>
    </>
  );
};
