import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import type { TicketsV2Dto } from '../dtos/TicketsV2Dto';
import { TicketsV2DtoSchema } from '../dtos/TicketsV2Dto';
import { buildParams } from '../utils/buildParams/buildParams';
import type { SearchSchemaType } from '../schemas/searchSchema';
import type { TicketTypeDto } from '../dtos/common/TicketTypeDto';

export const addZonesByTicketTypes = (
  ticketTypes?: TicketTypeDto[]
): string[] => {
  const zones: string[] = [];

  if (!ticketTypes) {
    return [];
  }

  if (
    ticketTypes.includes('SUPPLEMENTARY_ADULT') ||
    ticketTypes.includes('SUPPLEMENTARY_CHILD')
  ) {
    zones.push('1');
  }

  if (ticketTypes.includes('BIKE')) {
    zones.push('4');
  }

  if (ticketTypes.includes('DSB1')) {
    zones.push('8');
  }

  return zones;
};

type Args = {
  searchParams: {
    endDate: string;
    limit: string;
    page: string;
    startDate: string;
    distance: string[];
    phone?: string;
    operator: SearchSchemaType['operators'];
    ticketType: TicketTypeDto[];
    ticketStatus: SearchSchemaType['ticketStatus'];
    paymentStatus: SearchSchemaType['paymentStatus'];
  };
  options?: UseQueryOptions<TicketsV2Dto>;
};

export const useTicketsV2Query = ({ searchParams, options }: Args) => {
  const addedZones = addZonesByTicketTypes(searchParams.ticketType);

  const search = buildParams({
    ...searchParams,
    paymentStatus:
      searchParams.paymentStatus === 'AUTHORIZATION_FAILED' ||
      searchParams.paymentStatus === 'AUTHORIZED'
        ? 'NONE'
        : searchParams.paymentStatus,
    distance: [...new Set([...searchParams.distance, ...addedZones])],
  });

  return useAuthenticatedQuery(
    {
      schema: TicketsV2DtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/v2/tickets/search?${search}`,
    },
    { ...options, queryKey: ['ticketsSearchV2', searchParams], retry: false }
  );
};
