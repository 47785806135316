import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';
import { useSearchSchema } from '~/lib/schemas/searchSchema';
import { subDays } from 'date-fns';

export const defaultValues: SearchSchemaType = {
  phoneNumber: '',
  ticketId: '',
  fourTPaymentId: '',
  correlationId: '',
  startDate: subDays(new Date(), 1).toISOString(),
  endDate: new Date().toISOString(),
  operators: ['dk.hi3g', 'dk.sonofon', 'dk.tdc', 'dk.telia'],
  ticketTypes: ['ADULT', 'BIKE', 'CHILD', 'DOG', 'DSB1', 'SUPPLEMENTARY'],
  searchType: 'advanced',
  zones: ['2', '3', '4', '5', '6', '7', '8'],
  bikePossession: ['HAS_BIKE', 'WITHOUT_BIKE'],
  ticketStatus: 'NONE',
  paymentStatus: 'NONE',
  ticketsPage: '1',
  ticketsResultsPerPage: '10',
  ordersPage: '1',
  ordersResultsPerPage: '10',
  preordersPage: '1',
  preordersResultsPerPage: '10',
  otherMessagesPage: '1',
  otherMessagesResultsPerPage: '10',
  blockadeMessagesPage: '1',
  blockadeMessagesResultsPerPage: '10',
};

export const useSearch = () => {
  const searchSchema = useSearchSchema();

  const methods = useForm<SearchSchemaType>({
    resolver: zodResolver(searchSchema),
    reValidateMode: 'onSubmit',
    defaultValues,
  });

  return { methods };
};
