import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

const Pagination = ({
  onPageChange,
  pageCount,
  currentPage,
  resultsNumber,
}: {
  onPageChange: (event: { selected: number }) => void;
  pageCount: number;
  currentPage: number;
  resultsNumber?: number | null;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between">
      {resultsNumber ? <div className="md:w-28 w-16"></div> : <></>}

      <ReactPaginate
        pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center border text-sm font-medium"
        activeClassName="z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
        previousLinkClassName="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        nextLinkClassName="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        containerClassName="w-full relative z-0 inline-flex justify-center rounded-md -space-x-px mt-4"
        breakLinkClassName="px-4 py-2 z-0 "
        breakClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center border text-sm font-medium"
        breakLabel="..."
        nextLabel=" >"
        onPageChange={onPageChange}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel="< "
        renderOnZeroPageCount={undefined}
        pageLinkClassName="px-4 py-2 z-0"
        forcePage={currentPage}
      />
      {resultsNumber && resultsNumber > 0 ? (
        <div className="md:w-28 w-16 my-auto align-middle">
          {t('search.results') + ': ' + resultsNumber}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Pagination;
