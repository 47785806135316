import { z } from 'zod';
import { UserDtoSchema } from './UserDto';
import { DeliveryNotificationDtoSchema } from './common/DeliveryNotificationDto';
import { PreOrderDtoSchema } from './common/PreOrderDto';
import { BasicUserSchema } from './BasicUserDto';

export const PreOrderUserDtoSchema = z.object({
  preOrder: PreOrderDtoSchema,
  user: BasicUserSchema,
  deliveryNotification: DeliveryNotificationDtoSchema.nullable(),
});

export const PreOrdersDtoSchema = z.object({
  preOrderUsers: z.array(PreOrderUserDtoSchema),
  availablePages: z.number(),
  count: z.number(),
});

export type PreOrdersDto = z.infer<typeof PreOrdersDtoSchema>;
