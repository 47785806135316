import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import NotyfContext from '~/context/NotyfContext';
import { options } from '~/features/modal/ConfirmAlert';
import { useHasGroup } from '~/hooks/useHasGroup';
import type { TUser } from '../types';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import type { UpdateType } from '../useUsers';

type DeactivateUserMutationVariables = { username: string };
type ActivateUserMutationVariables = { username: string };

const DeactivateActivateUserCell = ({
  enabled,
  groups,
  username,
  email,
  updateUser,
}: {
  enabled: boolean;
  groups: TUser['Groups'];
  username: string;
  email?: string;
  updateUser: (username: string, updateType: UpdateType) => void;
}) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();

  const deactivateUserMutation = useMutation<
    DeactivateUserMutationVariables,
    Error,
    DeactivateUserMutationVariables
  >(
    async ({ username }) => {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await fetch(
        `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/users/deactivate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username }),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return { username };
    },
    {
      onSuccess: ({ username }) => {
        updateUser(username, { type: 'deactivate' });
        notyf.success(t('users.successfully-deactivated-user'));
      },
      onError: (error) => {
        notyf.error(error.message);
      },
    }
  );

  const activateUserMutation = useMutation<
    ActivateUserMutationVariables,
    Error,
    ActivateUserMutationVariables
  >(
    async ({ username }) => {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const response = await fetch(
        `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/users/activate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username }),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return { username };
    },
    {
      onSuccess: ({ username }) => {
        updateUser(username, { type: 'activate' });
        notyf.success(t('users.successfully-activated-user'));
      },
      onError: (error) => {
        notyf.error(error.message);
      },
    }
  );

  const group =
    groups.length > 0 &&
    groups.reduce(function (prev, current) {
      return prev.Precedence > current.Precedence ? prev : current;
    });
  const isSuperuser = useHasGroup('Superuser');

  const setupDeactivateAlert = (username: string, email?: string) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title =
      t('users.are-you-sure-you-want-to-deactivate-user') + ' ' + email + '?';
    newOptions.buttons = [
      {
        className: '!bg-red-500',
        label: t('common.yes'),
        onClick: () => {
          deactivateUserMutation.mutate({ username });
        },
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };

  const setupActivateAlert = (username: string, email: string | undefined) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title =
      t('users.are-you-sure-you-want-to-activate-user') + ' ' + email + '?';
    newOptions.buttons = [
      {
        className: '!bg-red-500',
        label: t('common.yes'),
        onClick: () => {
          activateUserMutation.mutate({ username });
        },
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };
  const hasDeactivateActivatePermission = useHasPermissions(
    'DEACTIVATE_ACTIVATE_USER'
  );
  const cannotEditSuperuser =
    group && group.GroupName === 'Superuser' && !isSuperuser;
  if (hasDeactivateActivatePermission && enabled && !cannotEditSuperuser) {
    return (
      <td className="px-6 py-4">
        <span
          className="hover:text-red-600 text-red-500 cursor-pointer"
          onClick={() => confirmAlert(setupDeactivateAlert(username, email))}
        >
          {t('users.deactivate')}
        </span>
      </td>
    );
  }
  if (hasDeactivateActivatePermission && !enabled && !cannotEditSuperuser) {
    return (
      <td className="px-6 py-4">
        <span
          className="hover:text-green-600 text-green-500 cursor-pointer"
          onClick={() => confirmAlert(setupActivateAlert(username, email))}
        >
          {t('users.activate')}
        </span>
      </td>
    );
  }
  if (enabled) {
    return (
      <td className="px-6 py-4">
        <span className="text-gray-500 cursor-not-allowed">
          {t('users.deactivate')}
        </span>
      </td>
    );
  }
  if (!enabled) {
    return (
      <td className="px-6 py-4">
        <span className="text-gray-500 cursor-not-allowed">
          {t('users.activate')}
        </span>
      </td>
    );
  }
  return (
    <td className="px-6 py-4">
      <span className="">-</span>
    </td>
  );
};
export default DeactivateActivateUserCell;
