import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { Container } from '../../components/Layout';
import { useHasPermissions } from '../../hooks/useHasPermissions';
import RecordsPerPageSelect from '../search/components/RecordsPerPageSelect';
import BulletinsTable from './BulletinsTable';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './customEditorToolbar.css';

const Bulletins: React.FC = () => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const hasPermissions = useHasPermissions('MANAGE_BULLETINS');
  return (
    <div>
      <Header className="pb-2">{t('bulletins.bulletins')}</Header>

      <Container>
        <div className="flex justify-between">
          {hasPermissions ? (
            <Link to="create" className="mt-auto mb-4">
              <Button className="">{t('bulletins.publish-bulletin')}</Button>
            </Link>
          ) : (
            <div></div>
          )}

          <div className="mb-4">
            <RecordsPerPageSelect
              type="sm"
              setItemsPerPage={setItemsPerPage}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </div>

        <BulletinsTable
          newest={false}
          edit={true}
          itemsPerPage={itemsPerPage}
        />
      </Container>
    </div>
  );
};
export default Bulletins;
