import { z } from 'zod';

export const BulletinDtoSchema = z.object({
  bulletin: z.object({
    id: z.object({
      value: z.number(),
    }),
    subject: z.string(),
    publishedBy: z.string(),
    publishedAt: z.string(),
    content: z.string(),
    modifiedAt: z.string(),
  }),
});

export type BulletinDto = z.infer<typeof BulletinDtoSchema>;
