import { Navigate } from 'react-router-dom';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import type { PermissionsDto } from '~/lib/dtos/PermissionsDto';

interface ProtectedRouteProps {
  children: React.ReactElement;
  permissions: PermissionsDto;
  fallback: string;
}

const ProtectedRoute = ({
  permissions,
  fallback,
  children,
}: ProtectedRouteProps) => {
  const hasPermission = useHasPermissions(permissions);

  if (!hasPermission) {
    return <Navigate to={fallback} replace />;
  }

  return children;
};

export default ProtectedRoute;
