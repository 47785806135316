import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Header from '../../components/Header';
import { useCurrentUserContext } from '../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import { Container } from '../../components/Layout';
import useAuth from '../../hooks/useAuth';
import { BarLoader } from 'react-spinners';
import { useTranslateUserLevels } from '~/hooks/useTranslateUserLevels';
import type { TUserGroup } from '../maintenance/users/types';
import { differenceInDays } from 'date-fns';
import { parseGMT } from '~/lib/utils/formatDates/formatDates';

export const MapUserLevels = ({ roles }: { roles: TUserGroup[] }) => {
  const { translateUserLevel } = useTranslateUserLevels();

  if (roles.length === 0) {
    return null;
  }

  const translatedRoles = roles.map((role) => {
    if (!role) {
      return null;
    }

    return `${translateUserLevel(role)}`;
  });

  return <span>{translatedRoles.join(', ')}</span>;
};

const DaysTillReset = ({
  dateDiffInDays,
}: {
  dateDiffInDays: number | null;
}) => {
  const { t } = useTranslation();
  if (dateDiffInDays === null) return null;
  if (dateDiffInDays < 1) {
    return <span>{t('users.today')}</span>;
  }
  return (
    <span>
      {dateDiffInDays}
      {dateDiffInDays > 1 && ' ' + t('users.days')}
      {dateDiffInDays === 1 && ' ' + t('users.day')}
    </span>
  );
};

export const NextResetIn = ({
  lastResetPassword,
  isDeactivated,
  isInactive,
  status,
}: {
  lastResetPassword?: Date | null;
  isDeactivated?: boolean;
  isInactive?: boolean | null;
  status?: string;
}) => {
  if (isDeactivated) {
    return null;
  }

  if (isInactive && status === 'FORCE_CHANGE_PASSWORD') {
    return null;
  }

  if (!lastResetPassword) {
    return null;
  }
  const now = new Date();
  const nextResetDate = now.setMonth(now.getMonth() - 1);
  return (
    <DaysTillReset
      dateDiffInDays={differenceInDays(lastResetPassword, nextResetDate)}
    />
  );
};
const Profile = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { t } = useTranslation();

  const { currentUser, signOut } = useCurrentUserContext();

  if (!currentUser)
    return (
      <Container>
        <div className="flex flex-col justify-center text-center">
          {t('common.loading-permissions')}
          <div className="flex justify-center w-full py-2 mx-auto">
            <BarLoader color="#7f7f7f" />
          </div>
        </div>
      </Container>
    );

  return (
    <div>
      <Header className="pb-2">{t('profile.title')}</Header>

      <Container>
        <div className="flex justify-between">
          <div className=" my-auto">
            <div>
              {t('users.email')}:
              <span className="ml-1 font-semibold">
                {user?.attributes?.email}
              </span>
            </div>
            <div>
              <div>
                {t('users.user-level')}:
                <span className="ml-1 font-semibold">
                  <MapUserLevels roles={currentUser.groups} />
                </span>
              </div>
              <div>
                {t('users.next-reset-in')}:
                <span className="ml-1 font-semibold">
                  <NextResetIn
                    lastResetPassword={
                      currentUser?.lastResetPassword
                        ? parseGMT(currentUser?.lastResetPassword)
                        : undefined
                    }
                  />
                </span>
              </div>
            </div>
          </div>
          <div className=" mb-auto">
            <button
              onClick={() => {
                signOut();
              }}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {t('profile.sign-out')}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Profile;
