import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import BulletinsTable from '../bulletins/BulletinsTable';
import SelectLanguage from '../language/LanguageSelect';
import HorizontalLine from '../../components/HorizontalLine';
import Button from '../../components/Button';
import BasicSearch from '../search/basic/BasicSearch';
import Header from '../../components/Header';
import Description from '../../components/Description';
import { Container } from '../../components/Layout';

const Main: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header>{t('main.header')}</Header>
      <Description>
        <p>
          <span>{t('main.description')}</span>
        </p>
      </Description>
      <Container>
        <BasicSearch />
        <HorizontalLine />
        <div className="py-2 text-lg font-semibold">
          {t('main.newest-bulletins')}
        </div>
        <BulletinsTable newest={true} edit={false} itemsPerPage={3} />
        <div className="flex justify-end pt-2">
          <Link to="bulletins">
            <Button>{t('main.go-to-bulletins')}</Button>
          </Link>
        </div>
        <HorizontalLine />
        <SelectLanguage />
      </Container>
    </>
  );
};
export default Main;
