import { Table, Tooltip, Flowbite } from 'flowbite-react';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import type { ReactNode } from 'react';
import type { Nullable } from '~/features/search/types';

type JustifyType = 'center' | 'left';

const justifyTextMap: Record<JustifyType, string> = {
  center: 'text-center',
  left: 'text-left',
};

type Props = {
  content: Nullable<ReactNode>;
  justifyText?: JustifyType;
  color?: Nullable<string>;
  helperText?: Nullable<string>;
  helperTextPlacement?: 'left' | 'right' | 'top' | 'bottom';
};
// https://github.com/themesberg/flowbite-react/discussions/939
const customTheme: CustomFlowbiteTheme = {
  tooltip: {
    target: '', // To avoid changing children size as default theme is using w-fit
  },
};

export const Cell = ({
  content,
  color,
  justifyText = 'center',
  helperText,
  helperTextPlacement,
}: Props) => {
  const justifyTextMapped = justifyTextMap[justifyText];
  if (helperText) {
    return (
      <Flowbite theme={{ theme: customTheme }}>
        <Table.Cell
          className={`text-center px-4 ${justifyTextMapped} border border-gray-100 ${
            color ?? ''
          }`}
        >
          <Tooltip
            content={helperText}
            placement={helperTextPlacement ?? 'top'}
          >
            {content ?? '-'}
          </Tooltip>
        </Table.Cell>
      </Flowbite>
    );
  }

  return (
    <Table.Cell
      className={`px-4 ${justifyTextMapped} border border-gray-100 ${
        color ?? ''
      }`}
    >
      {content ?? '-'}
    </Table.Cell>
  );
};
