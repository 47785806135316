import type { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';

export interface UseAuthHookResponse {
  currentUser: CognitoUser | null;
  signOut: () => void;
  loading: boolean;
}

const getCurrentUser = async (): Promise<CognitoUser | null> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (e) {
    return null;
  }
};

const useAuth = (): UseAuthHookResponse => {
  const [currentUser, setCurrentUser] = useState<CognitoUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const updateUser = async () => {
      setCurrentUser(await getCurrentUser());
      setLoading(false);
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser);
  }, []);

  const signOut = () => {
    Auth.signOut();
    setCurrentUser(null);
  };

  return { currentUser, signOut, loading };
};

export default useAuth;

export { getCurrentUser };
