import { Spinner, Table } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import RecordsPerPageSelect from '../components/RecordsPerPageSelect';
import { goToTop, mapOperator } from '../utils';
import { useDecodeSearchParams } from '~/hooks/useDecodeSearchParams';
import { useTicketsV2Query } from '~/lib/hooks/useTicketsV2Query';
import type { TicketsV2Dto } from '~/lib/dtos/TicketsV2Dto';
import {
  GoToCell,
  PhoneNumberCell,
  TicketValidityCell,
} from '../components/TicketCells';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';
import { useTicketsV2CountQuery } from '~/lib/hooks/useTicketsV2CountQuery';
import Pagination from '../components/Pagination';
import { EbkDsbStatusCell } from '~/components/Cells/EbkDsbStatusCell';
import { PaymentStatusCell } from '~/components/Cells/PaymentStatusCell';
import { DeliveryCell } from '~/components/Cells/DeliveryCell';
import { TicketTypeCell } from '~/components/Cells/TicketTypeCell';
import { TicketRefundCell } from '~/components/Cells/TicketRefundCell';
import { differenceInSeconds, parseISO } from 'date-fns';
import { buildParams } from '~/lib/utils/buildParams/buildParams';
import { defaultValues } from '~/hooks/useSearch';
import { useTranslateColumnNames } from '~/hooks/useTranslateColumnNames';
import type { ColumnsVisibilityDto } from '~/lib/dtos/common/ColumnsVisibilityDto';
import ColumnVisibilityControl from '../components/ColumnVisibilityControl';

const initialColumnVisibility: ColumnsVisibilityDto = {
  DETAILS: true,
  CREATED: true,
  OPERATOR: true,
  PHONE_NUMBER: true,
  DELIVERY_STATUS: true,
  EBK_RESPONSE: true,
  DSB_RESPONSE: true,
  VALID_FROM: true,
  VALID_TO: true,
  TYPE: true,
  REFUND: true,
  PAYMENT_STATUS: true,
};
const TicketsTable = () => {
  const [params, originalParams] = useDecodeSearchParams();

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(
    params.ticketsPage
      ? Number(params.ticketsPage)
      : Number(defaultValues.ticketsPage)
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    params.ticketsResultsPerPage
      ? Number(params.ticketsResultsPerPage)
      : Number(defaultValues.ticketsResultsPerPage)
  );

  const navigate = useNavigate();

  const ticketsCount = useTicketsV2CountQuery({
    searchParams: {
      distance: params.zones ?? [],
      endDate: params.endDate!,
      operator: params.operators ?? [],
      paymentStatus: params.paymentStatus ?? 'NONE',
      startDate: params.startDate!,
      ticketStatus: params.ticketStatus ?? 'NONE',
      ticketType: params.ticketTypes ?? [],
      limit: String(itemsPerPage),
      phone: params.phoneNumber,
    },
    options: {
      enabled: Boolean(params.endDate && params.startDate),
    },
  });

  const tickets = useTicketsV2Query({
    searchParams: {
      distance: params.zones ?? [],
      endDate: params.endDate!,
      operator: params.operators ?? [],
      paymentStatus: params.paymentStatus ?? 'NONE',
      startDate: params.startDate!,
      ticketStatus: params.ticketStatus ?? 'NONE',
      ticketType: params.ticketTypes ?? [],
      limit: String(itemsPerPage),
      page: String(currentPage),
      phone: params.phoneNumber,
    },
    options: {
      enabled: Boolean(params.endDate && params.startDate),
    },
  });

  useEffect(() => {
    if (!ticketsCount.data?.availablePages) {
      return;
    }

    const { availablePages } = ticketsCount.data;

    if (availablePages <= currentPage - 1) {
      setCurrentPage(availablePages);
      return;
    }
  }, [ticketsCount.data?.availablePages]);

  useEffect(() => {
    const updatedParams = buildParams({
      ...originalParams,
      ticketsPage: currentPage.toString(),
      ticketsResultsPerPage: itemsPerPage.toString(),
    });

    navigate('/orders?' + updatedParams);
  }, [currentPage, itemsPerPage]);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected + 1);
  };

  const [columnVisibility, setColumnVisibility] =
    useState<ColumnsVisibilityDto>(() => {
      const savedVisibility = localStorage.getItem(
        'TicketsTableColumnVisibility'
      );
      return savedVisibility
        ? JSON.parse(savedVisibility)
        : initialColumnVisibility;
    });

  const handleColumnVisibilityChange =
    (column: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedColumnVisibility = {
        ...columnVisibility,
        [column]: e.target.checked,
      };
      setColumnVisibility(updatedColumnVisibility);

      localStorage.setItem(
        'TicketsTableColumnVisibility',
        JSON.stringify(updatedColumnVisibility)
      );
    };

  const { translateColumnName } = useTranslateColumnNames();

  return (
    <div className="flex flex-col justify-center px-2 pb-4 mt-4 overflow-x-auto">
      <div className="flex justify-between">
        <Header className="mt-auto mb-2">{t('search.tickets')}</Header>
        <div className="flex">
          <div className="flex flex-col mt-auto">
            <ColumnVisibilityControl
              columnVisibility={columnVisibility}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
              translateColumnName={translateColumnName}
              prefix="tickets"
              excludedColumns={['DETAILS', 'PHONE_NUMBER']}
            />
          </div>
          <RecordsPerPageSelect
            type="lg"
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
      </div>

      <Table className="border-spacing-0 mt-2 border border-separate border-gray-300 rounded-md shadow-lg">
        <TableBody
          isError={tickets.isError}
          isLoading={tickets.isLoading}
          tickets={tickets.data?.tickets}
          columnVisibility={columnVisibility}
        />
      </Table>
      {ticketsCount.isLoading ? (
        <div className="w-full mt-2 text-center">
          <Spinner />
        </div>
      ) : (
        <Pagination
          resultsNumber={ticketsCount.data?.count}
          onPageChange={handlePageClick}
          pageCount={ticketsCount.data?.availablePages ?? 0}
          currentPage={currentPage - 1}
        />
      )}
      {itemsPerPage > 10 && (
        <div className="flex justify-between">
          <Button type="gray" onClick={() => navigate(-1)}>
            {t('common.back')}
          </Button>
          <Button type="gray" onClick={goToTop}>
            {t('common.go-to-top')}
          </Button>
        </div>
      )}
    </div>
  );
};
type TableBodyProps = {
  isLoading: boolean;
  isError: boolean;
  tickets?: TicketsV2Dto['tickets'];
  columnVisibility: ColumnsVisibilityDto;
};
const TableBody = ({
  tickets,
  isLoading,
  isError,
  columnVisibility,
}: TableBodyProps) => {
  const { t } = useTranslation();
  const { translateColumnName } = useTranslateColumnNames();
  if (isError) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>{t('status.error')}</Table.HeadCell>
        </Table.Head>
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>{t('common.loading')}</Table.HeadCell>
        </Table.Head>
      </div>
    );
  }
  if (tickets?.length === 0) {
    return (
      <div className="flex justify-center">
        <Table.Head>
          <Table.HeadCell>
            {t('common.no-orders-for-this-search-criteria')}
          </Table.HeadCell>
        </Table.Head>
      </div>
    );
  }

  return (
    <>
      <Table.Head className="bg-gray-100">
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {translateColumnName('DETAILS')}
          </div>
        </Table.HeadCell>
        {columnVisibility.CREATED && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('CREATED')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.OPERATOR && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('OPERATOR')}
            </div>
          </Table.HeadCell>
        )}
        <Table.HeadCell>
          <div className="flex justify-center my-auto text-center align-middle">
            {translateColumnName('PHONE_NUMBER')}
          </div>
        </Table.HeadCell>
        {columnVisibility.DELIVERY_STATUS && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('DELIVERY_STATUS')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.EBK_RESPONSE && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('EBK_RESPONSE')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.DSB_RESPONSE && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('DSB_RESPONSE')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.VALID_FROM && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('VALID_FROM')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.VALID_TO && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('VALID_TO')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.TYPE && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('TYPE')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.REFUND && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('REFUND')}
            </div>
          </Table.HeadCell>
        )}
        {columnVisibility.PAYMENT_STATUS && (
          <Table.HeadCell>
            <div className="flex justify-center my-auto text-center align-middle">
              {translateColumnName('PAYMENT_STATUS')}
            </div>
          </Table.HeadCell>
        )}
      </Table.Head>
      <Table.Body className="divide-y">
        {tickets?.map(
          ({
            createdAt,
            deliveredAt,
            deliveryNotificationStatus,
            distance,
            paymentStatus,
            phoneNumber,
            resent,
            ticketId,
            ticketType,
            userId,
            validFrom,
            validTo,
            sendToFinancialAuthorityStatus,
            sendToTicketInspectionStatus,
            sentToDsbAt,
            sentToEbkAt,
            operator,
          }) => {
            return (
              <Table.Row
                key={ticketId.value}
                className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
              >
                <GoToCell id={String(ticketId.value)} type="ticket" />
                {columnVisibility.CREATED && (
                  <Table.Cell className="text-center border-2 border-gray-100">
                    <DateBreakHandler date={createdAt} />
                  </Table.Cell>
                )}
                {columnVisibility.OPERATOR && (
                  <Table.Cell className="text-center border-2 border-gray-100">
                    {mapOperator(operator)}
                  </Table.Cell>
                )}
                <PhoneNumberCell
                  className="whitespace-nowrap text-center border-2 border-gray-100"
                  phoneNumber={phoneNumber.value}
                  customerId={userId.value}
                />
                {columnVisibility.DELIVERY_STATUS && (
                  <DeliveryCell
                    fromDate={createdAt}
                    toDate={deliveredAt}
                    isResent={resent}
                    status={deliveryNotificationStatus}
                  />
                )}
                {columnVisibility.EBK_RESPONSE && (
                  <EbkDsbStatusCell
                    status={sendToTicketInspectionStatus}
                    delay={differenceInSeconds(
                      parseISO(sentToEbkAt ?? createdAt),
                      parseISO(createdAt)
                    )}
                  />
                )}
                {columnVisibility.DSB_RESPONSE && (
                  <EbkDsbStatusCell
                    status={sendToFinancialAuthorityStatus}
                    delay={differenceInSeconds(
                      parseISO(sentToDsbAt ?? createdAt),
                      parseISO(createdAt)
                    )}
                  />
                )}
                {columnVisibility.VALID_FROM && (
                  <Table.Cell className="text-center border-2 border-gray-100">
                    <DateBreakHandler date={validFrom} />
                  </Table.Cell>
                )}
                {columnVisibility.VALID_TO && (
                  <TicketValidityCell
                    className="text-center border-2 border-gray-100"
                    ticketValidEnd={validTo}
                    isRefunded={paymentStatus === 'PAYMENT_REFUNDED'}
                  />
                )}
                {columnVisibility.TYPE && (
                  <TicketTypeCell
                    type={ticketType}
                    distance={distance.value}
                    className="text-center border-2 border-gray-100"
                  />
                )}
                {columnVisibility.REFUND && (
                  <TicketRefundCell
                    className="text-center border-2 border-gray-100"
                    refundStatus={paymentStatus}
                    ticketId={String(ticketId.value)}
                  />
                )}
                {columnVisibility.PAYMENT_STATUS && (
                  <PaymentStatusCell paymentStatus={paymentStatus} />
                )}
              </Table.Row>
            );
          }
        )}
      </Table.Body>
    </>
  );
};
export default TicketsTable;
