import { useTranslation } from 'react-i18next';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';

type TicketStatus = SearchSchemaType['ticketStatus'];

export const useTicketStatuses = () => {
  const { t } = useTranslation();

  const ticketStatusMap: Record<Exclude<TicketStatus, 'NONE'>, string> = {
    VALID: t('search.valid'),
    EXPIRED: t('search.expired'),
    DELIVERY_FAILED: t('search.delivery-failed'),
  };

  const translateTicketStatuses = (
    ticketStatus: Exclude<TicketStatus, 'NONE'>
  ) => {
    if (!ticketStatus) {
      return '';
    }

    return ticketStatusMap[ticketStatus];
  };

  const translatedOptions = Object.entries(ticketStatusMap).map(
    ([key, value]) => ({ label: value, value: key })
  ) as { label: string; value: Exclude<TicketStatus, 'NONE'> }[];

  return { translateTicketStatuses, translatedOptions };
};
