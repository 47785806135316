import { Credentials } from '@aws-amplify/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router';
import { GroupSelect } from './GroupSelect';
import { Table } from 'flowbite-react';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { BarLoader } from 'react-spinners';
import { useHasGroup } from '../../../hooks/useHasGroup';
import { goToTop, snakeToTitle } from 'features/search/utils';
import { NextResetIn } from '~/features/profile/Profile';
import { useTranslateUserLevels } from '~/hooks/useTranslateUserLevels';
import { Link } from 'react-router-dom';
import type { UpdateType } from './useUsers';
import ButtonWithLoading from '~/components/ButtonWithLoading';
import ChangeUserFullNameCell from './Cells/ChangeUserFullNameCell';
import DeactivateActivateUserCell from './Cells/DeactivateActivateUserCell';
import DeleteUserCell from './Cells/DeleteUserCell';
import UserStatusCell from './Cells/UserStatusCell';
import { formatDate, parseGMT } from '~/lib/utils/formatDates/formatDates';
import type { TUser } from './types';
import type { TOrder } from '~/components/SortIndicator';
import SortIndicator from '~/components/SortIndicator';

const INVALID_CRED = { accessKeyId: '', secretAccessKey: '' };

export const credentialsProvider = async () => {
  try {
    const credentials = await Credentials.get();
    if (!credentials) return INVALID_CRED;
    const cred = Credentials.shear(credentials);
    return cred;
  } catch (error) {
    return INVALID_CRED;
  }
};

interface UserAccountsTableProps {
  company: string;
  itemsPerPage?: number;
  showAll?: boolean;
  filteredData: TUser[];
  error: Error | null;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isLoading: boolean;
  isFetching: boolean;
  updateUser: (username: string, updateType: UpdateType) => void;
  incrementPage: () => void;
  decrementPage: () => void;
  currentPage: number;
  displayPage: number;
  lastPage: number;
}
type TField =
  | 'fullName'
  | 'email'
  | 'dateAdded'
  | 'lastLoggedIn'
  | 'nextResetIn';

export type TSortPreference = {
  field: TField;
  order: TOrder;
};

const UserAccountsTable = ({
  filteredData,
  hasNextPage,
  isLoading,
  isFetching,
  updateUser,
  incrementPage,
  currentPage,
}: UserAccountsTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [sortPreferences, setSortPreferences] = useState<TSortPreference>({
    field: 'fullName',
    order: 'desc',
  });

  const [sortedData, setSortedData] = useState<TUser[] | null>(null);

  useEffect(() => {
    if (filteredData.length < 1) return;

    let sortedUsers: TUser[] = [];

    const { field, order } = sortPreferences;

    sortedUsers = [...filteredData].sort((a, b) => {
      let aValue, bValue;

      switch (field) {
        case 'fullName':
          aValue =
            a.Attributes.find(
              (attr) => attr.Name === 'name'
            )?.Value.toLowerCase() || '';
          bValue =
            b.Attributes.find(
              (attr) => attr.Name === 'name'
            )?.Value.toLowerCase() || '';
          break;
        case 'email':
          aValue =
            a.Attributes.find(
              (attr) => attr.Name === field
            )?.Value.toLowerCase() || '';
          bValue =
            b.Attributes.find(
              (attr) => attr.Name === field
            )?.Value.toLowerCase() || '';
          break;

        case 'dateAdded':
          aValue = a.UserCreateDate;
          bValue = b.UserCreateDate;
          break;

        case 'lastLoggedIn':
          aValue =
            a.Attributes.find((attr) => attr.Name === `custom:lastLoggedIn`)
              ?.Value || '';
          bValue =
            b.Attributes.find((attr) => attr.Name === `custom:lastLoggedIn`)
              ?.Value || '';
          break;
        case 'nextResetIn':
          aValue =
            a.Attributes.find(
              (attr) => attr.Name === `custom:lastResetPassword`
            )?.Value || '';
          bValue =
            b.Attributes.find(
              (attr) => attr.Name === `custom:lastResetPassword`
            )?.Value || '';
          break;

        default:
          return 0;
      }

      return order === 'desc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    setSortedData(sortedUsers);
  }, [filteredData, sortPreferences]);

  const handleIncrementPage = useCallback(() => {
    if (hasNextPage) {
      incrementPage();
    }
  }, [hasNextPage, incrementPage]);

  const hasChangeGroupPermission = useHasPermissions('CHANGE_GROUP');
  const hasDeactivateActivatePermission = useHasPermissions(
    'DEACTIVATE_ACTIVATE_USER'
  );
  const hasResendPasswordPermission = useHasPermissions('RESEND_PASSWORD_USER');
  const hasEditFullNamePermission = useHasPermissions('EDIT_FULLNAME_USER');
  const hasDeletePermission = useHasGroup('Superuser');
  const { translateUserLevel } = useTranslateUserLevels();

  const createSortHandler = (field: TField) => () => {
    setSortPreferences((currentSortPreferences) => ({
      field,
      order:
        currentSortPreferences.field === field
          ? currentSortPreferences.order === 'asc'
            ? 'desc'
            : 'asc'
          : 'desc',
    }));
  };

  if (isLoading || !sortedData) {
    return (
      <div className="flex justify-center w-full py-4 text-xl text-center">
        <BarLoader color="#7f7f7f" />
      </div>
    );
  }

  if (sortedData.length === 0) {
    return (
      <div className="flex justify-center w-full py-2 text-center">
        {t('users.no-users-found')}
      </div>
    );
  }

  return (
    <div className=" w-full px-2 py-2">
      <table className="dark:text-gray-400 w-full text-sm text-center text-gray-500 border border-separate border-gray-300 rounded-md">
        <thead className="bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-xs text-gray-700 uppercase">
          <tr className="border-b border-gray-800 select-none">
            <Table.HeadCell>
              <div
                className="gap flex justify-center my-auto text-center align-middle cursor-pointer"
                onClick={createSortHandler('fullName')}
              >
                <div className=" my-auto align-baseline">
                  {t('users.full-name')}
                </div>
                <SortIndicator
                  sortOrder={
                    sortPreferences.field === 'fullName'
                      ? sortPreferences.order
                      : null
                  }
                />
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div
                className="gap flex justify-center my-auto text-center align-middle cursor-pointer"
                onClick={createSortHandler('email')}
              >
                <div className=" my-auto align-baseline">
                  {t('users.email')}
                </div>
                <SortIndicator
                  sortOrder={
                    sortPreferences.field === 'email'
                      ? sortPreferences.order
                      : null
                  }
                />
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div
                className="gap flex justify-center my-auto text-center align-middle cursor-pointer"
                onClick={createSortHandler('dateAdded')}
              >
                <div className=" my-auto align-baseline">
                  {t('users.date-added')}
                </div>
                <SortIndicator
                  sortOrder={
                    sortPreferences.field === 'dateAdded'
                      ? sortPreferences.order
                      : null
                  }
                />
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div
                className="gap flex justify-center my-auto text-center align-middle cursor-pointer"
                onClick={createSortHandler('lastLoggedIn')}
              >
                <div className=" my-auto align-baseline">
                  {t('users.last-logged-in')}
                </div>
                <SortIndicator
                  sortOrder={
                    sortPreferences.field === 'lastLoggedIn'
                      ? sortPreferences.order
                      : null
                  }
                />
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div
                className="gap flex justify-center my-auto text-center align-middle cursor-pointer"
                onClick={createSortHandler('nextResetIn')}
              >
                <div className=" my-auto align-baseline">
                  {t('users.next-reset-in')}
                </div>
                <SortIndicator
                  sortOrder={
                    sortPreferences.field === 'nextResetIn'
                      ? sortPreferences.order
                      : null
                  }
                />
              </div>
            </Table.HeadCell>
            <Table.HeadCell>{t('users.company')}</Table.HeadCell>
            <Table.HeadCell>{t('users.user-level')}</Table.HeadCell>
            <Table.HeadCell>{t('users.status')}</Table.HeadCell>
            {hasDeactivateActivatePermission && (
              <Table.HeadCell>{t('users.deactivate-activate')}</Table.HeadCell>
            )}
            {hasDeletePermission && (
              <Table.HeadCell>{t('tables.delete')}</Table.HeadCell>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((user) => {
            const group =
              user.Groups && user.Groups.length > 0
                ? user.Groups.reduce(function (prev, current) {
                    return prev.Precedence > current.Precedence
                      ? prev
                      : current;
                  })
                : null;

            const lastLoggedInValue = user.Attributes?.find(
              (attribute) => attribute.Name === 'custom:lastLoggedIn'
            )?.Value;

            const lastLoggedInDate = lastLoggedInValue
              ? parseGMT(lastLoggedInValue)
              : null;

            const lastResetPasswordValue = user.Attributes?.find(
              (attribute) => attribute.Name === 'custom:lastResetPassword'
            )?.Value;

            const lastResetPasswordDate = lastResetPasswordValue
              ? parseGMT(lastResetPasswordValue)
              : null;

            const fullName = user.Attributes?.find(
              (attribute) => attribute.Name === 'name'
            )?.Value;

            const email = user.Attributes?.find(
              (attribute) => attribute.Name === 'email'
            )?.Value as string;

            const didntLoginAfterReset =
              lastResetPasswordDate !== null &&
              lastLoggedInDate !== null &&
              lastLoggedInDate <= lastResetPasswordDate;
            return (
              <tr
                className="dark:bg-gray-800 dark:border-gray-700 items-center text-gray-900 bg-white border-b"
                key={user.Username}
              >
                {hasEditFullNamePermission ? (
                  <ChangeUserFullNameCell
                    fullName={fullName || email || ''}
                    groups={user.Groups}
                    updateUser={updateUser}
                    username={user.Username}
                    email={email}
                  />
                ) : (
                  <td className="px-6 py-4">
                    <span className="my-auto">
                      {fullName ? fullName : email}
                    </span>
                  </td>
                )}
                <th
                  scope="row"
                  className="whitespace-nowrap dark:text-white px-6 py-4 font-medium text-gray-900"
                >
                  <Link to={`/user/${encodeURIComponent(user.Username)}`}>
                    <span className="text-blue-700">{email}</span>
                  </Link>
                </th>
                <td className="px-6 py-4">{formatDate(user.UserCreateDate)}</td>
                <td className="px-6 py-4">
                  {lastLoggedInValue && formatDate(lastLoggedInValue)}
                </td>

                <td className="px-6 py-4">
                  <NextResetIn
                    lastResetPassword={lastResetPasswordDate}
                    isDeactivated={!user.Enabled}
                    isInactive={didntLoginAfterReset}
                    status={user.UserStatus}
                  />
                </td>

                <td className="px-6 py-4">
                  {snakeToTitle(
                    user.Attributes?.find(
                      (attribute) => attribute.Name === 'custom:company'
                    )?.Value
                  )}
                </td>
                <td className="px-6 py-4">
                  {hasChangeGroupPermission ? (
                    <GroupSelect
                      username={user.Username}
                      propsGroups={user.Groups}
                    />
                  ) : (
                    group && (
                      <span className="">
                        {translateUserLevel(group.GroupName)}
                      </span>
                    )
                  )}
                </td>

                <UserStatusCell
                  canResend={hasResendPasswordPermission}
                  updateUser={updateUser}
                  user={user}
                />

                {hasDeactivateActivatePermission && (
                  <DeactivateActivateUserCell
                    groups={user.Groups}
                    updateUser={updateUser}
                    enabled={user.Enabled}
                    username={user.Username}
                    email={email}
                  />
                )}
                {hasDeletePermission && (
                  <DeleteUserCell
                    updateUser={updateUser}
                    username={user.Username}
                    email={email}
                    enabled={user.Enabled}
                  />
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="whitespace-nowrap flex justify-center my-4">
        <ButtonWithLoading
          onClick={handleIncrementPage}
          disabled={!hasNextPage}
          text={t('common.more')}
          loading={isLoading || isFetching}
        />
      </div>

      <div className="flex justify-between">
        <Button type="gray" onClick={() => navigate(-1)}>
          {t('common.back')}
        </Button>
        <Button type="gray" onClick={goToTop}>
          {t('common.go-to-top')}
        </Button>
      </div>
    </div>
  );
};
export default UserAccountsTable;
