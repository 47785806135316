import { useTranslation } from 'react-i18next';
import type { Nullable } from '~/features/search/types';
import { Cell } from './Cell';
import type { OrderStatusDto } from '~/lib/dtos/common/OrderStatusDto';
import type { TicketPaymentStatusDto } from '~/lib/dtos/common/TicketPaymentStatusDto';

type Props = {
  orderStatus: Nullable<OrderStatusDto>;
  paymentStatus: Nullable<TicketPaymentStatusDto>;
};

const completedStatuses: Nullable<TicketPaymentStatusDto>[] = [
  'PAYMENT_REFUNDED',
  'PAYMENT_COMPLETED',
];

export const OrderStatusCell = ({ orderStatus, paymentStatus }: Props) => {
  const orderStatusMapped = useOrderStatusMap({ orderStatus, paymentStatus });

  return <Cell content={orderStatusMapped} />;
};

const useOrderStatusMap = ({
  orderStatus,
  paymentStatus,
}: Parameters<typeof OrderStatusCell>[number]) => {
  const { t } = useTranslation();

  const orderStatusMap: Record<OrderStatusDto, string> = {
    WAITING_FOR_CUSTOMER_CONFIRMATION: t(
      'order-status.waiting-for-customer-confirmation'
    ),
    LACK_OF_CONFIRMATION: t('order-status.lack-of-confirmation'),
    CANCELLED: t('order-status.cancelled'),
    TERMINATED_WRONG_CONFIRMATION: t(
      'order-status.terminated-wrong-confirmation'
    ),
    AUTHORIZATION_FAILED: t('order-status.authorization-failed'),
    CLOSED_WITH_OPERATOR_TIMEOUT: t(
      'order-status.closed-with-operator-timeout'
    ),
    COMPLETED: t('order-status.payment-completed'),
    WAITING_FOR_AUTHORIZATION: t('order-status.waiting-for-authorization'),
    WAITING_FOR_CUSTOMER_CONFIRMATION_SECOND_ATTEMPT: t(
      'order-status.waiting-for-customer-confirmation-second-attempt'
    ),
  };

  if (completedStatuses.includes(paymentStatus)) {
    return t('status.payment-completed');
  }

  return orderStatus
    ? orderStatusMap[orderStatus]
    : t('common.invalid-order-error');
};
