import { useHasPermissions } from '../../../hooks/useHasPermissions';
import type { ChangeEvent } from 'react';
import { memo, useEffect, useState } from 'react';
import RecordsPerPageSelect from '../../search/components/RecordsPerPageSelect';
import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../../hooks/useCurrentUser';
import { BarLoader } from 'react-spinners';
import Button from '../../../components/Button';
import { Link } from 'react-router-dom';
import { useHasGroup } from '../../../hooks/useHasGroup';
import { useUsers } from './useUsers';
import UserAccountsTable from './UserAccountsTable';
import { LoadingCurrentUser } from '~/components/LoadingCurrentUser';
import type { MultiValue } from 'react-select';
import Select from 'react-select';
import type { TUser, TUserGroup } from './types';
import { useTranslateUserLevels } from '~/hooks/useTranslateUserLevels';
import { snakeToTitle } from '~/features/search/utils';
import { useTranslateUserStatus } from '~/hooks/useTranslateUserStatus';
import debounce from 'lodash.debounce';
import type { TUserStatus } from './utils';
import { getUserStatus } from './utils';

export const emptyFilterPreferences = {
  company: [],
  userLevel: [],
  status: [],
  email: '',
};

const Users = () => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUserContext();

  const canCreateUser = useHasPermissions('CREATE_USER');
  const isSuperuser = useHasGroup('Superuser');

  const [filteredData, setFilteredData] = useState<TUser[]>([]);

  const updateEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterPreferences((filterPreferences) => ({
      ...filterPreferences,
      email: e.target.value,
    }));
  };

  const debouncedEmailUpdate = debounce(updateEmail, 500);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    updateUser,
    incrementPage,
    decrementPage,
    currentPage,
    displayPage,
    lastPage,
  } = useUsers({
    company: currentUser?.company,
    itemsPerPage: undefined,
    showAll: isSuperuser,
    setUsers: setFilteredData,
  });

  const { translateUserLevel } = useTranslateUserLevels();
  const { translateUserStatus } = useTranslateUserStatus();

  const [filterPreferences, setFilterPreferences] = useState<{
    company: { value: string; label: string }[];
    userLevel: { value: string; label: string }[];
    status: { value: string; label: string }[];
    email: string;
  }>(emptyFilterPreferences);
  const filterPreferencesOptions = {
    company: ['DSB', 'METRO_SERVICE', 'MOVIA', 'DOT', 'SIILI', 'PLUSDIAL'].map(
      (company) => ({ value: company, label: snakeToTitle(company) })
    ),
    userLevel: (
      [
        'Superuser',
        'Administrator',
        'TeamLeader',
        'FraudManager',
        'BackofficeEmployee',
        'CustomerServiceRW',
        'CustomerServiceR',
      ] as TUserGroup[]
    ).map((userLevel) => ({
      value: userLevel,
      label: translateUserLevel(userLevel),
    })),
    status: (
      [
        'ACTIVE',
        'DEACTIVATED',
        'RESEND',
        'INACTIVE',
        'RESET_REQUIRED',
      ] as TUserStatus[]
    ).map((status) => ({
      value: status,
      label: translateUserStatus(status),
    })),
  };

  useEffect(() => {
    const usersArray = data?.pages.flatMap((page) => page.Users) || [];

    const filteredData = usersArray.filter((user) => {
      return (
        (filterPreferences.company.length === 0 ||
          filterPreferences.company.some(
            (company) =>
              company.value ===
              user.Attributes?.find(
                (attribute) => attribute.Name === 'custom:company'
              )?.Value
          )) &&
        (filterPreferences.userLevel.length === 0 ||
          filterPreferences.userLevel.some(
            (userLevel) => userLevel.value === user.Groups?.[0]?.GroupName
          )) &&
        (filterPreferences.status.length === 0 ||
          filterPreferences.status.some(
            (status) => status.value === getUserStatus(user)
          )) &&
        (filterPreferences.email.length === 0 ||
          user.Attributes?.find(
            (attribute) => attribute.Name === 'email'
          )?.Value.includes(filterPreferences.email))
      );
    });

    setFilteredData(filteredData);
  }, [filterPreferences, data]);

  if (!currentUser) return <LoadingCurrentUser currentUser={currentUser} />;

  return (
    <div className="flex flex-col w-full overflow-x-auto">
      <Header>{t('users.users')}</Header>
      <>
        <div className="flex justify-between py-4">
          <div className="xl:flex-row flex flex-col gap-2">
            {canCreateUser && (
              <div className="mt-auto mr-2">
                <Link to="add" className="">
                  <Button className="whitespace-nowrap">
                    {t('users.add-new-user')}
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <div className="xl:flex-row flex flex-col gap-2">
            <div className="xl:border-r pr-2 mt-auto border-gray-400">
              <input
                type="text"
                className="focus:ring-blue-500 focus:border-blue-500 dark:text-white block w-full text-sm text-gray-900 border border-gray-400 rounded-md"
                placeholder={t('users.email')}
                onChange={debouncedEmailUpdate}
              />
            </div>

            <div className="mt-auto">
              <Select
                menuPosition="fixed"
                placeholder={t('users.company')}
                isClearable
                className="h-min w-60"
                options={filterPreferencesOptions.company}
                onChange={(
                  selectedOption: MultiValue<{ value: string; label: string }>
                ) => {
                  setFilterPreferences({
                    ...filterPreferences,
                    company: selectedOption.map((option) => ({
                      value: option.value,
                      label: option.label,
                    })),
                  });
                }}
                isMulti={true}
              />
            </div>
            <div className="mt-auto">
              <Select
                placeholder={t('users.user-level')}
                isClearable
                className="h-min w-72"
                menuPosition="fixed"
                options={filterPreferencesOptions.userLevel}
                onChange={(
                  selectedOption: MultiValue<{ value: string; label: string }>
                ) => {
                  setFilterPreferences({
                    ...filterPreferences,
                    userLevel: selectedOption.map((option) => ({
                      value: option.value,
                      label: option.label,
                    })),
                  });
                }}
                isMulti={true}
              />
            </div>
            <div className="mt-auto">
              <Select
                menuPosition="fixed"
                placeholder={t('users.status')}
                isClearable
                className="h-min w-60"
                options={filterPreferencesOptions.status}
                onChange={(
                  selectedOption: MultiValue<{ value: string; label: string }>
                ) => {
                  setFilterPreferences({
                    ...filterPreferences,
                    status: selectedOption.map((option) => ({
                      value: option.value,
                      label: option.label,
                    })),
                  });
                }}
                isMulti={true}
              />
            </div>
          </div>
        </div>
        <UserAccountsTable
          showAll={isSuperuser}
          company={currentUser.company}
          filteredData={filteredData}
          error={error}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage ?? false}
          isLoading={isLoading}
          isFetching={isFetching}
          updateUser={updateUser}
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          currentPage={currentPage}
          displayPage={displayPage}
          lastPage={lastPage}
        />
      </>
    </div>
  );
};
export default memo(Users);
