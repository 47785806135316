import clsx from 'clsx';
import { Outlet } from 'react-router-dom';
import type { PropsWithChildren } from 'react';
import React from 'react';
import Navbar from './Navbar';
import type { ProjectInfo } from '../types';
export const LayoutNavbar = ({
  children,
  projectInfo,
}: {
  children?: JSX.Element | JSX.Element[];
  projectInfo: ProjectInfo | null;
}) => {
  return (
    <div className="w-full pb-4">
      <Navbar projectInfo={projectInfo} />
      <div className="4xl:mx-32 p-4 mx-0">
        {children ? children : <Outlet />}
      </div>
    </div>
  );
};

export const Container = ({
  type,
  children,
}: PropsWithChildren<{ type?: string }>) => {
  const baseClass = 'bg-gray-50 px-6 py-4 mx-auto rounded-md shadow-md';

  const classFromProps = (type: string | undefined, baseClass: string) => {
    if (type === 'full') {
      return clsx(baseClass, 'xl:mx-16 ');
    } else if (type === 'xxl') {
      return clsx(baseClass, '3xl:mx-64');
    } else {
      return clsx(baseClass, '');
    }
  };

  return (
    <div className={classFromProps(type, baseClass)}>
      {children ? children : <Outlet />}
    </div>
  );
};
