import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import type { BasicUserDto } from '../dtos/BasicUserDto';
import { BasicUserDtoSchema } from '../dtos/BasicUserDto';

export const useBasicUserQuery = (
  phoneNumber: string,
  options?: UseQueryOptions<BasicUserDto>
) => {
  return useAuthenticatedQuery(
    {
      schema: BasicUserDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/basicuser/${phoneNumber}`,
    },
    { ...options, queryKey: ['basicuser', phoneNumber] }
  );
};
