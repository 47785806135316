import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import '@aws-amplify/ui-react/styles.css';
import App from './App';
import './i18n';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;

root.render(<App />);
