import { useTranslation } from 'react-i18next';

const RecordsPerPageSelect = ({
  setItemsPerPage,
  itemsPerPage,
  type,
}: {
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  itemsPerPage: number;
  type: 'sm' | 'lg';
}) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <label
        htmlFor="countries"
        className="dark:text-gray-400 block mb-2 text-sm font-medium text-gray-900"
      >
        {t('common.select-records-per-page')}
      </label>
      <select
        value={itemsPerPage}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          setItemsPerPage(parseInt(event.target.value));
        }}
        id="countries"
        className="bg-gray-50 border pr-8 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        {type === 'sm' ? (
          <>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </>
        ) : (
          <>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
          </>
        )}
      </select>
    </div>
  );
};

export default RecordsPerPageSelect;
