import { useTranslation } from 'react-i18next';
import { Cell } from '~/components/Cells/Cell';
import type { Nullable } from '~/features/search/types';
import type { RefundStatusDto } from '~/lib/dtos/common/RefundStatusDto';

type RefundStatusCell = {
  refundStatus: Nullable<RefundStatusDto>;
};

export const RefundStatusCell = ({ refundStatus }: RefundStatusCell) => {
  const { color, name, helperText } = useRefundStatusMap(refundStatus);

  return (
    <Cell
      content={name}
      color={color}
      helperText={helperText}
      helperTextPlacement={'right'}
    />
  );
};

const useRefundStatusMap = (refundStatus: Nullable<RefundStatusDto>) => {
  const { t } = useTranslation();

  const refundStatusMap: Record<
    RefundStatusDto,
    { color: string; name: string; helperText: string }
  > = {
    COMPLETED: {
      color: 'bg-green-300',
      name: t('status.refund-completed'),
      helperText: t('helper-texts.order-info.refund-status.refund-completed'),
    },
    CREATED: {
      color: 'bg-yellow-300',
      name: t('status.refund-created'),
      helperText: t('helper-texts.order-info.refund-status.refund-created'),
    },
    ERROR: {
      color: 'bg-red-400',
      name: t('status.refund-error'),
      helperText: t('helper-texts.order-info.refund-status.refund-error'),
    },
  };

  return refundStatus
    ? refundStatusMap[refundStatus]
    : {
        color: null,
        name: '-',
        helperText: t('helper-texts.order-info.refund-status.refund-empty'),
      };
};
