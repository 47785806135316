import { Auth, Storage } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router';

import NotyfContext from '../../context/NotyfContext';
import Button from '../../components/Button';
import { options } from '../modal/ConfirmAlert';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Buffer } from 'buffer';
import { Alert } from 'flowbite-react';

const toBase64 = (str: string) => Buffer.from(str).toString('base64');

const uploadInstructionMutation = async ({
  instructionFile,
  username,
  email,
}: {
  instructionFile?: File;
  username?: string;
  email?: string;
}) => {
  if (instructionFile && username && email) {
    const usernameBase64 = toBase64(username);
    const emailBase64 = toBase64(email);

    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    await fetch(
      `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/logs/addinstruction`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          reference: instructionFile.name,
        }),
      }
    ).then((res) => {
      if (res.ok) {
        return res;
      }

      throw new Error();
    });

    const response = await Storage.put(instructionFile.name, instructionFile, {
      contentType: 'application/pdf',
      level: 'public',
      metadata: {
        username: encodeURIComponent(username),
        email: encodeURIComponent(email),
      },
      tagging: `username=${usernameBase64}&email=${emailBase64}`,
    });

    return response;
  }

  throw new Error('instructions.didnt-specify-file');
};

const InstructionPublish: React.FC = () => {
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuthenticator((context) => [context.user]);

  const uploadMutation = useMutation(uploadInstructionMutation, {
    onSuccess: (response, instructionFile) => {
      if (response.key && instructionFile) {
        notyf.success(
          t('instructions.successfully-added') +
            ' ' +
            instructionFile.instructionFile?.name +
            ' ' +
            t('instructions.instruction')
        );
        navigate('/instructions');
      }
    },
    onError: (error) => {
      if (typeof error === 'string') {
        notyf.error(error);
      }
    },
  });

  const [instructionFile, setInstructionFile] = useState<File | undefined>(
    undefined
  );

  const setupAlert = () => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title = t('instructions.are-you-sure-publish');
    newOptions.buttons = [
      {
        className: '!bg-green-600',
        label: t('common.yes'),
        onClick: () => {
          if (instructionFile?.type === 'application/pdf') {
            uploadMutation.mutate({
              instructionFile: instructionFile,
              username: user.username,
              email: user.attributes?.email,
            });
          } else {
            notyf.error(t('instructions.only-pdf'));
          }
        },
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstructionFile(event.target.files?.[0]);
  };

  return (
    <div className="flex flex-col gap-2">
      <Header>
        <>{t('instructions.publish') + ' ' + t('instructions.instruction')}</>
      </Header>

      <input type="file" onChange={onFileChange} />
      <div>
        <Button onClick={() => confirmAlert(setupAlert())}>
          {t('instructions.upload')}
        </Button>
      </div>
      {instructionFile && instructionFile.type !== 'application/pdf' && (
        <>
          <hr className="dark:bg-gray-700 h-px my-8 bg-gray-200 border-0" />
          <Alert color="warning" rounded>
            <span>
              <p>
                <span className="font-medium">
                  {t('instructions.supported-file-formats')}
                </span>
              </p>
            </span>
          </Alert>
        </>
      )}
    </div>
  );
};

export default InstructionPublish;
