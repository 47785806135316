import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router';
import Description from '../../../components/Description';
import Header from '../../../components/Header';
import { mapOperator } from '../utils';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../components/Layout';
import type { SubmitHandler } from 'react-hook-form';
import { useFormContext, Controller } from 'react-hook-form';
import { TextInput } from '~/components/Input';
import { Checkbox } from '~/components/Checkbox';
import { SelectClearAll } from '~/components/SelectClearAll';
import { Table } from 'flowbite-react';
import { useTranslateTicketTypes } from '~/hooks/useTranslateTicketTypes';
import type { SearchSchemaType } from '~/lib/schemas/searchSchema';
import Select from 'react-select';
import { useTicketStatuses } from '~/hooks/useTicketStatuses';
import { usePaymentStatuses } from '~/hooks/usePaymentStatuses';
import { buildParams } from '~/lib/utils/buildParams/buildParams';
import { useEffect, useMemo } from 'react';
import ErrorMessage from '~/components/ErrorMessage';
import { defaultValues } from '~/hooks/useSearch';
import { addDays, addYears, parseISO, subDays, subYears } from 'date-fns';

const operators: SearchSchemaType['operators'] = [
  'dk.telia',
  'dk.sonofon',
  'dk.tdc',
  'dk.hi3g',
];

const zones: SearchSchemaType['zones'] = ['2', '3', '4', '5', '6', '7', '8'];
const ticketTypes: SearchSchemaType['ticketTypes'] = [
  'SUPPLEMENTARY',
  'DSB1',
  'BIKE',
];
const customerTypes: SearchSchemaType['ticketTypes'] = [
  'ADULT',
  'CHILD',
  'DOG',
];
const bikePossession: SearchSchemaType['bikePossession'] = [
  'HAS_BIKE',
  'WITHOUT_BIKE',
];

const AdvancedSearch = () => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useFormContext<SearchSchemaType>();

  const { translatedOptions: translatedTicketStatusesOptions } =
    useTicketStatuses();
  const { translatedOptions: translatedPaymentStatusesOptions } =
    usePaymentStatuses();

  useEffect(() => {
    reset((prev) => ({ ...prev, searchType: 'advanced' }));
  }, [reset]);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const { translateTicketTypes } = useTranslateTicketTypes();

  const onSubmit: SubmitHandler<SearchSchemaType> = (data) => {
    navigate('/orders?' + buildParams(data));
  };

  const startDate = watch('startDate');

  const minStartDate = useMemo(() => {
    const threeYearsAgo = subYears(new Date(new Date()), 3);
    const launchDate = new Date('2023-01-18T00:00:00.000Z');
    return launchDate > threeYearsAgo ? launchDate : threeYearsAgo;
  }, [startDate]);

  return (
    <>
      <Header>{t('search.advanced-search')}</Header>
      <Description>{t('search.advanced-search-description')}</Description>
      <Container>
        <form
          className="flex flex-col gap-6 px-2 overflow-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="md:grid-cols-2 grid gap-2">
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <TextInput
                    label={t('tables.phone-number-customer-profile')}
                    placeholder="12345678"
                    variant="phoneNumber"
                    maxLength={8}
                    {...rest}
                  />
                );
              }}
            />
            <Controller
              name="ticketId"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <TextInput
                    label={t('tables.ticket-id')}
                    placeholder="1234 5678 9012 3"
                    {...rest}
                  />
                );
              }}
            />
          </div>
          <div className="w-1/3">
            <Controller
              name="fourTPaymentId"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return <TextInput label={t('tables.payment-id')} {...rest} />;
              }}
            />
          </div>
          <div className="flex items-end gap-6">
            <Controller
              name="startDate"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <div>
                    <label
                      htmlFor="phone"
                      className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t('common.start-date')}
                    </label>
                    <DatePicker
                      minDate={minStartDate}
                      showTimeSelect
                      dateFormat="d/M/yyyy, HH:mm"
                      timeFormat="HH:mm"
                      onChange={(date) => {
                        if (date) onChange(date.toISOString());
                      }}
                      selected={value ? new Date(value) : null}
                      calendarStartDay={1}
                    />
                  </div>
                );
              }}
            />
            <Controller
              name="endDate"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <div>
                    <label
                      htmlFor="serial_number"
                      className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900"
                    >
                      {t('common.end-date')}
                    </label>
                    <DatePicker
                      showTimeSelect
                      dateFormat="d/M/yyyy, HH:mm"
                      timeFormat="HH:mm"
                      onChange={(date) => {
                        if (date) onChange(date.toISOString());
                      }}
                      selected={value ? new Date(value) : null}
                      calendarStartDay={1}
                    />
                  </div>
                );
              }}
            />
            {errors?.phoneNumber?.message && (
              <div className="w-96">
                <ErrorMessage errorMessage={errors.phoneNumber.message} />
              </div>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <div className="font-medium">{t('search.operators')}</div>
            <Controller
              name="operators"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    <SelectClearAll
                      onClearAll={() => {
                        onChange([]);
                      }}
                      onSelectAll={() => {
                        onChange(operators);
                      }}
                    />
                    <div className="flex items-center gap-2">
                      {operators.map((operator) => {
                        return (
                          <Checkbox
                            key={operator}
                            value={operator}
                            label={mapOperator(operator)}
                            checked={value.some((o) => o === operator)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                onChange([...value, event.target.value]);
                                return;
                              }

                              onChange(
                                value.filter(
                                  (value) => value !== event.target.value
                                )
                              );
                            }}
                          />
                        );
                      })}
                    </div>
                  </>
                );
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-medium">{t('search.ticket-types')}</div>
            <div className="flex gap-6">
              <div className="flex flex-col gap-2">
                <SelectClearAll
                  onClearAll={() => {
                    reset(
                      (prev) => ({
                        ...prev,
                        ticketTypes: [
                          ...prev.ticketTypes.filter(
                            (type) => !ticketTypes.includes(type)
                          ),
                        ],
                        bikePossession: [],
                        zones: [],
                      }),
                      { keepDefaultValues: true }
                    );
                  }}
                  onSelectAll={() => {
                    reset(
                      (prev) => ({
                        ...prev,
                        ticketTypes: [...prev.ticketTypes, ...ticketTypes],
                        bikePossession: ['HAS_BIKE', 'WITHOUT_BIKE'],
                        zones: zones,
                      }),
                      { keepDefaultValues: true }
                    );
                  }}
                />
                <div>
                  <div className="flex gap-2 pb-4 mb-4 border-b">
                    <Controller
                      name="zones"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <>
                            <Table className="shadow-lg">
                              <Table.Head className="bg-gray-100">
                                {zones.map((zone) => {
                                  return (
                                    <Table.HeadCell key={zone}>
                                      {zone}
                                    </Table.HeadCell>
                                  );
                                })}
                              </Table.Head>
                              <Table.Body className="divide-y">
                                <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
                                  {zones.map((zone) => {
                                    return (
                                      <Table.Cell
                                        key={zone}
                                        className="border-2 border-gray-100"
                                      >
                                        <Checkbox
                                          key={zone}
                                          value={zone}
                                          checked={value.some(
                                            (z) => z === zone
                                          )}
                                          onChange={(event) => {
                                            if (event.target.checked) {
                                              onChange([
                                                ...value,
                                                event.target.value,
                                              ]);
                                              return;
                                            }
                                            onChange(
                                              value.filter(
                                                (value) =>
                                                  value !== event.target.value
                                              )
                                            );
                                          }}
                                        />
                                      </Table.Cell>
                                    );
                                  })}
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </>
                        );
                      }}
                    />
                    <Controller
                      name="bikePossession"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <div className="flex gap-2 mt-auto align-bottom">
                            {bikePossession.map((bikePossession) => {
                              return (
                                <Checkbox
                                  key={bikePossession}
                                  value={bikePossession}
                                  label={
                                    bikePossession === 'HAS_BIKE'
                                      ? t('search.with-bike')
                                      : t('search.without-bike')
                                  }
                                  checked={value.some(
                                    (b) => b === bikePossession
                                  )}
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      onChange([...value, event.target.value]);
                                      return;
                                    }
                                    onChange(
                                      value.filter(
                                        (value) => value !== event.target.value
                                      )
                                    );
                                  }}
                                />
                              );
                            })}
                          </div>
                        );
                      }}
                    />
                  </div>

                  <Controller
                    name="ticketTypes"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Table className="shadow-lg">
                          <Table.Head className="bg-gray-100">
                            {ticketTypes.map((type) => {
                              return (
                                <Table.HeadCell key={type}>
                                  {translateTicketTypes(type)}
                                </Table.HeadCell>
                              );
                            })}
                          </Table.Head>
                          <Table.Body className="divide-y">
                            <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
                              {ticketTypes.map((type) => {
                                return (
                                  <Table.Cell
                                    key={type}
                                    className="border-2 border-gray-100"
                                  >
                                    <div className="flex justify-center">
                                      <Checkbox
                                        key={type}
                                        value={type}
                                        checked={value.some((t) => t === type)}
                                        onChange={(event) => {
                                          if (event.target.checked) {
                                            onChange([
                                              ...value,
                                              event.target.value,
                                            ]);
                                            return;
                                          }
                                          onChange(
                                            value.filter(
                                              (value) =>
                                                value !== event.target.value
                                            )
                                          );
                                        }}
                                      />
                                    </div>
                                  </Table.Cell>
                                );
                              })}
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-medium">{t('customer.customer')}</div>
            <Controller
              name="ticketTypes"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    <SelectClearAll
                      onClearAll={() => {
                        reset(
                          (prev) => ({
                            ...prev,
                            ticketTypes: [
                              ...prev.ticketTypes.filter(
                                (type) => !customerTypes.includes(type)
                              ),
                            ],
                          }),
                          { keepDefaultValues: true }
                        );
                      }}
                      onSelectAll={() => {
                        reset(
                          (prev) => ({
                            ...prev,
                            ticketTypes: [
                              ...prev.ticketTypes,
                              ...customerTypes,
                            ],
                          }),
                          { keepDefaultValues: true }
                        );
                      }}
                    />
                    <div className="flex items-center gap-2">
                      {customerTypes.map((customerType) => {
                        return (
                          <Checkbox
                            key={customerType}
                            value={customerType}
                            label={translateTicketTypes(customerType)}
                            checked={value.some((t) => t === customerType)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                onChange([...value, event.target.value]);
                                return;
                              }
                              onChange(
                                value.filter(
                                  (value) => value !== event.target.value
                                )
                              );
                            }}
                          />
                        );
                      })}
                    </div>
                  </>
                );
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-medium">{t('search.order-status')}</div>
            <div className="flex items-center gap-2">
              <div className="flex flex-col gap-2">
                <div className="font-normal">{t('search.ticket-status')}</div>
                <Controller
                  name="ticketStatus"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <div className="flex items-center gap-2">
                        <Select
                          placeholder={t('common.select') + '...'}
                          menuPlacement="auto"
                          isClearable
                          className="w-60"
                          value={
                            translatedTicketStatusesOptions.find(
                              (option) => option.value === value
                            ) ?? null
                          }
                          onChange={(option, { action }) => {
                            if (action === 'clear') {
                              onChange('NONE');
                              return;
                            }
                            onChange(option?.value ?? null);
                          }}
                          options={translatedTicketStatusesOptions}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-normal">{t('search.payment-status')}</div>
                <Controller
                  name="paymentStatus"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <div className="flex items-center gap-2">
                        <Select
                          placeholder={t('common.select') + '...'}
                          menuPlacement="auto"
                          isClearable
                          className="w-60"
                          value={
                            translatedPaymentStatusesOptions.find(
                              (option) => option.value === value
                            ) ?? null
                          }
                          onChange={(option, { action }) => {
                            if (action === 'clear') {
                              onChange('NONE');
                              return;
                            }
                            onChange(option?.value ?? null);
                          }}
                          options={translatedPaymentStatusesOptions}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {t('common.submit')}
            </button>
            <button
              type="button"
              onClick={() => {
                reset({
                  ...defaultValues,
                  startDate: subDays(new Date(), 1).toISOString(),
                  endDate: new Date().toISOString(),
                });
              }}
              className="text-black bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {t('common.reset-to-defaults')}
            </button>
          </div>
        </form>
      </Container>
    </>
  );
};
export default AdvancedSearch;
