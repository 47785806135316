import { Table } from 'flowbite-react';
import clsx from 'clsx';
import { confirmAlert } from 'react-confirm-alert';
import { options } from '../../modal/ConfirmAlert';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { useTranslation } from 'react-i18next';
import { API, Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import NotyfContext from '../../../context/NotyfContext';
import { mapOperator } from '../utils';
import { Link } from 'react-router-dom';
import { NoteModal } from '~/components/Modal/NoteModal';
import type { FinancialStatusDto } from '~/lib/dtos/common/FinancialStatusDto';
import type { InspectionStatusDto } from '~/lib/dtos/common/InspectionStatusDto';
import { DateBreakHandler } from '~/features/customer/FullUserResultsTable';
import { format, parseISO } from 'date-fns';
import { formatDate } from '~/lib/utils/formatDates/formatDates';

export const TicketValidityCell = ({
  className,
  ticketValidEnd,
  isRefunded,
}: {
  className?: string;
  ticketValidEnd: string;
  isRefunded: boolean;
}) => {
  const backgroundStatusColor = (ticketValidEnd: string | null) => {
    const ticketValidEndDate = new Date();
    const dateNow = new Date();
    if (ticketValidEnd !== null) {
      if (ticketValidEndDate > dateNow && !isRefunded) {
        return 'bg-green-300';
      }
    }
    return '';
  };
  return (
    <Table.Cell
      className={clsx(backgroundStatusColor(ticketValidEnd), className)}
    >
      <>
        <DateBreakHandler date={ticketValidEnd} />
      </>
    </Table.Cell>
  );
};

export const MessageCell = ({
  className,
  message,
}: {
  className?: string;
  message: string;
}) => {
  const setupAlert = () => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.message = message;
    newOptions.buttons = [
      {
        className: '!bg-gray-500',
        label: 'Close',
        onClick: () => {},
      },
    ];
    return newOptions;
  };
  return (
    <Table.Cell
      className={clsx('cursor-pointer', className)}
      onClick={() => confirmAlert(setupAlert())}
    >
      <span className="text-blue-500">{message.slice(0, 30)}</span>
    </Table.Cell>
  );
};

export const GoToCell = ({ id, type }: { id: string; type: string }) => {
  const { t } = useTranslation();
  return (
    <Table.Cell className="text-center border-2 border-gray-100">
      <Link
        className="px-3 flex mx-auto py-1.5 bg-blue-500 hover:bg-blue-600 text-white rounded-md cursor-pointer max-w-max"
        to={`/${type}/${id}`}
      >
        {t('tables.open')}
      </Link>
    </Table.Cell>
  );
};

export const PhoneNumberCell = ({
  className,
  phoneNumber,
  customerId,
}: {
  className?: string;
  phoneNumber: string;
  customerId: number;
}) => {
  return (
    <Table.Cell
      className={clsx(
        'cursor-pointer px-3 py-1.5 text-center text-blue-500',
        className
      )}
    >
      <Link to={`/customer/${customerId}`}>{phoneNumber}</Link>
    </Table.Cell>
  );
};

export const IntegrationStatusCell = ({
  className,
  status,
  createdTime,
}: {
  className?: string;
  status: FinancialStatusDto | InspectionStatusDto | null;
  createdTime?: string;
}) => {
  const { t } = useTranslation();

  const returnColor = (status: string | undefined) => {
    if (status) {
      if (status.includes('200')) {
        return 'bg-green-300';
      } else if (status.includes('201')) {
        return 'bg-green-300';
      } else if (status.includes('503')) {
        return 'bg-red-400';
      } else if (status.includes('502')) {
        return 'bg-red-400';
      } else if (status.includes('500')) {
        return 'bg-red-400';
      } else {
        return 'bg-yellow-300';
      }
    }
  };

  const returnStatus = (status: string | undefined) => {
    if (!status) {
      return t('status.empty-status');
    } else {
      if (createdTime !== undefined) {
        if (status.includes('200')) {
          return t('status.registered');
        } else if (status.includes('201')) {
          return t('status.registered');
        } else if (status.includes('503')) {
          return t('status.error');
        } else if (status.includes('502')) {
          return t('status.error');
        } else if (status.includes('500')) {
          return t('status.error');
        } else {
          return status;
        }
      } else {
        return status
          .replaceAll('_', ' ')
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase());
      }
    }
  };

  const statusTimeDate = status
    ? status.modifiedAt
      ? new Date(status.modifiedAt)
      : new Date(status.createdAt)
    : undefined;

  const createdTimeDate = createdTime ? new Date(createdTime) : undefined;

  const timeDifference = () => {
    if (statusTimeDate !== undefined && createdTimeDate !== undefined) {
      return (
        statusTimeDate.getMinutes() * 60 +
        statusTimeDate.getSeconds() -
        (createdTimeDate.getMinutes() * 60 + createdTimeDate.getSeconds())
      );
    } else return 0;
  };

  const delay = timeDifference();

  const isDelayed = () => {
    if (delay > 15) {
      const minute = Math.floor(delay / 60);
      const rest_seconds = delay % 60;
      return (
        t('status.delay') +
        ':' +
        minute.toString().padStart(2, '0') +
        ':' +
        rest_seconds.toString().padStart(2, '0')
      );
    } else {
      return false;
    }
  };

  const delayStatus = isDelayed();
  return (
    <Table.Cell
      className={clsx(
        returnColor(status?.status),
        className,
        ' align-middle',
        delayStatus === false ? '' : ''
      )}
    >
      <span className=" relative">{returnStatus(status?.status)}</span>
      <br />
      {createdTime && (
        <span
          className={clsx(
            '',
            delayStatus === false ? '' : 'relative -bottom-3  text-center'
          )}
        >
          {delayStatus}
        </span>
      )}
    </Table.Cell>
  );
};

export const IntegrationDateCell = ({
  className,
  date,
}: {
  className?: string;
  date: string | undefined | null;
}) => {
  return (
    <Table.Cell className={clsx('', className)}>
      <span className="">
        {date !== undefined && date !== null ? formatDate(date) : ''}
      </span>
    </Table.Cell>
  );
};

export const OperatorCell = ({
  className,
  operator,
}: {
  className?: string;
  operator: string;
}) => {
  return (
    <Table.Cell className={clsx('', className)}>
      <span className="">{mapOperator(operator)}</span>
    </Table.Cell>
  );
};
