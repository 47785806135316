import { Button, Spinner } from 'flowbite-react';

type Props =
  | {
      text: string;
      onClick: () => void;
      type?: 'button';
      loading?: boolean;
      disabled?: boolean;
    }
  | {
      text: string;
      onClick?: () => void;
      type?: 'submit';
      loading?: boolean;
      disabled?: boolean;
    };

const ButtonWithLoading = ({
  text,
  onClick,
  type = 'button',
  loading = false,
  disabled = false,
}: Props) => {
  return (
    <div className="max-w-min relative">
      <button
        className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center  disabled:opacity-50 whitespace-nowrap"
        onClick={onClick}
        type={type}
        disabled={loading || disabled}
      >
        {loading && (
          <div className="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2">
            <Spinner size="sm" light={true} />
          </div>
        )}
        {text}
      </button>
    </div>
  );
};
export default ButtonWithLoading;
