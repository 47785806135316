import { useTranslation } from 'react-i18next';
import type { TUserGroup } from '~/features/maintenance/users/types';

export const useTranslateUserLevels = () => {
  const { t } = useTranslation();

  const userLevelMap: Record<TUserGroup, string> = {
    Superuser: t('users.super-user'),
    Administrator: t('users.administrator'),
    TeamLeader: t('users.team-leader'),
    FraudManager: t('users.fraud-manager'),
    BackofficeEmployee: t('users.backoffice-employee'),
    CustomerServiceRW: t('users.customer-fervice-rw'),
    CustomerServiceR: t('users.customer-service-r'),
    ProductOwner: t('users.product-owner'),
  };

  const translateUserLevel = (userGroup: TUserGroup) => {
    return userLevelMap[userGroup] ?? userGroup;
  };

  return { translateUserLevel };
};
