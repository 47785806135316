import z from 'zod';
import { DeliveryNotificationDtoSchema } from './common/DeliveryNotificationDto';
import { FinancialStatusDtoSchema } from './common/FinancialStatusDto';
import { IncomingMessageDtoSchema } from './common/IncomingMessageDto';
import { InspectionStatusDtoSchema } from './common/InspectionStatusDto';
import { OutgoingMessageDtoSchema } from './common/OutgoingMessageDto';
import { RefundStatusDtoSchema } from './common/RefundStatusDto';
import { TicketDtoSchema } from './common/TicketDto';
import { TicketNoteTypeDtoSchema } from './common/TicketNoteType';
import { UserDtoSchema } from './common/UserDto';

export const TicketDetailsDtoSchema = z.object({
  detail: z.object({
    user: UserDtoSchema,
    ticket: TicketDtoSchema,
    refund: z.union([
      z.object({
        id: z.object({
          value: z.number(),
        }),
        processId: z.object({
          value: z.string(),
        }),
        status: RefundStatusDtoSchema,
        note: z.object({
          value: z.string(),
        }),
        author: z.object({
          value: z.string(),
        }),
        createdAt: z.string(),
        modifiedAt: z.string(),
      }),
      z.null(),
    ]),

    paymentId: z
      .object({
        value: z.string(),
      })
      .optional(),
    incomingMessages: z.array(IncomingMessageDtoSchema),
    outgoingMessages: z.array(OutgoingMessageDtoSchema),
    sendToFinancialAuthorityStatus: z.union([
      FinancialStatusDtoSchema,
      z.null(),
    ]),
    sendToTicketInspectionStatus: z.union([
      InspectionStatusDtoSchema,
      z.null(),
    ]),
    deliveryNotifications: z.array(DeliveryNotificationDtoSchema),
    notes: z.array(
      z.object({
        content: z.string(),
        createdAt: z.string(),
        createdBy: z.string(),
        id: z.object({ value: z.number() }),
        ticketId: z.object({ value: z.number() }),
        type: TicketNoteTypeDtoSchema,
      })
    ),
  }),
});

export type TicketDetailsDto = z.infer<typeof TicketDetailsDtoSchema>;
