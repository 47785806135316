import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../../components/ErrorMessage';
import NotyfContext from '../../../context/NotyfContext';
import { useHasPermissions } from '../../../hooks/useHasPermissions';
import { useHasGroup } from '../../../hooks/useHasGroup';
import { useCurrentUserContext } from '../../../hooks/useCurrentUser';
import type { CurrentUserData } from '~/context/UserContext';
import { useTranslateUserLevels } from '~/hooks/useTranslateUserLevels';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import PermissionsTable, {
  fetchAllPermissionsRequest,
  fetchGroupPermissionsRequest,
} from './PermissionsTable';
import type { PermissionsDto } from '~/lib/dtos/PermissionsDto';

import ButtonWithLoading from '~/components/ButtonWithLoading';
import { useMutation } from 'react-query';
import type {
  BOUserGroupSchemaDto,
  BOUserSchemaDto,
  CreateBOUserFormSchemaDto,
} from '~/lib/dtos/BOUserDto';

const createUserRequest = async (
  params: CreateBOUserFormSchemaDto
): Promise<BOUserSchemaDto> => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();
  const postData = {
    email: params.userEmail,
    name: params.name,
    groups: [params.userLevel],
  };
  const response = await fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/users`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(postData),
    }
  );

  const data = await response.json();

  if (response.ok) {
    return data;
  } else {
    throw data;
  }
};

const createRegex = (
  superuser: boolean,
  company: CurrentUserData['company'] | undefined
) => {
  if (superuser) {
    return /^[A-Za-z0-9._%+-]+@(siili\.com|dsb\.dk|moviatrafik\.dk|metroservice\.dk|plusdial\.net|lokaltog\.dk|dinoffentligetransport\.dk)$/;
  }
  switch (company) {
    case 'DSB':
      return /^[A-Za-z0-9._%+-]+@(dsb\.dk)$/;
    case 'METRO_SERVICE':
      return /^[A-Za-z0-9._%+-]+@(metroservice\.dk)$/;
    case 'MOVIA':
      return /^[A-Za-z0-9._%+-]+@(moviatrafik\.dk|lokaltog\.dk)$/;
    case 'DOT':
      return /^[A-Za-z0-9._%+-]+@(dinoffentligetransport\.dk)$/;
    case 'SIILI':
      return /^[A-Za-z0-9._%+-]+@(siili\.com)$/;
    case 'PLUSDIAL':
      return /^[A-Za-z0-9._%+-]+@(plusdial\.net)$/;
    default:
      return /^$/;
  }
};

const CreateUser: React.FC = () => {
  const { t } = useTranslation();

  const isSuperuser = useHasGroup('Superuser');

  const isAdministrator = useHasGroup('Administrator');

  const { currentUser } = useCurrentUserContext();
  const emailPatternNotValidMessage = isSuperuser
    ? t('users.email-required-superuser')
    : t('users.email-required-administrator') +
      ' (' +
      currentUser?.email.split('@')[1] +
      ')';

  const createUserFormSchema = (
    isSuperuser: boolean,
    company: CurrentUserData['company'] | undefined
  ) =>
    z.object({
      name: z.string().min(1, t('users.full-name-is-required')),
      userEmail: z
        .string()
        .min(1, t('users.email-is-required'))
        .regex(createRegex(isSuperuser, company), emailPatternNotValidMessage),
      userLevel: z.string().min(1, t('users.user-level-is-required')),
    });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<CreateBOUserFormSchemaDto>({
    resolver: zodResolver(
      createUserFormSchema(isSuperuser, currentUser?.company)
    ),
    defaultValues: {
      name: '',
      userEmail: '',
      userLevel: '',
    },
  });

  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);

  const [groupPermissions, setGroupPermissions] =
    useState<null | PermissionsDto>(null);
  const [allPermissions, setAllPermissions] = useState<null | PermissionsDto>(
    null
  );

  // use createUserRequest in react-query useMutation
  const createUserMutation = useMutation<
    BOUserSchemaDto,
    { code: string; message: string },
    CreateBOUserFormSchemaDto
  >(createUserRequest, {
    onSuccess: (user) => {
      const userEmail = String(
        user.Attributes.find((attribute) => attribute.Name === 'email')?.Value
      );
      notyf.success(
        t('users.successfully-created-new-user-with-email') + userEmail
      );
      reset();
    },
    onError: (e: { code: string; message: string }) => {
      if (e.code === 'UsernameExistsException') {
        notyf.error(t('users.user-already-exists'));
      } else {
        notyf.error(e.message);
      }
    },
  });

  const hasPermission = useHasPermissions('CREATE_USER');

  const { translateUserLevel } = useTranslateUserLevels();

  const superuserAccountLevelCreationList: BOUserGroupSchemaDto[] = [
    'Superuser',
    'TeamLeader',
    'Administrator',
    'FraudManager',
    'BackofficeEmployee',
    'CustomerServiceRW',
    'CustomerServiceR',
  ];

  const administratorAccountLevelCreationList: BOUserGroupSchemaDto[] = [
    'Administrator',
    'TeamLeader',
    'FraudManager',
    'BackofficeEmployee',
    'CustomerServiceRW',
    'CustomerServiceR',
  ];

  const userLevel = watch('userLevel');

  const fetchGroupPermissionsData = async () => {
    if (!userLevel) return;

    try {
      const permissions = await fetchGroupPermissionsRequest(userLevel);
      setGroupPermissions(permissions);
    } catch (error) {
      console.error(`Failed to fetch group data for ${userLevel}`, error);
    }
  };

  const fetchAllPermissionsData = async () => {
    try {
      const permissions = await fetchAllPermissionsRequest();
      setAllPermissions(permissions);
    } catch (error) {
      console.error(`Failed to fetch group data for ${userLevel}`, error);
    }
  };

  useEffect(() => {
    if (userLevel) fetchGroupPermissionsData();
    if (!groupPermissions) fetchAllPermissionsData();
  }, [userLevel]);

  return (
    <div className="flex flex-col w-full p-2 overflow-auto">
      <form
        className=""
        onSubmit={handleSubmit((newUserData) =>
          createUserMutation.mutate(newUserData)
        )}
      >
        <div className="flex justify-between w-full">
          <Header>{t('users.add-new-user')}</Header>
          <Button
            className="w-auto"
            type="gray"
            onClick={() => navigate('/maintenance/users')}
          >
            {t('common.back')}
          </Button>
        </div>

        <div className="xl:w-1/2 flex">
          <div className="flex flex-col">
            <div className="my-auto">{t('users.email')}</div>
            <div className="whitespace-nowrap my-auto">
              {t('users.full-name')}
            </div>
            <div className="whitespace-nowrap my-auto">
              {t('users.user-level')}
            </div>
          </div>
          <div className="w-96 xl:w-full flex flex-col">
            <Controller
              name="userEmail"
              control={control}
              rules={{
                required: t('users.email-is-required'),
                pattern: {
                  value: createRegex(isSuperuser, currentUser?.company),
                  message: emailPatternNotValidMessage,
                },
              }}
              render={({ field }) => (
                <div className="flex my-1 align-middle">
                  <input
                    type="text"
                    id=""
                    className="w-full py-1 mx-2"
                    {...field}
                  />
                </div>
              )}
            />

            <Controller
              name="name"
              control={control}
              rules={{ required: t('users.full-name-is-required') }}
              render={({ field }) => (
                <div className="flex my-1 align-middle">
                  <input
                    type="text"
                    id=""
                    className="w-full py-1 mx-2"
                    {...field}
                  />
                </div>
              )}
            />

            <Controller
              name="userLevel"
              control={control}
              rules={{ required: t('users.user-level-is-required') }}
              render={({ field }) => (
                <div className="flex my-1 align-middle">
                  <select className="w-full py-1 mx-2" {...field}>
                    <option value="" disabled>
                      {t('users.select-user-role')}
                    </option>
                    {isSuperuser && hasPermission && (
                      <>
                        {superuserAccountLevelCreationList.map(
                          (accountLevel) => (
                            <option key={accountLevel} value={accountLevel}>
                              {translateUserLevel(accountLevel)}
                            </option>
                          )
                        )}
                      </>
                    )}
                    {isAdministrator && hasPermission && (
                      <>
                        {administratorAccountLevelCreationList.map(
                          (accountLevel) => (
                            <option key={accountLevel} value={accountLevel}>
                              {translateUserLevel(accountLevel)}
                            </option>
                          )
                        )}
                      </>
                    )}
                  </select>
                </div>
              )}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 pt-2">
          {errors.userEmail && (
            <ErrorMessage errorMessage={errors.userEmail.message?.toString()} />
          )}
          {errors.name && (
            <ErrorMessage errorMessage={errors.name.message?.toString()} />
          )}

          {errors.userLevel?.message && (
            <ErrorMessage errorMessage={errors.userLevel.message.toString()} />
          )}
        </div>

        <div className="py-2">
          <ButtonWithLoading
            text={t('users.create')}
            disabled={!groupPermissions || !allPermissions}
            loading={createUserMutation.isLoading}
            type="submit"
          />
        </div>
      </form>
      {groupPermissions && allPermissions && (
        <PermissionsTable
          groupPermissions={groupPermissions}
          allPermissions={allPermissions}
        />
      )}
    </div>
  );
};

export default CreateUser;
