import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import type { BulletinsDto } from '~/lib/dtos/BulltetinsDto';

const fetchBulletins = async (params: { limit: string; page: string }) => {
  const paramsWithoutFalsyValues = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v)
  );
  const searchParams = new URLSearchParams(paramsWithoutFalsyValues);

  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/bulletins?${searchParams}`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );

  return res.json();
};

export const useBulletins = (page: number, limit: number) => {
  const { data, isLoading, error, isFetching } = useQuery<BulletinsDto>(
    ['bulletins', { page, limit }],
    () => fetchBulletins({ page: page.toString(), limit: limit.toString() }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return { data, isLoading, error, isFetching };
};
