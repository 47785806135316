import z from 'zod';

export const PaymentStatusDtoSchema = z.union([
  z.literal('PAYMENT_PROCESSING'),
  z.literal('PAYMENT_REJECTED'),
  z.literal('PAYMENT_COMPLETED'),
  z.literal('PAYMENT_REFUNDED'),
]);

export type PaymentStatusDto = z.infer<typeof PaymentStatusDtoSchema>;
