import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import type { FullUserDto } from '~/lib/dtos/FullUserDto';

const fetchFullUser = async ({
  userId,
  limit,
  page,
  startDate,
  endDate,
}: Props) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/fulluser/${userId}?page=${page}&limit=${limit}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );

  return res.json();
};

type Props = {
  userId?: string;
  limit: number;
  page: number;
  startDate: Date;
  endDate: Date;
};

export const useFullUserResultsTable = ({
  userId,
  limit,
  page,
  startDate,
  endDate,
}: Props) => {
  const { data, isLoading, isFetching, error } = useQuery<FullUserDto>(
    ['fullUser', { userId, limit, page, startDate, endDate }],
    () => fetchFullUser({ userId, limit, page, startDate, endDate }),
    {
      enabled: Boolean(userId),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    data: data?.fullUserData ?? [],
    isLoading,
    isFetching,
    error,
    count: data?.count ?? 0,
    availablePages: data?.availablePages ?? 0,
  };
};
