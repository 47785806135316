import { addYears, isBefore, isEqual } from 'date-fns';

export const notLongerThanOneYear = ({
  phoneNumber,
  startDate,
  endDate,
  ticketId,
  fourTPaymentId,
}: {
  phoneNumber: string;
  startDate: string;
  endDate: string;
  ticketId: string;
  fourTPaymentId: string;
}): boolean => {
  const hasTicketOrPaymentId = ticketId || fourTPaymentId;

  if (hasTicketOrPaymentId) {
    return true;
  }
  const oneYearAfterStart = addYears(new Date(startDate), 1);
  const endDateObj = new Date(endDate);

  oneYearAfterStart.setSeconds(0);
  oneYearAfterStart.setMilliseconds(0);

  endDateObj.setSeconds(0);
  endDateObj.setMilliseconds(0);

  if (phoneNumber.match(/\d{8}/) !== null) {
    return true;
  }

  return (
    isBefore(endDateObj, oneYearAfterStart) ||
    isEqual(endDateObj, oneYearAfterStart)
  );
};
