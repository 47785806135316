import type { ChangeEvent } from 'react';

type Props = {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label?: string;
  disabled?: boolean;
};

export const Checkbox = ({
  checked,
  onChange,
  value,
  label,
  disabled = false,
}: Props) => {
  return (
    <div className={label && 'mr-4'}>
      <input
        id={value}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className="focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
      />
      {label && (
        <label
          htmlFor={value}
          className="dark:text-gray-300 ml-1 text-sm text-gray-900"
        >
          {label}
        </label>
      )}
    </div>
  );
};
