import { Table } from 'flowbite-react';
import { useNavigate, useParams } from 'react-router';
import Button from '../../../components/Button';
import ActionsTable from './ActionsTable';
import TicketTable from './TicketTable';
import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import MessagesTable from '../messages/MessagesTable';
import {
  IntegrationDateCell,
  IntegrationStatusCell,
} from '../components/TicketCells';
import { NotesTable } from './NotesTable';
import { useTicketQuery } from '~/lib/hooks/useTicketQuery';
import type { TicketDetailsDto } from '~/lib/dtos/TicketDto';
import { BarLoader } from 'react-spinners';
import { useDownloadSearchResults } from '~/hooks/useDownloadSearchResults';
import { useHasPermissions } from '~/hooks/useHasPermissions';

const TicketDetails = ({
  mCode,
  paymentId,
}: {
  mCode?: string;
  paymentId?: string;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    data: ticket,
    isFetching,
    refetch,
  } = useTicketQuery({ mCode, paymentId, ticketId: params?.ticketId });

  const numericMcode = ticket?.detail.ticket.codes.mcode.value
    .split(/\r?\n/)
    .pop();
  const { download } = useDownloadSearchResults({
    id: ticket?.detail.ticket.id.value,
    createdAt: ticket?.detail.ticket.createdAt,
    fileType: 'ticket',
    mCode: numericMcode,
  });

  const { t } = useTranslation();

  const downloadVisible = useHasPermissions('DOWNLOAD_DATA');

  return (
    <div className="">
      <div className="flex justify-between">
        <Button className="w-auto" type="gray" onClick={() => navigate(-1)}>
          {t('common.back')}
        </Button>
        <div className="flex gap-2">
          <Button className="w-auto" type="gray" onClick={() => refetch()}>
            {t('common.refresh')}
          </Button>
        </div>
      </div>
      <div className="py-4 text-xl font-semibold">
        {numericMcode
          ? `${t('search.ticket-details')} (${t(
              'tables.ticket-id'
            )}: ${numericMcode})`
          : `${t('search.ticket-details')} (${t('common.loading')})`}
      </div>

      <TicketDetailsContent ticket={ticket} isFetching={isFetching} />
    </div>
  );
};

const TicketDetailsContent = ({
  ticket,
  isFetching,
}: {
  ticket?: TicketDetailsDto;
  isFetching: boolean;
}) => {
  const { t } = useTranslation();

  const numericMcode = ticket?.detail.ticket.codes.mcode.value
    .split(/\r?\n/)
    .pop();
  const { download } = useDownloadSearchResults({
    id: ticket?.detail.ticket.id.value,
    createdAt: ticket?.detail.ticket.createdAt,
    fileType: 'ticket',
    mCode: numericMcode,
  });

  const downloadVisible = useHasPermissions('DOWNLOAD_DATA');

  if (isFetching) {
    return (
      <div className="flex justify-center w-full py-4 text-xl text-center">
        <BarLoader color="#7f7f7f" />
      </div>
    );
  }
  if (!ticket) {
    return <Header>{t('search.couldnt-load-ticket')}</Header>;
  }

  return (
    <div className="flex w-full">
      <div className="flex flex-col">
        <TicketTable ticketResponse={ticket} />
        <div className=" pt-4 pb-2 text-xl font-semibold text-left">
          {t('tables.responses')}
        </div>
        <Table className="border-spacing-0 w-full border border-separate border-gray-300 rounded-md shadow-lg">
          <Table.Head className="font-bold bg-gray-100">
            <Table.Cell className="text-center border border-gray-100">
              {t('tables.ebk-response')}
            </Table.Cell>
            <Table.Cell className="text-center border border-gray-100">
              {t('tables.dsb-response')}
            </Table.Cell>
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
              <IntegrationStatusCell
                status={ticket.detail.sendToTicketInspectionStatus}
                className="text-center border border-gray-100"
              />

              <IntegrationStatusCell
                status={ticket.detail.sendToFinancialAuthorityStatus}
                className="text-center border border-gray-100"
              />
            </Table.Row>
            <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
              <IntegrationDateCell
                className="text-center border border-gray-100"
                date={ticket.detail.sendToTicketInspectionStatus?.modifiedAt}
              />
              <IntegrationDateCell
                date={ticket.detail.sendToFinancialAuthorityStatus?.createdAt}
                className="text-center border border-gray-100"
              />
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <div className="flex flex-col w-full ml-8">
        <ActionsTable ticket={ticket.detail.ticket} />
        <div className="flex justify-between pt-4 pb-2">
          <div className=" mt-auto text-xl font-semibold text-left">
            {t('tables.messages')}
          </div>
          <div className=" mt-auto">
            <Button onClick={download} isVisible={downloadVisible}>
              {t('common.download-messages')}
            </Button>
          </div>
        </div>

        <div className="">
          <MessagesTable
            deliveryNotifications={ticket.detail.deliveryNotifications}
            incomingMessages={ticket.detail.incomingMessages}
            outgoingMessages={ticket.detail.outgoingMessages}
          />
        </div>
        <NotesTable notes={ticket.detail.notes} />
      </div>
    </div>
  );
};
export default TicketDetails;
