import { z } from 'zod';

export const OperatorsSchema = z.union([
  z.literal('dk.telia'),
  z.literal('dk.sonofon'),
  z.literal('dk.tdc'),
  z.literal('dk.hi3g'),
]);

export type OperatorsSchemaDto = z.infer<typeof OperatorsSchema>;
