import z from 'zod';
import { TicketTypeDtoSchema } from './common/TicketTypeDto';
import { PaymentStatusDtoSchema } from './common/PaymentStatusDto';
import { OperatorsSchema } from './common/OperatorsDTO';

export const TicketsV2DtoSchema = z.object({
  tickets: z.array(
    z.object({
      ticketId: z.object({
        value: z.number(),
      }),
      createdAt: z.string(),
      phoneNumber: z.object({
        value: z.string(),
      }),
      userId: z.object({
        value: z.number(),
      }),
      deliveryNotificationStatus: z.string().nullable(),
      deliveredAt: z.string().nullable(),
      sendToTicketInspectionStatus: z.string().nullable(),
      sendToFinancialAuthorityStatus: z.string().nullable(),
      validFrom: z.string(),
      validTo: z.string(),
      ticketType: TicketTypeDtoSchema,
      distance: z.object({
        value: z.number(),
      }),
      paymentStatus: PaymentStatusDtoSchema,
      canRefund: z.boolean(),
      valid: z.boolean(),
      resent: z.boolean(),
      sentToEbkAt: z.string().nullable(),
      sentToDsbAt: z.string().nullable(),
      operator: OperatorsSchema,
    })
  ),
});

export type TicketsV2Dto = z.infer<typeof TicketsV2DtoSchema>;
