import type { UseQueryOptions } from 'react-query';
import { useAuthenticatedQuery } from './common/useAuthenticatedQuery';
import { buildParams } from '../utils/buildParams/buildParams';
import type { SearchSchemaType } from '../schemas/searchSchema';
import type { TicketTypeDto } from '../dtos/common/TicketTypeDto';
import type { CountDto } from '../dtos/CountDto';
import { CountDtoSchema } from '../dtos/CountDto';
import { addZonesByTicketTypes } from './useTicketsV2Query';

type Args = {
  searchParams: {
    distance: string[];
    endDate: string;
    limit: string;
    operator: SearchSchemaType['operators'];
    paymentStatus: SearchSchemaType['paymentStatus'];
    phone?: string;
    startDate: string;
    ticketStatus: SearchSchemaType['ticketStatus'];
    ticketType: TicketTypeDto[];
  };
  options?: UseQueryOptions<CountDto>;
};

export const useTicketsV2CountQuery = ({ searchParams, options }: Args) => {
  const addedZones = addZonesByTicketTypes(searchParams.ticketType);

  const search = buildParams({
    ...searchParams,
    paymentStatus:
      searchParams.paymentStatus === 'AUTHORIZATION_FAILED' ||
      searchParams.paymentStatus === 'AUTHORIZED'
        ? 'NONE'
        : searchParams.paymentStatus,
    distance: [...new Set([...searchParams.distance, ...addedZones])],
  });

  return useAuthenticatedQuery(
    {
      schema: CountDtoSchema,
      url: `${
        import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
      }/v2/tickets/search/count?${search}`,
    },
    {
      ...options,
      queryKey: ['ticketsSearchV2Count', searchParams],
      retry: false,
    }
  );
};
