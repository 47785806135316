import z from 'zod';

export const BlockadeOperationTypeDtoSchema = z.union([
  z.literal('PERMANENTLY_BLOCKED_AUTOMATICALLY'),
  z.literal('PERMANENTLY_BLOCKED_MANUALLY'),
  z.literal('TEMPORARILY_BLOCKED_AUTOMATICALLY'),
  z.literal('TEMPORARILY_BLOCKED_MANUALLY'),
  z.literal('UNBLOCKED_AUTOMATICALLY'),
  z.literal('UNBLOCKED_MANUALLY'),
]);

export type BlockadeOperationTypeDto = z.infer<
  typeof BlockadeOperationTypeDtoSchema
>;
