import z from 'zod';
import { TicketPaymentStatusDtoSchema } from './TicketPaymentStatusDto';
import { TicketTypeDtoSchema } from './TicketTypeDto';

export const TicketDtoSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  type: TicketTypeDtoSchema,
  price: z.object({
    amount: z.number(),
    vat: z.number(),
  }),
  paymentStatus: TicketPaymentStatusDtoSchema,
  zoneId: z.array(
    z.object({
      value: z.number(),
    })
  ),
  distance: z.object({
    value: z.number(),
  }),
  codes: z.object({
    sendersNumber: z.object({
      value: z.string(),
    }),
    nakedEyeSecurityCode: z.object({
      value: z.string(),
    }),
    controlCode: z.object({
      value: z.string(),
    }),
    serialId: z.object({
      value: z.string(),
    }),
    mcode: z.object({
      value: z.string(),
    }),
  }),
  processId: z.object({
    value: z.string(),
  }),
  userId: z.object({
    value: z.number(),
  }),
  ticketTimeValidity: z.object({
    validFrom: z.string(),
    validTo: z.string(),
    validInMinutes: z.string(),
  }),
  createdAt: z.string(),
  modifiedAt: z.string(),
});

export type TicketDto = z.infer<typeof TicketDtoSchema>;
