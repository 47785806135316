import clsx from 'clsx';
import React from 'react';
const Header = ({
  size,
  children,
  className,
}: {
  size?: string;
  className?: string;
  children: string | JSX.Element;
}) => {
  const baseClass = clsx('pt-2 font-semibold', className);

  const dynamicClass = () => {
    if (size === undefined) {
      return 'text-xl';
    } else {
      return `text-${size}`;
    }
  };

  return <div className={clsx(baseClass, dynamicClass())}>{children}</div>;
};
export default Header;
