import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { Container } from '../../components/Layout';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useBulletinQuery } from '~/lib/hooks/useBulletinQuery';
import { useCurrentUserContext } from '~/hooks/useCurrentUser';
import NotyfContext from '~/context/NotyfContext';
import { LoadingCurrentUser } from '~/components/LoadingCurrentUser';
import { z } from 'zod';
import { useDeleteBulletinMutation } from '~/lib/hooks/useDeleteBulletinMutation';
import { useChangeBulletinMutation } from '~/lib/hooks/useChangeBulletinMutation';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import { formatDate } from '~/lib/utils/formatDates/formatDates';

const jsonStringSchema = z.string().refine(
  (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  },
  { message: 'Invalid JSON' }
);

type TLoadedBulletin = {
  editorState?: EditorState;
  id: number;
  subject: string;
  publishedBy: string;
  publishedAt: string;
  content?: string;
  modifiedAt?: string;
};

const DisplayBulletinContent = ({
  bulletin,
}: {
  bulletin: TLoadedBulletin;
}) => {
  if (bulletin.editorState) {
    return (
      <div className="flex flex-col">
        <div className="flex justify-between">
          <Editor
            toolbarHidden={true}
            editorState={bulletin.editorState}
            readOnly={true}
            wrapperClassName="w-full h-full"
            editorClassName="readonly-editor"
          />
        </div>
      </div>
    );
  }
  return <div>{bulletin.content}</div>;
};

const Bulletin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { currentUser } = useCurrentUserContext();
  const notyf = useContext(NotyfContext);

  const { data: nestedBulletin, isError } = useBulletinQuery(
    String(params.bulletinId)
  );
  const { deleteBulletin, setupDeleteBulletinAlert } =
    useDeleteBulletinMutation();

  const bulletin = nestedBulletin?.bulletin;

  const loadedBulletin: TLoadedBulletin | null = useMemo(() => {
    if (!bulletin) return null;

    if (
      bulletin &&
      bulletin.content &&
      jsonStringSchema.safeParse(bulletin.content).success
    ) {
      const rawContent = JSON.parse(bulletin.content);
      const contentState = convertFromRaw(rawContent);
      const loadedEditorState = EditorState.createWithContent(contentState);
      return {
        id: bulletin.id.value,
        subject: bulletin.subject,
        publishedBy: bulletin.publishedBy,
        publishedAt: bulletin.publishedAt,
        content: bulletin.content,
        modifiedAt: bulletin.modifiedAt,
        editorState: loadedEditorState,
      };
    }

    return {
      id: bulletin.id.value,
      subject: bulletin.subject,
      publishedBy: bulletin.publishedBy,
      publishedAt: bulletin.publishedAt,
      content: bulletin.content,
      modifiedAt: bulletin.modifiedAt,
    };
  }, [bulletin]);

  const [editorState, setEditorState] = useState<EditorState>(
    loadedBulletin?.editorState || EditorState.createEmpty()
  );

  const [textContent, setTextContent] = useState(loadedBulletin?.content || '');

  const [editedSubject, setEditedSubject] = useState(
    loadedBulletin?.subject || ''
  );

  useEffect(() => {
    if (loadedBulletin?.editorState) {
      setEditorState(loadedBulletin.editorState);
      setEditedSubject(loadedBulletin.subject);
    } else {
      setTextContent(loadedBulletin?.content || '');
      setEditedSubject(loadedBulletin?.subject || '');
    }
  }, [loadedBulletin]);

  const [isEditing, setIsEditing] = useState(false);

  const canManageBulletin = useHasPermissions('MANAGE_BULLETINS');

  const changeBulletin = useChangeBulletinMutation({
    onSuccess: () => {
      setIsEditing(false);
    },
  });

  if (!currentUser) return <LoadingCurrentUser currentUser={currentUser} />;

  const submitEditedBulletin = () => {
    if (!loadedBulletin) return;
    const subjectLength = editedSubject.length;
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const jsonData = JSON.stringify(rawContentState);

    const contentLength = jsonData.length;

    if (subjectLength < 1)
      return notyf.error(t('bulletins.bulletin-subject-validation-min'));
    if (subjectLength > 200)
      return notyf.error(t('bulletins.bulletin-subject-validation-max'));

    if (contentLength < 1)
      return notyf.error(t('bulletins.bulletin-content-validation-min'));
    if (contentLength > 10000)
      return notyf.error(t('bulletins.bulletin-content-validation-max'));

    changeBulletin.mutate({
      id: loadedBulletin.id,
      publishedBy: currentUser.email,
      subject: editedSubject,
      content: loadedBulletin.editorState ? jsonData : textContent,
    });
  };

  return (
    <Container>
      <div className="flex justify-between pb-4">
        <div>
          <Button className="w-auto" type="gray" onClick={() => navigate(-1)}>
            {t('common.back')}
          </Button>
        </div>
        <div>
          {canManageBulletin && loadedBulletin && (
            <>
              <Button
                className="w-auto mr-2"
                onClick={() => setIsEditing((isEditing) => !isEditing)}
              >
                {t('tables.edit')}
              </Button>
              <Button
                className="w-auto"
                type="red"
                onClick={setupDeleteBulletinAlert(
                  loadedBulletin.id,
                  loadedBulletin.subject
                )}
              >
                {t('tables.delete')}
              </Button>
            </>
          )}
        </div>
      </div>
      {isError && <Header>{t('bulletins.error')}</Header>}
      {loadedBulletin && (
        <div>
          <div className="flex justify-between">
            <div>
              {t('tables.author') + ': '}
              <strong>{loadedBulletin.publishedBy}</strong>
            </div>
            <div>
              {formatDate(
                loadedBulletin.modifiedAt || loadedBulletin.publishedAt
              )}
            </div>
          </div>
          {isEditing ? (
            <input
              className="p-1 text-xl font-semibold border-[#F1F1F1] border my-1 md:w-1/2 w-full"
              value={editedSubject}
              onChange={(e) => {
                setEditedSubject(e.target.value);
              }}
            />
          ) : (
            <Header>{loadedBulletin.subject}</Header>
          )}
          {isEditing ? (
            <div className="flex flex-col w-full">
              {loadedBulletin.editorState ? (
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName mb-0"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName bg-white p-2 border-[#F1F1F1] border border-t-0"
                  onEditorStateChange={(editorState) =>
                    setEditorState(editorState)
                  }
                />
              ) : (
                <textarea
                  className="h-64"
                  value={textContent}
                  onChange={(e) => {
                    setTextContent(e.target.value);
                  }}
                />
              )}

              <div className="py-2">
                <Button onClick={submitEditedBulletin}>
                  {t('common.save')}
                </Button>
              </div>
            </div>
          ) : (
            <DisplayBulletinContent bulletin={loadedBulletin} />
          )}
        </div>
      )}
    </Container>
  );
};

export default Bulletin;
