import z from 'zod';

export const DashboardOperatorDtoSchema = z.object({
  dashboard: z.object({
    operatorData: z.object({
      operator: z.string(),
      latestIncomingMessage: z.string(),
      latestOutgoingMessage: z.string(),
      latestSuccessfulCapture: z.string(),
      numberOfMessagesReceivedInAnHour: z.number(),
      numberOfMessagesSentInAnHour: z.number(),
      numberOfDeliveryNotificationInAnHour: z.number(),
      numberOfCapturedInAnHour: z.number(),
      percentageOfDeliveredMessagesToSentMessages: z.number(),
      percentageOfSuccessfullyCapturedToSentTicketMessages: z.number(),
    }),
  }),
});

export type DashboardOperator = z.infer<typeof DashboardOperatorDtoSchema>;
