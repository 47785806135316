import { isAfter, addDays, isBefore, isEqual } from 'date-fns';

export const notLongerThanThirtyDays = ({
  phoneNumber,
  startDate,
  endDate,
  ticketId,
  fourTPaymentId,
}: {
  phoneNumber: string;
  startDate: string;
  endDate: string;
  ticketId: string;
  fourTPaymentId: string;
}): boolean => {
  const hasTicketOrPaymentId = ticketId || fourTPaymentId;

  if (hasTicketOrPaymentId) {
    return true;
  }

  if (phoneNumber) {
    return true;
  }

  const thirtyDaysAfterStart = addDays(new Date(startDate), 31);

  const endDateObj = new Date(endDate);

  thirtyDaysAfterStart.setSeconds(0);
  thirtyDaysAfterStart.setMilliseconds(0);

  endDateObj.setSeconds(0);
  endDateObj.setMilliseconds(0);

  return (
    isBefore(endDateObj, thirtyDaysAfterStart) ||
    isEqual(endDateObj, thirtyDaysAfterStart)
  );
};
