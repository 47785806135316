import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import NotyfContext from '~/context/NotyfContext';
import type { BlockReasonDTO } from '../dtos/common/BlockReasonDTO';

const blockCustomerRequest = async (
  customerId: number,
  note: string,
  blockReason: BlockReasonDTO,
  type: 'temporary' | 'permanent'
) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/${
      type === 'temporary' ? 'blocktemp' : 'blockperm'
    }`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        userId: customerId,
        note,
        blockReason,
      }),
    }
  ).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

const unblockCustomerRequest = async (customerId: number, note: string) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(`${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/unblock`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      userId: customerId,
      note,
    }),
  }).then((res) => {
    if (res.ok) {
      return;
    }

    throw new Error();
  });
};

export const useBlockCustomerMutation = (telephoneNumber: string) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const blockCustomerMutation = useMutation(
    ({
      customerId,
      note,
      blockReason,
      type,
      toggleModal,
    }: {
      customerId: number;
      note: string;
      blockReason: BlockReasonDTO;
      type: 'temporary' | 'permanent';
      toggleModal: () => void;
    }) => {
      return blockCustomerRequest(customerId, note, blockReason, type).then(
        () => {
          toggleModal();
        }
      );
    },

    {
      onSuccess: () => {
        notyf.success(
          t('customer.successfully-blocked-customer') + ' ' + telephoneNumber
        );
        queryClient.invalidateQueries({ queryKey: ['user'] });
      },
      onError: (e: { message: string }) => {
        notyf.error(e.message);
      },
    }
  );

  const unblockCustomerMutation = useMutation(
    ({
      customerId,
      note,
      toggleModal,
    }: {
      customerId: number;
      note: string;
      toggleModal: () => void;
    }) => {
      return unblockCustomerRequest(customerId, note).then(() => {
        toggleModal();
      });
    },

    {
      onSuccess: () => {
        notyf.success(
          t('customer.successfully-unblocked-customer') + ' ' + telephoneNumber
        );
        queryClient.invalidateQueries({ queryKey: ['user'] });
      },
      onError: (e: { message: string }) => {
        notyf.error(e.message);
      },
    }
  );

  return { blockCustomerMutation, unblockCustomerMutation };
};
