import z from 'zod';

export const FinancialStatusDtoSchema = z.object({
  id: z.object({
    value: z.number(),
  }),
  status: z.string(),
  message: z.string(),
  ticketId: z.object({
    value: z.number(),
  }),
  createdAt: z.string(),
  modifiedAt: z.string().optional(),
});

export type FinancialStatusDto = z.infer<typeof FinancialStatusDtoSchema>;
