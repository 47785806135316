export const hasNumberOrTicketIdOnBasic = ({
  phoneNumber,
  ticketId,
  searchType,
}: {
  phoneNumber: string;
  ticketId: string;
  searchType: string;
}): boolean => {
  const isBasicSearch = searchType === 'basic';

  return isBasicSearch ? phoneNumber !== '' || ticketId !== '' : true;
};
