import parse from 'date-fns/parse';
import parseISO from 'date-fns/esm/parseISO';

import { zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';

const timeZone = 'Europe/Copenhagen';

export const parseGMT = (dateString: string): Date => {
  const parsedDate = parse(dateString, "yyyy-MM-dd HH:mm:ss 'GMT'", new Date());
  // https://github.com/date-fns/date-fns/issues/2642
  const dateWithOffset = zonedTimeToUtc(parsedDate, 'UTC');
  return dateWithOffset;
};

export const formatDate = (dateString: string): string => {
  const parsedDate = dateString.endsWith('GMT')
    ? parseGMT(dateString)
    : parseISO(dateString);

  const formattedDate = formatInTimeZone(
    parsedDate,
    timeZone,
    'dd/MM/yyyy HH:mm:ss'
  );

  return formattedDate;
};
