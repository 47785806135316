import React from 'react';

interface UpIconProps {
  size?: number;
  color?: string;
}

const UpIcon: React.FC<UpIconProps> = ({
  size = 16,
  color = 'rgb(107, 114, 128)', // .bg-gray-500 in tailwind
}) => (
  <svg
    fill="none"
    width={size}
    viewBox="0 0 10 10"
    id="Scroll-Up-Down--Streamline-Micro.svg"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.75 3.5 4.779 0.574a0.253 0.253 0 0 1 0.358 0L8.25 3.5"
      strokeWidth="1"
    ></path>
  </svg>
);

export default UpIcon;
