import ResultsNavbar from './TicketsResultsNavbar';
import OrdersTable from '../orders/OrdersTable';
import PreOrdersTable from '../preorders/PreOrdersTable';
import IncomingMessagesTable from '../incomingMessages/IncomingMessagesTable';
import { Container } from '../../../components/Layout';
import { useTranslation } from 'react-i18next';
import SingleTicket from '../tickets/TicketDetails';
import { useDecodeSearchParams } from '~/hooks/useDecodeSearchParams';
import TicketsTable from '../tickets/TicketsTable';
import BlockadeMessagesTable from '../blockades/BlockadeMessagesTable';

const SearchResults = () => {
  const { t } = useTranslation();

  const [params] = useDecodeSearchParams();

  if (params.ticketId && params.ticketId.length > 0) {
    return <SingleTicket mCode={params.ticketId} />;
  }

  if (params.fourTPaymentId && params.fourTPaymentId.length > 0) {
    return <SingleTicket paymentId={params.fourTPaymentId} />;
  }

  return (
    <Container>
      <div className="pb-2 text-xl font-semibold">
        {t(
          `${
            params.searchType === 'basic'
              ? 'search.basic-search-results'
              : 'search.advanced-search-results'
          }`
        )}
      </div>
      <ResultsNavbar />
      <TicketsTable />
      <OrdersTable />
      <PreOrdersTable />
      <IncomingMessagesTable />
      <BlockadeMessagesTable />
    </Container>
  );
};

export default SearchResults;
