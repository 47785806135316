import type { S3ProviderListOutputItem } from '@aws-amplify/storage';
import { Auth, Storage } from 'aws-amplify';
import { Table } from 'flowbite-react';
import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { BarLoader } from 'react-spinners';
import { formatDate } from '~/lib/utils/formatDates/formatDates';
import NotyfContext from '../../context/NotyfContext';
import { options } from '../modal/ConfirmAlert';

type S3ItemWithUrl = S3ProviderListOutputItem & { url: string };

const sortInstructionsByDate = (a: S3ItemWithUrl, b: S3ItemWithUrl) => {
  if (b.lastModified && a.lastModified) {
    return b.lastModified.getTime() - a.lastModified.getTime();
  } else if (b.lastModified) {
    return -1;
  } else if (a.lastModified) {
    return 1;
  } else {
    return 0;
  }
};

const fetchInstructions = async (): Promise<S3ItemWithUrl[]> => {
  const response = await Storage.list('');
  const instructions = await Promise.all(
    response.results.map(async (data) => {
      const url = await Storage.get(data.key ?? '');

      return { ...data, url };
    })
  );

  return instructions.sort(sortInstructionsByDate);
};

const deleteInstructionMutation = async (fileName: string): Promise<string> => {
  const session = await Auth.currentSession();
  const jwtToken = session.getIdToken().getJwtToken();
  const logsResponse = await fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/logs/deleteinstruction`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        reference: fileName,
      }),
    }
  );

  if (!logsResponse.ok) {
    throw new Error();
  }

  const response = await Storage.remove(fileName, {
    level: 'public',
  });

  if (response.$metadata.httpStatusCode !== 204) {
    throw new Error();
  } else {
    return fileName;
  }
};

const InstructionsTable = (props: { edit: boolean }) => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();

  const { data: instructionsList, refetch } = useQuery(
    'instructions',
    fetchInstructions,
    {
      onError: (error) => {
        notyf.error(String(error));
      },
    }
  );

  const deleteMutation = useMutation(deleteInstructionMutation, {
    onSuccess: (data) => {
      notyf.success(t('common.deleted') + ' ' + data);
      refetch();
    },
    onError: () => {
      notyf.error(t('instructions.couldnt-delete-file'));
    },
  });

  const setupAlert = (instruction: S3ItemWithUrl) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.title = t('instructions.are-you-sure-delete');
    newOptions.buttons = [
      {
        className: '!bg-red-500',
        label: t('common.yes'),
        onClick: () => deleteMutation.mutate(instruction.key ?? ''),
      },
      {
        label: t('common.no'),
        onClick: () => {},
      },
    ];
    return newOptions;
  };

  if (!instructionsList) {
    return (
      <div>
        <BarLoader color="#7f7f7f" className="mx-auto" />
      </div>
    );
  }
  return (
    <>
      <Table className="shadow-lg">
        <Table.Head className="bg-gray-100">
          <Table.HeadCell>{t('instructions.published')}</Table.HeadCell>
          <Table.HeadCell>{t('instructions.subject')}</Table.HeadCell>
          {props.edit === true && (
            <Table.HeadCell>{t('instructions.action')}</Table.HeadCell>
          )}
        </Table.Head>
        <Table.Body className="divide-y">
          {instructionsList.map((instruction, id) => (
            <Table.Row
              key={id}
              className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
            >
              <Table.Cell className="whitespace-nowrap">
                {formatDate(instruction.lastModified?.toISOString() ?? '')}
              </Table.Cell>
              <Table.Cell>
                <a href={instruction.url} target="_blank" rel="noreferrer">
                  {instruction.key}
                </a>
              </Table.Cell>
              {props.edit === true && (
                <Table.Cell
                  onClick={() => confirmAlert(setupAlert(instruction))}
                  className="text-red-600 cursor-pointer"
                >
                  {t('tables.delete')}
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default InstructionsTable;
