import DownIcon from '~/features/search/messages/DownIcon';
import EqualIcon from '~/features/search/messages/EqualIcon';
import UpIcon from '~/features/search/messages/UpIcon';

export type TOrder = 'asc' | 'desc' | null;

const SortIndicator = ({ sortOrder }: { sortOrder: TOrder }) => {
  if (sortOrder === undefined) {
    return null;
  }
  if (sortOrder === 'asc') {
    return (
      <div className="flex w-4 mx-2 align-middle">
        <UpIcon />
      </div>
    );
  }
  if (sortOrder === 'desc') {
    return (
      <div className="flex w-4 mx-2 align-middle">
        <DownIcon />
      </div>
    );
  }
  if (sortOrder === null) {
    return (
      <div className="flex w-4 mx-2 align-middle">
        <EqualIcon />
      </div>
    );
  }
};

export default SortIndicator;
