export const numberHasThreeConsecutiveDigits = ({
  phoneNumber,
  ticketId,
  fourTPaymentId,
}: {
  phoneNumber: string;
  ticketId: string;
  fourTPaymentId: string;
}): boolean => {
  const hasTicketOrPaymentId = ticketId || fourTPaymentId;

  if (hasTicketOrPaymentId) {
    return true;
  }

  return phoneNumber ? phoneNumber.match(/\d{3}/) !== null : true;
};
