import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import type { CustomerNotesDto } from '~/lib/dtos/CustomerNotesDto';

const fetchCustomerNotesMutation = async ({ userId, limit, page }: Props) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/user/${userId}/notes?page=${page}&limit=${limit}`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );

  return res.json();
};

type Props = {
  userId?: string;
  limit: number;
  page: number;
};

export const useCustomerNotesTable = ({ userId, limit, page }: Props) => {
  const { data, isLoading, error } = useQuery<CustomerNotesDto>(
    ['customerNotes', { userId, limit, page }],
    () => fetchCustomerNotesMutation({ userId, limit, page }),
    {
      enabled: Boolean(userId),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    notes: data?.notes ?? [],
    isLoading,
    error,
    count: data?.count ?? 0,
    availablePages: data?.availablePages ?? 0,
  };
};
