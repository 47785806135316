import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { SendReceiptCell } from './Cells/SendReceiptCell';
import type { Dispatch, SetStateAction } from 'react';
import { AddTicketNoteCell } from './Cells/AddTicketNoteCell';
import { ResendTicketCell } from './Cells/ResendTicketCell';
import type { TicketDto } from '~/lib/dtos/common/TicketDto';
import { EmptyCell } from '~/components/Cells/EmptyCell';
import type { PaymentStatusDto } from '~/lib/dtos/common/PaymentStatusDto';
import { TicketRefundCell } from '~/components/Cells/TicketRefundCell';

const ActionsTable = ({ ticket }: { ticket: TicketDto | null }) => {
  const { t } = useTranslation();

  const sendReceiptDisabledStatuses: PaymentStatusDto[] = [
    'PAYMENT_PROCESSING',
    'PAYMENT_REJECTED',
  ];
  if (!ticket) {
    return null;
  }
  return (
    <div className="flex flex-col">
      <div className="my-2 text-xl font-semibold text-left">
        {t('search.actions')}
      </div>
      <Table className="border-spacing-0 w-full border border-separate border-gray-300 rounded-md shadow-lg">
        <Table.Head className=" font-bold">
          <Table.HeadCell className=" text-center">
            {t('tables.refund-status')}
          </Table.HeadCell>
          <Table.HeadCell className=" text-center">
            {t('tables.send-receipt')}
          </Table.HeadCell>
          <Table.HeadCell className=" text-center">
            {t('tables.ticket-resend')}
          </Table.HeadCell>
          <Table.HeadCell className=" text-center">
            {t('customer.note')}
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white">
            <TicketRefundCell
              className="border border-gray-100"
              refundStatus={ticket.paymentStatus}
              ticketId={String(ticket.id.value)}
            />
            {sendReceiptDisabledStatuses.includes(ticket.paymentStatus) ? (
              <EmptyCell />
            ) : (
              <SendReceiptCell ticketId={String(ticket.id.value)} />
            )}
            <ResendTicketCell ticketId={String(ticket.id.value)} />
            <AddTicketNoteCell ticketId={String(ticket.id.value)} />
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
export default ActionsTable;
